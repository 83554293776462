import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import { scannableQueryOptions } from "@/api/queries/scannable-queries.ts";
import { Scanner } from "@/components/scanner/scanner.tsx";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, notFound } from "@tanstack/react-router";
import { AnimatePresence } from "framer-motion";

export const Route = createFileRoute("/agamotto_/$code")({
	loader: ({ context: { queryClient }, params }) => {
		if (!params.code) {
			throw notFound();
		}

		return queryClient.prefetchQuery(scannableQueryOptions(params.code));
	},
	component: Page,
});

function Page() {
	const params = Route.useParams();
	const scannableQuery = useSuspenseQuery(scannableQueryOptions(params.code));
	const scannable = scannableQuery.data;
	const profile = useQuery(profileQueryOptions());

	return (
		<div className="flex w-screen h-[100svh]">
			<AnimatePresence>
				<div className="z-[50] fixed inset-0 w-full h-full overflow-hidden">
					<Scanner code={params.code} isAuth={!!profile.data} />
					{scannable.scan_animation === "Animated" ? (
						<div className="flex h-full w-max animate-[comicsPageMarquee_640s_linear_infinite]">
							{[...Array(2)].map((_, i) => (
								<div key={i} className="flex flex-row h-full min-w-max">
									{Array.from({ length: 10 }).map((_, index) => (
										<div key={index} className="inline-flex h-full shrink-0">
											<img
												className="h-full w-full object-cover"
												src="/scannables/scanner-bg-default.jpg"
												alt="Macroverse"
											/>
										</div>
									))}
								</div>
							))}
						</div>
					) : (
						<div className="flex justify-center items-center h-full w-full">
							<img
								className="block h-full w-full object-cover"
								src="/scannables/scanner-bg-default.jpg"
								alt="Macroverse"
							/>
						</div>
					)}
				</div>
			</AnimatePresence>
		</div>
	);
}

export default Page;
