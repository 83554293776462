import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/core/actions/dropdown-menu.tsx";
import Tooltip from "@/components/core/feedbacks/tooltip.tsx";
import ProfileFilterItems from "@/components/dropdowns/profile-filter-items.tsx";
import { IconFilter } from "@/components/mvicons";
import clsx from "clsx";
import { useState } from "react";

export default function ProfileFilter() {
	const [isActive, setIsActive] = useState(false);

	return (
		<DropdownMenu modal={false} onOpenChange={(open) => setIsActive(open)}>
			<DropdownMenuTrigger>
				<Tooltip size="sm" content="Filter" sideOffset={8}>
					<button
						type="button"
						className={clsx(
							"inline-flex flex-col justify-center items-center w-[38px] h-[38px] rounded-xl bg-transparent text-white/50 hover:text-white transition-all",
							{
								"rotate-180": isActive,
								"rotate-0": !isActive,
							},
						)}
					>
						<IconFilter size={24} />
					</button>
				</Tooltip>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start" side="bottom" sideOffset={6} alignOffset={-24}>
				<ProfileFilterItems />
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
