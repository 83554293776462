import type { ErrorResponse } from "@/api/base-service";
import { useSupplyClaimMutation } from "@/api/queries/supply-queries.ts";
import { Button, ButtonIcon, ButtonLabel } from "@/components/core/buttons/button";
import { Form, FormField, FormGroup, FormItem, FormMessage } from "@/components/core/forms/form.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import {
	IconCheckCircle,
	IconChevronRight,
	IconCollectionSecured,
	IconExp,
	IconQrcode,
	IconShare,
	IconWarningTriangle,
} from "@/components/mvicons";
import { ErrorCameraPermissionModal } from "@/components/scanner/error-camera-permission-modal.tsx";
import { ErrorClaimedModal } from "@/components/scanner/error-claimed-modal.tsx";
import { ErrorInvalidCodeModal } from "@/components/scanner/error-invalid-code-modal.tsx";
import { ErrorMaxClaimsModal } from "@/components/scanner/error-max-claims-modal.tsx";
import { RequestPermissionModal } from "@/components/scanner/request-permission-modal.tsx";
import { entityTypes } from "@/constants/entity-types.ts";
import type { Suppliable } from "@/types/suppliable.ts";
import { formatDate } from "@/utils/format-date.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { IconLoader3, IconX } from "@tabler/icons-react";
import { Scanner as ReactQrScanner } from "@yudiel/react-qr-scanner";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { type ReactNode, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import Alert from "../core/feedbacks/alert";
import { Input } from "../core/forms/input";
import ScanSequence from "../scan-sequence";
import ShareCollectionModal from "../share-collection-modal";

export const ScanStep = {
	Guest: 1, // This will only get triggered when scanning from agamotto, since the user is not logged-in
	Welcome: 2,
	Scanning: 3,
	Success: 4,
};

function ScanContainer({
	children,
	showLogo,
	showBg,
	showBlur,
	fullScreen,
}: {
	children: ReactNode;
	showLogo?: boolean;
	showBg?: boolean;
	showBlur?: boolean;
	onClose?: () => void;
	fullScreen?: boolean;
}) {
	return (
		<motion.div
			className={clsx(
				"relative flex flex-col justify-center items-center border-[2px] border-white/10 overflow-hidden",
				fullScreen
					? "h-[100svh] w-screen"
					: "h-[100svh] md:h-[720px] w-screen md:w-[440px] rounded-none md:rounded-4xl",
				showBg && "bg-mvdark-950/80",
				showBlur && "backdrop-blur-[2px]",
			)}
		>
			<div className="z-[3] flex flex-col justify-between items-center w-full h-full">
				{showLogo && (
					<div className="flex flex-row justify-center items-center w-full p-8">
						<div
							className="inline-flex w-[60px]"
							style={{
								filter: `
                  drop-shadow(0px 0px 12px rgba(0,0,0,.6))
                  drop-shadow(0px 0px 20px rgba(0,0,0,0.9))
                  drop-shadow(0px 0px 28px rgba(0,0,0,0.5))
                  drop-shadow(0px 0px 36px rgba(0,0,0,0.2))`,
							}}
						>
							<img src="/scannables/macroverse-logo-white.svg" alt="Macroverse" />
						</div>
					</div>
				)}
				<div className="flex grow shrink flex-col justify-center items-center h-full w-full gap-6">{children}</div>
			</div>
			{fullScreen ? null : (
				<div className="z-[1] hidden md:flex absolute scanner-dialog-border inset-0 blur-[10px] pointer-events-none" />
			)}
		</motion.div>
	);
}

export function Scanner({
	isAuth,
	code,
	onClose,
}: {
	code?: string;
	isAuth?: boolean;
	onClose?: () => void;
}) {
	const modal = useModal();
	const supplyClaimMutation = useSupplyClaimMutation();
	const [permissionGranted, setPermissionGranted] = useState(false);
	const [_, setAnimationEnded] = useState(false);
	const [suppliable, setSuppliable] = useState<Suppliable | null>();
	const [step, setStep] = useState(ScanStep.Guest);
	const [scannerPaused, setScannerPaused] = useState(false);
	const [isMediumScreen, setIsMediumScreen] = useState(false);
	const [error, setError] = useState<string | null>();
	const [isManual, setIsManual] = useState(false);
	const [isSecured, setIsSecured] = useState(false);
	const [securing, setSecuring] = useState(false);
	const { loginWithRedirect } = useAuth0();

	const handleLogin = () => {
		loginWithRedirect({
			appState: {
				returnTo: `/agamotto/${code}`,
			},
			authorizationParams: {
				supply_code: code,
			},
		});
	};

	const form = useForm({
		defaultValues: {
			code,
		},
	});

	useEffect(() => {
		if (isAuth) {
			setStep(ScanStep.Welcome);
		}
	}, [isAuth]);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(min-width: 768px)");
		setIsMediumScreen(mediaQuery.matches);

		const handleResize = () => {
			setIsMediumScreen(mediaQuery.matches);
		};

		mediaQuery.addEventListener("change", handleResize);

		return () => {
			mediaQuery.removeEventListener("change", handleResize);
		};
	}, []);

	useEffect(() => {
		checkCameraPermission();
	}, []);
	const stopStreamTracks = (stream: MediaStream) => {
		for (const track of stream.getTracks()) {
			track.stop();
		}
	};

	const checkCameraPermission = useCallback(async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });
			if (stream) {
				stopStreamTracks(stream);
				setPermissionGranted(true);
			}
		} catch (err) {
			console.error("Failed to check camera permissions:", err);
		}
	}, []);

	const requestCameraPermissions = useCallback(async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });
			if (stream) {
				stopStreamTracks(stream);
				setPermissionGranted(true);
			}
		} catch (err) {
			modal.open({
				type: "confirm",
				onConfirm: async () => {},
				children: <ErrorCameraPermissionModal />,
			});
		}
	}, [modal]);

	const openErrorModal = useCallback(
		(errCode: string) => {
			let modalComponent: ((handleConfirm: () => void) => ReactNode) | undefined;
			switch (errCode) {
				case "user_redeemed":
				case "already_redeemed":
					modalComponent = (handleConfirm) => <ErrorClaimedModal handleConfirm={handleConfirm} />;
					break;
				case "invalid_qr":
					modalComponent = (handleConfirm) => <ErrorInvalidCodeModal handleConfirm={handleConfirm} />;
					break;
				case "max_claims":
					modalComponent = (handleConfirm) => <ErrorMaxClaimsModal handleConfirm={handleConfirm} />;
					break;
				default:
					return;
			}
			modal.open({
				type: "confirm",
				onConfirm: async () => {
					setScannerPaused(false);
				},
				children: (handleConfirm: () => void) => (modalComponent ? modalComponent(handleConfirm) : null),
				onDismiss: () => setScannerPaused(false),
			});
		},
		[modal],
	);

	const processCode = useCallback(
		async (code: string) => {
			setError(null);

			try {
				const response = await supplyClaimMutation.mutateAsync({ code });
				setSuppliable(response);

				setTimeout(() => {
					setStep(ScanStep.Success);
				}, 8_000);
			} catch (err) {
				const errorResponse = err as ErrorResponse;

				const errCode = errorResponse.response.data.code;

				if (errorResponse.response.data.message) {
					setError(errorResponse.response.data.message);
				}

				openErrorModal(errCode);
			} finally {
				form.reset();
			}
		},
		[supplyClaimMutation, form, openErrorModal],
	);

	const handleScan = useCallback(
		async (results: { rawValue: string; format: string }[]) => {
			if (results?.length && !scannerPaused) {
				const scannedCode = results[0];
				if (scannedCode.format === "unknown") return;
				setScannerPaused(true);
				await processCode(scannedCode.rawValue);
			}
		},
		[processCode, scannerPaused],
	);

	const handleManualCodeInput = useCallback(
		async (values: { code: string }) => {
			await processCode(values.code);
		},
		[processCode],
	);

	const handleError = useCallback(() => {
		modal.open({
			type: "confirm",
			onConfirm: async () => {},
			children: (handleConfirm) => <ErrorInvalidCodeModal handleConfirm={handleConfirm} />,
		});
	}, [modal]);

	const handleRequestCameraPermission = useCallback(() => {
		if (permissionGranted) {
			setIsManual(false);
			setStep(ScanStep.Scanning);
		} else {
			modal.open({
				type: "confirm",
				onConfirm: async () => {
					await requestCameraPermissions();
				},
				children: (handleConfirm) => <RequestPermissionModal handleConfirm={handleConfirm} />,
			});
		}
	}, [permissionGranted, modal, requestCameraPermissions]);

	// Reset state for a new scan session
	const handleResetState = useCallback(() => {
		setSuppliable(null);
		setStep(ScanStep.Scanning);
		setAnimationEnded(false);
		setScannerPaused(false);
		setIsManual(false);
	}, []);

	const handleSecureIssue = () => {
		setSecuring(true);

		setTimeout(() => {
			setIsSecured((prev) => !prev);
			setSecuring(false);
		}, 2000);
	};

	/*const handleAnimationEnd = () => {
    alert("Animation has ended!");
  };*/

	const exitPage = `${window.location.protocol}//${window.location.host}/${isAuth ? "overview" : ""}`;

	return (
		<motion.div
			className="z-50 fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center min-h-[100svh]"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<motion.div
				className="absolute inset-0 bg-mvdark-950/70"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.1 }}
			/>
			<motion.div
				className="font-aller relative flex flex-col h-auto w-auto"
				initial={{ opacity: 0, scale: 0.95 }}
				animate={{ opacity: 1, scale: 1 }}
				exit={{ opacity: 0, scale: 0.95 }}
				transition={{ duration: 0.1 }}
			>
				{/* scan ui close button */}
				{step !== ScanStep.Success && (
					<button
						onClick={
							suppliable
								? () => {}
								: () => {
										if ((step === ScanStep.Welcome || step === ScanStep.Guest) && onClose === undefined) {
											window.location.href = exitPage;
										} else {
											setStep(ScanStep.Welcome);
											onClose?.();
										}
									}
						}
						type="button"
						className="z-10 absolute left-4 top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
					>
						<IconX size={28} stroke={2} />
					</button>
				)}

				<div className="relative flex flex-col items-center gap-8">
					{step === ScanStep.Guest && (
						<ScanContainer showLogo={true} showBg={false} showBlur={true} onClose={onClose}>
							<div className="flex flex-col justify-end w-full h-full max-w-[420px] p-8 gap-6">
								<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
									<div
										className="inline-flex w-[220px]"
										style={{
											filter: `
                        drop-shadow(0px 0px 12px rgba(0,0,0,.4))
                        drop-shadow(0px 0px 20px rgba(0,0,0,0.6))
                        drop-shadow(0px 0px 28px rgba(0,0,0,0.4))
                        drop-shadow(0px 0px 36px rgba(0,0,0,0.2))`,
										}}
									>
										<img
											className="block w-full"
											src="/scannables/scanner-logo-authentic-editions.svg"
											alt="Macroverse"
										/>
									</div>
									<div
										className="inline-flex w-[80px]"
										style={{
											filter: `
                        drop-shadow(0px 0px 12px rgba(0,0,0,.4))
                        drop-shadow(0px 0px 20px rgba(0,0,0,0.6))
                        drop-shadow(0px 0px 28px rgba(0,0,0,0.4))
                        drop-shadow(0px 0px 36px rgba(0,0,0,0.2))`,
										}}
									>
										<img
											className="block w-full"
											src="/scannables/scanner-icon-authentic-editions.svg"
											alt="Macroverse"
										/>
									</div>
								</div>
								<span
									className="text-center font-semibold text-sm text-white"
									style={{
										textShadow: `
                      0px 0px 6px rgba(0,0,0,0.9),
                      0px 0px 12px rgba(0,0,0,0.7),
                      0px 0px 18px rgba(0,0,0,0.5),
                      0px 0px 24px rgba(0,0,0,0.3),
                      0px 0px 30px rgba(0,0,0,0.1)`,
									}}
								>
									Scan Authentic Edition QR Codes and redeem your free Digital Twins plus Bonus Perks and Rewards!
								</span>
								<Button
									onClick={() => handleLogin()}
									className="font-quagmire w-full uppercase mb-12"
									type="button"
									variant="default"
									color="white"
									size="lg"
								>
									<ButtonLabel>Login and Scan</ButtonLabel>
								</Button>
							</div>
						</ScanContainer>
					)}

					{step === ScanStep.Welcome && (
						<ScanContainer showLogo={false} showBg={true} showBlur={true} onClose={onClose}>
							<div className="flex flex-col justify-center items-center w-full max-w-[420px] p-8 gap-6">
								<div className="flex flex-col items-center w-full gap-2">
									<div className="inline-flex flex-row items-center gap-2">
										<IconQrcode className="text-white" size={32} />
										<span className="text-white text-xl">Scan QR Code</span>
									</div>
									<div className="inline-flex flex-col justiy-center items-center">
										<span className="font-quagmire text-xl font-bold text-white uppercase leading-7">
											Get your Digital Twin
										</span>
										<span className="font-quagmire text-xl font-bold text-mvred-600 uppercase leading-7">
											+ Bonus Rewards
										</span>
									</div>
								</div>
								<div className="flex flex-col w-full gap-2 px-4">
									<span className="text-center text-sm text-white">
										Each printed issue of Authentic Editions include a QR code connected to a unique Digital Twin.
									</span>
									<span className="text-center text-sm text-white">
										Scan and claim this unique Digital Edition and add it to your collection. The physical copy is
										paired with the digital to create a bonded set.{" "}
									</span>
									<span className="text-center text-sm text-white">
										Only the owner of the physical can claim it and any additional perks and rewards that come with it.
									</span>
									<span className="text-center text-sm text-white">Let’s get started!</span>
								</div>
								<div className="flex flex-col w-full mt-2 gap-2">
									<Button
										onClick={handleRequestCameraPermission}
										className="font-quagmire w-full backdrop-blur uppercase"
										type="button"
										variant="gradient"
										size="md"
									>
										<ButtonLabel>Scan Now</ButtonLabel>
									</Button>
									<Button
										onClick={() => {
											setIsManual(true);
											setStep(ScanStep.Scanning);
										}}
										className="font-quagmire w-full backdrop-blur uppercase"
										type="button"
										variant="outline"
										size="md"
										color="blue"
									>
										<ButtonLabel>Enter Code Manually</ButtonLabel>
									</Button>
									<Button
										onClick={() => {
											if (onClose) {
												onClose();
											} else {
												window.location.href = exitPage;
											}
										}}
										className="font-quagmire w-full backdrop-blur text-white/70 hover:text-white"
										type="button"
										variant="unstyled"
										size="md"
										color="blue"
									>
										<ButtonLabel>Not Now</ButtonLabel>
									</Button>
								</div>
							</div>
						</ScanContainer>
					)}

					{step === ScanStep.Scanning && (
						<ScanContainer showLogo={false} showBg={true} showBlur={true} onClose={onClose}>
							{isManual ? (
								<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[420px] p-8 gap-6">
									<div className="flex flex-col w-full gap-2">
										<span className="text-center text-white text-xl">Manual Input Code</span>
										<span className="text-center text-sm text-white">
											It looks like we couldn't scan your QR code. No worries! You can manually enter the code below to
											continue the claiming process.
										</span>
									</div>
									<div className="flex flex-col w-full mt-4">
										<Form {...form}>
											<form onSubmit={form.handleSubmit(handleManualCodeInput)}>
												<div className="flex flex-col w-full gap-4">
													{error && (
														<Alert type="error" allowClose={false} icon={<IconWarningTriangle size={28} />}>
															{error}
														</Alert>
													)}
													<FormField
														control={form.control}
														name="code"
														render={({ field }) => (
															<FormItem>
																<FormGroup inputSize="lg" inputStyle="default">
																	<Input
																		className="!text-center md:!text-lg md:!font-semibold"
																		disabled={!!suppliable && !error}
																		autoComplete="off"
																		placeholder="Enter Code"
																		{...field}
																	/>
																</FormGroup>
																<FormMessage />
															</FormItem>
														)}
													/>

													<div className="flex flex-col w-full gap-2">
														<Button
															className="font-quagmire w-full backdrop-blur uppercase"
															type={supplyClaimMutation.isPending || suppliable ? "button" : "submit"}
															variant="gradient"
															size="md"
															isLoading={supplyClaimMutation.isPending}
															disabled={!!suppliable}
														>
															<ButtonLabel>Submit</ButtonLabel>
														</Button>
														<Button
															onClick={
																supplyClaimMutation.isPending || suppliable
																	? () => {}
																	: () => {
																			setIsManual(false);

																			setStep(ScanStep.Scanning);
																		}
															}
															className="font-quagmire w-full backdrop-blur uppercase"
															type="button"
															variant="outline"
															size="md"
															color="blue"
															disabled={supplyClaimMutation.isPending || !!suppliable}
														>
															<ButtonLabel>Scan QR Code</ButtonLabel>
														</Button>
														<Button
															onClick={
																supplyClaimMutation.isPending || suppliable
																	? () => {}
																	: () => {
																			if (onClose) {
																				onClose?.();
																			} else {
																				setStep(ScanStep.Welcome);
																			}
																		}
															}
															className="font-quagmire w-full backdrop-blur text-white/70 hover:text-white"
															type="button"
															variant="unstyled"
															size="md"
															color="blue"
														>
															<ButtonLabel>Not Now</ButtonLabel>
														</Button>
													</div>
													<div className="flex flex-row justify-center w-full mt-4">
														<ScanSequence
															start={!!suppliable}
															cover={suppliable?.image?.original_url!}
															supply={suppliable?.name}
															issueNumber={suppliable?.issue_number}
															releaseDate={suppliable?.released_at}
															onAnimationEnd={() => {
																setAnimationEnded(true);
																setStep(4);
															}}
														/>
													</div>
												</div>
											</form>
										</Form>
									</div>
								</div>
							) : (
								<div className="flex flex-col w-full h-full">
									<div className="z-20 absolute top-12 left-1/2 -translate-x-1/2">
										<div className="flex flex-row items-center w-auto gap-2 bg-black/30 backdrop-blur py-2 px-4 rounded-full">
											<div className="inline-flex w-[14px] h-[14px] rounded-full bg-mvred-600">
												<div className="animate-ping inline-flex w-[14px] h-[14px] rounded-full bg-mvred-600" />
											</div>
											<span className="tracking-wider text-xs text-white uppercase">Camera is Active</span>
										</div>
									</div>

									<div className="z-[2] absolute bottom-12 flex flex-row justify-center w-full">
										<ScanSequence
											start={suppliable}
											cover={suppliable?.image?.original_url}
											supply={suppliable?.name}
											issueNumber={suppliable?.issue_number}
											releaseDate={suppliable?.released_at}
											onAnimationEnd={() => {
												setAnimationEnded(true);
												setStep(4);

												// This gives an extra time before loading the last sscreen
												/*setTimeout(() => {
                          setStep(4);
                        }, 5000);*/
											}}
										/>
									</div>

									{/*<div className="z-[2] absolute bottom-12 flex flex-row justify-center w-full ">
										<div className="flex flex-row items-center w-auto p-6 gap-3 rounded-lg bg-black/30 backdrop-blur">
											{suppliable ? (
												<ScanProgress
													cover={suppliable?.image?.original_url}
													baseDelay={2000}
													baseDuration={5000}
													onAnimationEnd={() => {
														setAnimationEnded(true);

														// This gives an extra time before loading the last sscreen
														setTimeout(() => {
															setStep(4);
														}, 5000);
													}}
												/>
											) : (
												<div className="relative inline-flex justify-center items-center min-h-[80px] w-[56px]">
													<div className="z-[2] inline-flex text-white">
														<IconComic size={54} />
													</div>
												</div>
											)}

											{animationEnded ? (
												<div className="flex flex-col gap-2">
													<div className="flex flex-row items-center gap-1">
														<IconQrcode className="text-mvblue-300" size={14} />
														<span className="text-xs text-mvblue-300">Issue Confirmed</span>
													</div>
													<div className="flex flex-col">
														<span className="text-xs text-white leading-tight">
															{suppliable?.name} #{suppliable?.issue_number}
														</span>
														<span className="text-xs text-white leading-tight">Authentic Edition</span>
														<span className="text-xs text-white leading-tight">{suppliable?.released_at}</span>
													</div>
												</div>
											) : (
												<div className="flex flex-col gap-2">
													<div className="flex flex-row items-center gap-1">
														<IconQrcode className="text-mvblue-300" size={14} />
														<span className="text-xs text-mvblue-300">Identifying Issue...</span>
													</div>
													<div className="flex flex-col">
														<span className="text-xs text-white leading-tight">Finding Title...</span>
														<span className="text-xs text-white leading-tight">Locating Edition...</span>
														<span className="text-xs text-white leading-tight">Confirming Details...</span>
													</div>
												</div>
											)}
										</div>
									</div>*/}

									{!scannerPaused && (
										<ReactQrScanner
											paused={!!suppliable}
											onScan={handleScan}
											onError={handleError}
											allowMultiple={false}
											scanDelay={1000}
											classNames={{
												container: "scanner",
												video: "object-cover",
											}}
										/>
									)}
								</div>
							)}
						</ScanContainer>
					)}

					{step === ScanStep.Success && (
						<ScanContainer fullScreen={true} showLogo={false} showBg={false} showBlur={true} onClose={onClose}>
							{/* gradient oerlays */}
							<div className="z-[4] hidden md:flex fixed inset-0 scanner-claim-border blur-[2px] opacity-80 pointer-events-none" />
							<div className="z-[3] hidden md:flex fixed inset-0 scanner-claim-accent blur-[20px] opacity-80 pointer-events-none" />

							{/* success issue claim */}
							{suppliable?.type === entityTypes.ISSUE && (
								<div className="z-[5] relative flex justify-center h-full w-full overflow-auto">
									<div className="md:relative w-auto h-auto my-auto mx-auto">
										<button
											onClick={() => {
												setSuppliable(null);
												setStep(ScanStep.Welcome);

												onClose?.();
											}}
											type="button"
											className="z-10 absolute left-4 md:-left-4 top-4 md:-top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
										>
											<IconX size={28} stroke={2} />
										</button>
										<motion.div
											className=" flex my-auto flex-row w-full h-auto max-w-[380px] py-8 md:p-8 md:max-w-none gap-8 overflow-hidden"
											initial={isMediumScreen ? { width: 380 } : { width: 800 }}
											animate={isMediumScreen ? { width: 800 } : { width: 800 }}
											exit={isMediumScreen ? { width: 380 } : { width: 800 }}
											transition={{ delay: 1, duration: 0.3 }}
										>
											<div
												className="relative hidden md:inline-flex self-center shrink-0 w-[320px] border-2 border-white overflow-hidden"
												style={{
													filter: `
                          drop-shadow(0px 0px 6px rgba(0,0,0,.4))
                          drop-shadow(0px 0px 12px rgba(0,0,0,0.3))
                          drop-shadow(0px 0px 18px rgba(0,0,0,0.2))
                          drop-shadow(0px 0px 24px rgba(0,0,0,0.1))`,
												}}
											>
												{suppliable?.image?.original_url ? (
													<img className="block w-full" src={suppliable?.image?.original_url} alt="Macroverse" />
												) : (
													<img className="block w-full" src="/collection-cover-placeholder.jpg" alt="Macroverse" />
												)}
											</div>
											<motion.div
												className="flex flex-col justify-center items-center grow gap-4"
												initial={isMediumScreen ? { opacity: 0 } : { opacity: 1 }}
												animate={isMediumScreen ? { opacity: 1 } : { opacity: 1 }}
												exit={isMediumScreen ? { opacity: 0 } : { opacity: 1 }}
												transition={{ delay: 1.3, duration: 0.3 }}
											>
												<div className="inline-flex w-[120px]">
													<img className="block w-full" src="/svg/macroverse-authentic-editions.svg" alt="Macroverse" />
												</div>
												<div
													className="relative inline-flex md:hidden shrink-0 w-[180px] border-2 border-white"
													style={{
														filter: `
                            drop-shadow(0px 0px 6px rgba(0,0,0,.4))
                            drop-shadow(0px 0px 12px rgba(0,0,0,0.3))
                            drop-shadow(0px 0px 18px rgba(0,0,0,0.2))
                            drop-shadow(0px 0px 24px rgba(0,0,0,0.1))`,
													}}
												>
													<img className="block w-full" src={suppliable?.image?.original_url} alt="Macroverse" />
												</div>
												<div className="flex flex-row justify-center items-center w-full p-3 gap-2 rounded-lg bg-mvblue-400/10 backdrop-blur shadow">
													<div className="flex justify-center items-center flex-row shrink-0 w-[44px] h-[44px] rounded-sm bg-white">
														<IconQrcode className="text-black" size={36} />
													</div>
													<div className="flex flex-col gap-1">
														<div className="flex flex-row items-center gap-1">
															<IconCheckCircle className="text-mvblue-300" size={16} />
															<span className="text-sm text-mvblue-300">Digital Twin Paired</span>
														</div>
														<div className="self-start w-auto flex flex-row items-center gap-1 p-1 px-2 rounded-md bg-white/10">
															<span className="text-xs text-white leading-tight">#{suppliable.claim_sequence}</span>
														</div>
													</div>
												</div>
												<div className="flex flex-row items-center w-auto gap-4">
													<div className="flex flex-col">
														<span className="font-semibold text-sm text-white leading-tight">
															{suppliable?.name} #{suppliable?.issue_number}
														</span>
														<span className="text-sm text-white leading-tight">Authentic Edition</span>
														<span className="text-xs text-white/50 leading-tight">
															1st Printing | {formatDate(suppliable?.released_at)}
														</span>
													</div>
													<div className="inline-flex flex-col justify-center items-center p-2 rounded-md bg-white/10 text-white">
														<IconExp size={22} />
														<span className="mt-1 font-semibold text-2xs leading-tight">+10</span>
													</div>
												</div>
												<div className="flex flex-col w-full mt-2 gap-3">
													<div className="flex flex-row gap-3">
														<Button
															onClick={() => {
																modal.open({
																	title: "",
																	className: "overflow-hidden",
																	component: <ShareCollectionModal suppliable={suppliable} />,
																});
															}}
															className="flex-1 font-quagmire backdrop-blur uppercase !gap-2"
															type="button"
															variant="outline"
															size="md"
															color="blue"
														>
															<ButtonIcon>
																<IconShare size={18} />
															</ButtonIcon>
															<ButtonLabel>Share</ButtonLabel>
														</Button>
														<Button
															className="flex-1 font-quagmire backdrop-blur uppercase !gap-2"
															type="button"
															variant="outline"
															size="md"
															color="blue"
															onClick={() => {
																setSuppliable(null);
																setStep(ScanStep.Welcome);
															}}
														>
															<ButtonIcon>
																<IconQrcode size={18} />
															</ButtonIcon>
															<ButtonLabel className="whitespace-nowrap">Scan More</ButtonLabel>
														</Button>
													</div>
													{onClose ? (
														isSecured ? (
															<div className="flex flex-row justify-center items-center w-full h-[40px] px-3 gap-2 rounded-[14px] bg-mvblue-400/10 backdrop-blur shadow text-mvblue-300">
																<IconCollectionSecured size={18} />
																<span className="font-quagmire uppercase text-lg ">Secured</span>
															</div>
														) : (
															<Button
																disabled
																onClick={handleSecureIssue}
																className="font-quagmire w-full backdrop-blur uppercase"
																type="button"
																variant="gradient"
																size="md"
																color="blue"
															>
																{securing ? (
																	<>
																		<IconLoader3 className="animate-spin mr-1 mt-1" size={18} />
																		<ButtonLabel>Securing</ButtonLabel>
																	</>
																) : (
																	<>
																		<div className="inline-flex flex-row items-center gap-1 mr-2 mt-[2px]">
																			<IconExp size={20} />
																			<span className="font-aller text-base font-semibold leading-tight">1000</span>
																		</div>
																		<ButtonLabel>Secure</ButtonLabel>
																	</>
																)}
															</Button>
														)
													) : (
														<></>
													)}

													<Button
														disabled
														onClick={handleResetState}
														className="font-quagmire w-full backdrop-blur uppercase"
														type="button"
														variant="gradient"
														size="lg"
													>
														<IconChevronRight className="mt-1 mr-2" size={12} stroke={12} />
														<ButtonLabel>Read</ButtonLabel>
													</Button>
												</div>
											</motion.div>
										</motion.div>
									</div>
								</div>
							)}

							{/* success collectible claim */}
							{suppliable?.type === entityTypes.COLLECTIBLE && (
								<div className="z-[5] relative flex justify-center h-full w-full overflow-auto">
									<div className="md:relative w-auto h-auto my-auto mx-auto">
										<button
											onClick={() => {
												setSuppliable(null);
												setStep(ScanStep.Welcome);

												onClose?.();
											}}
											type="button"
											className="z-10 absolute left-4 md:-left-4 top-4 md:-top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
										>
											<IconX size={28} stroke={2} />
										</button>
										<motion.div
											className="flex my-auto flex-row w-full h-auto max-w-[380px] py-8 md:p-8 md:max-w-none gap-8 overflow-hidden"
											initial={isMediumScreen ? { width: 380 } : { width: 800 }}
											animate={isMediumScreen ? { width: 800 } : { width: 800 }}
											exit={isMediumScreen ? { width: 380 } : { width: 800 }}
											transition={{ delay: 1, duration: 0.3 }}
										>
											<div
												className="relative hidden md:inline-flex self-center shrink-0 w-[320px] border-2 border-white overflow-hidden"
												style={{
													filter: `
                          drop-shadow(0px 0px 6px rgba(0,0,0,.4))
                          drop-shadow(0px 0px 12px rgba(0,0,0,0.3))
                          drop-shadow(0px 0px 18px rgba(0,0,0,0.2))
                          drop-shadow(0px 0px 24px rgba(0,0,0,0.1))`,
												}}
											>
												{suppliable?.image?.original_url ? (
													<img className="block w-full" src="/wutang.png" alt="Macroverse" />
												) : (
													<img className="block w-full" src="/collection-cover-placeholder.jpg" alt="Macroverse" />
												)}
											</div>
											<motion.div
												className="flex flex-col justify-center items-center grow gap-4"
												initial={isMediumScreen ? { opacity: 0 } : { opacity: 1 }}
												animate={isMediumScreen ? { opacity: 1 } : { opacity: 1 }}
												exit={isMediumScreen ? { opacity: 0 } : { opacity: 1 }}
												transition={{ delay: 1.3, duration: 0.3 }}
											>
												<div className="inline-flex h-[40px]">
													<img
														className="block h-full w-auto"
														src="/scannables/scanner-logo-mixprints.svg"
														alt="Macroverse"
													/>
												</div>
												<div
													className="relative inline-flex md:hidden shrink-0 w-[180px] border-2 border-white"
													style={{
														filter: `
                            drop-shadow(0px 0px 6px rgba(0,0,0,.4))
                            drop-shadow(0px 0px 12px rgba(0,0,0,0.3))
                            drop-shadow(0px 0px 18px rgba(0,0,0,0.2))
                            drop-shadow(0px 0px 24px rgba(0,0,0,0.1))`,
													}}
												>
													<img className="block w-full" src={suppliable.image.original_url} alt="Macroverse" />
												</div>
												<div className="flex flex-row justify-center items-center w-full p-3 gap-2 rounded-lg bg-mvblue-400/10 backdrop-blur shadow">
													<div className="flex justify-center items-center flex-row shrink-0 w-[44px] h-[44px] rounded-sm bg-white">
														<IconQrcode className="text-black" size={36} />
													</div>
													<div className="flex flex-col gap-1">
														<div className="flex flex-row items-center gap-1">
															<IconCheckCircle className="text-mvblue-300" size={16} />
															<span className="text-sm text-mvblue-300">Collectible Claimed</span>
														</div>
														<div className="self-start w-auto flex flex-row items-center gap-1 p-1 px-2 rounded-md bg-white/10">
															<span className="text-xs text-white leading-tight">#{suppliable.claim_sequence}</span>
														</div>
													</div>
												</div>
												<div className="flex flex-row justify-between items-center w-full gap-4">
													<div className="flex flex-col">
														<span className="font-semibold text-md text-white leading-tight">{suppliable.name}</span>
														<span className="font-semibold text-md text-white leading-tight">Black Samson RSD US</span>
													</div>
													<div className="inline-flex flex-col justify-center items-center p-2 rounded-md bg-white/10 text-white">
														<IconExp size={22} />
														<span className="mt-1 font-semibold text-2xs leading-tight">+10</span>
													</div>
												</div>
												<span className="text-sm text-white/70 leading-tight">
													You collected the exclusive Record Store Day released of the Mathematics x Wu-Tang Black
													Samson the Bastard Swordsman Vinyl. Nicely done! Limited Edition of 5000.
												</span>
												<div className="flex flex-col w-full mt-2 gap-3">
													<div className="flex flex-row gap-3">
														<Button
															onClick={() => {
																modal.open({
																	title: "",
																	className: "overflow-hidden",
																	component: <ShareCollectionModal suppliable={suppliable} />,
																});
															}}
															className="flex-1 font-quagmire backdrop-blur uppercase !gap-2"
															type="button"
															variant="outline"
															size="md"
															color="blue"
														>
															<ButtonIcon>
																<IconShare size={18} />
															</ButtonIcon>
															<ButtonLabel>Share</ButtonLabel>
														</Button>
														<Button
															className="flex-1 font-quagmire backdrop-blur uppercase !gap-2"
															type="button"
															variant="outline"
															size="md"
															color="blue"
															onClick={() => {
																setSuppliable(null);
																setStep(ScanStep.Welcome);
															}}
														>
															<ButtonIcon>
																<IconQrcode size={18} />
															</ButtonIcon>
															<ButtonLabel className="whitespace-nowrap">Scan More</ButtonLabel>
														</Button>
													</div>
													{onClose ? (
														isSecured ? (
															<div className="flex flex-row justify-center items-center w-full h-[40px] px-3 gap-2 rounded-[14px] bg-mvblue-400/10 backdrop-blur shadow text-mvblue-300">
																<IconCollectionSecured size={18} />
																<span className="font-quagmire uppercase text-lg ">Secured</span>
															</div>
														) : (
															<Button
																onClick={handleSecureIssue}
																className="font-quagmire w-full backdrop-blur uppercase"
																type="button"
																variant="gradient"
																size="md"
																color="blue"
																disabled={securing}
															>
																{securing ? (
																	<>
																		<IconLoader3 className="animate-spin mr-1 mt-1" size={18} />
																		<ButtonLabel>Securing</ButtonLabel>
																	</>
																) : (
																	<>
																		<div className="inline-flex flex-row items-center gap-1 mr-2 mt-[2px]">
																			<IconExp size={20} />
																			<span className="font-aller text-base font-semibold leading-tight">100</span>
																		</div>
																		<ButtonLabel>Secure</ButtonLabel>
																	</>
																)}
															</Button>
														)
													) : (
														<></>
													)}
												</div>
											</motion.div>
										</motion.div>
									</div>
								</div>
							)}

							{/* success badge claim */}
							{suppliable?.type === entityTypes.BADGE && (
								<div className="z-[5] relative flex justify-center h-full w-full overflow-auto">
									<div className="md:relative w-auto h-auto my-auto mx-auto">
										<button
											onClick={() => {
												setSuppliable(null);
												setStep(ScanStep.Welcome);

												onClose?.();
											}}
											type="button"
											className="z-10 absolute left-4 md:-left-4 top-4 md:-top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
										>
											<IconX size={28} stroke={2} />
										</button>
										<motion.div
											className="flex my-auto flex-col w-full h-auto max-w-[420px] py-8 p-8 gap-8 overflow-hidden"
											initial={isMediumScreen ? { height: 350 } : { height: "auto" }}
											animate={isMediumScreen ? { height: "auto" } : { height: "auto" }}
											exit={isMediumScreen ? { height: 350 } : { height: "auto" }}
											transition={{ delay: 1, duration: 0.3 }}
										>
											<div
												className="relative nline-flex self-center shrink-0 w-[180px] overflow-hidden"
												style={{
													filter: `
                          drop-shadow(0px 0px 6px rgba(0,0,0,.4))
                          drop-shadow(0px 0px 12px rgba(0,0,0,0.3))
                          drop-shadow(0px 0px 18px rgba(0,0,0,0.2))
                          drop-shadow(0px 0px 24px rgba(0,0,0,0.1))`,
												}}
											>
												<img className="block w-full" src={suppliable?.image.original_url} alt="Macroverse" />
											</div>
											<motion.div
												className="flex flex-col justify-start items-center grow gap-4"
												initial={isMediumScreen ? { opacity: 0 } : { opacity: 1 }}
												animate={isMediumScreen ? { opacity: 1 } : { opacity: 1 }}
												exit={isMediumScreen ? { opacity: 0 } : { opacity: 1 }}
												transition={{ delay: 1.3, duration: 0.3 }}
											>
												<div className="flex flex-row justify-center items-center w-full p-3 gap-2 rounded-lg bg-mvblue-400/10 backdrop-blur shadow">
													<div className="flex flex-col gap-1">
														<div className="flex flex-row items-center gap-2">
															<IconCheckCircle className="text-mvblue-300" size={20} />
															<span className="text-sm text-mvblue-300">Badge Redeemed</span>
															<div className="self-start w-auto flex flex-row items-center gap-1 p-1 px-2 rounded-md bg-white/10">
																<span className="text-xs text-white leading-tight">#{suppliable.claim_sequence}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="flex flex-row justify-between items-center w-full gap-4">
													<div className="flex flex-col gap-2">
														<span className="font-semibold text-md text-white leading-tight">{suppliable.name}</span>
														<span
															className="text-sm text-white/70 leading-tight"
															// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
															dangerouslySetInnerHTML={{ __html: suppliable.description }}
														/>
													</div>
												</div>
												<div className="flex flex-col w-full mt-2 gap-3">
													<div className="flex flex-row gap-3">
														<Button
															onClick={() => {
																modal.open({
																	title: "",
																	className: "overflow-hidden",
																	component: <ShareCollectionModal suppliable={suppliable} />,
																});
															}}
															className="flex-1 font-quagmire backdrop-blur uppercase !gap-2"
															type="button"
															variant="outline"
															size="md"
															color="blue"
														>
															<ButtonIcon>
																<IconShare size={18} />
															</ButtonIcon>
															<ButtonLabel>Share</ButtonLabel>
														</Button>
														<Button
															className="flex-1 font-quagmire backdrop-blur uppercase !gap-2"
															type="button"
															variant="outline"
															size="md"
															color="blue"
															onClick={() => {
																setSuppliable(null);
																setStep(ScanStep.Welcome);
															}}
														>
															<ButtonIcon>
																<IconQrcode size={18} />
															</ButtonIcon>
															<ButtonLabel className="whitespace-nowrap">Scan More</ButtonLabel>
														</Button>
													</div>
													{onClose ? (
														isSecured ? (
															<div className="flex flex-row justify-center items-center w-full h-[40px] px-3 gap-2 rounded-[14px] bg-mvblue-400/10 backdrop-blur shadow text-mvblue-300">
																<IconCollectionSecured size={18} />
																<span className="font-quagmire uppercase text-lg ">Secured</span>
															</div>
														) : (
															<Button
																onClick={handleSecureIssue}
																className="font-quagmire w-full backdrop-blur uppercase"
																type="button"
																variant="gradient"
																size="md"
																color="blue"
																disabled={securing}
															>
																{securing ? (
																	<>
																		<IconLoader3 className="animate-spin mr-1 mt-1" size={18} />
																		<ButtonLabel>Securing</ButtonLabel>
																	</>
																) : (
																	<>
																		<div className="inline-flex flex-row items-center gap-1 mr-2 mt-[2px]">
																			<IconExp size={20} />
																			<span className="font-aller text-base font-semibold leading-tight">100</span>
																		</div>
																		<ButtonLabel>Secure</ButtonLabel>
																	</>
																)}
															</Button>
														)
													) : (
														<></>
													)}
												</div>
											</motion.div>
										</motion.div>
									</div>
								</div>
							)}
						</ScanContainer>
					)}
				</div>
			</motion.div>
		</motion.div>
	);
}

function ScanModal({
	isOpen,
	onClose,
	isAuth,
}: {
	isOpen: boolean;
	isAuth: boolean;
	onClose: () => void;
}) {
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
		return () => {
			document.body.style.overflow = "";
		};
	}, [isOpen]);

	return ReactDOM.createPortal(
		<AnimatePresence>{isOpen && <Scanner isAuth={isAuth} onClose={onClose} />}</AnimatePresence>,
		document.body,
	);
}

export default ScanModal;
