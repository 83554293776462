import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import { userQueryOptions } from "@/api/queries/user-queries.ts";
import { profileTabTrigger } from "@/components/constants/tabs.ts";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/core/actions/dropdown-menu.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button.tsx";
import Tooltip from "@/components/core/feedbacks/tooltip.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/core/navigations/tabs.tsx";
import ExternalLinkModal from "@/components/external-link-modal.tsx";
import ProfileFilter from "@/components/features/profile/profile-filter.tsx";
import { ProfileHeader } from "@/components/features/profile/profile-header.tsx";
import { PortalContainerProvider } from "@/components/layouts/portal-container-context.tsx";
import {
	PortalContainer,
	PortalContainerBody,
	PortalContainerContent,
	PortalContainerHead,
} from "@/components/layouts/portal-container.tsx";
import {
	IconActivity,
	IconCheckCircle,
	IconCheckCircleFilled,
	IconCollectionHot,
	IconCollections,
	IconDotsHor,
	IconExternalLink,
	IconInstagram,
	IconShare,
	IconUser,
	IconWebsite,
} from "@/components/mvicons";
import IconTwitterx from "@/components/mvicons/icon-twitterx.tsx";
import PortalTopbar from "@/components/portal-topbar.tsx";
import ShareProfileModal from "@/components/share-profile-modal.tsx";
import { FollowButton } from "@/components/socials/follow-button.tsx";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { Link, Outlet, createFileRoute, redirect, useNavigate, useRouterState } from "@tanstack/react-router";
import clsx from "clsx";

let tabItems = [
	{
		id: "collections",
		path: "/user/$username/collections",
		icon: <IconCollectionHot size={20} />,
		label: "Collections",
	},
	{
		id: "about",
		path: "/user/$username/about",
		icon: <IconUser size={20} />,
		label: "About",
	},
	{
		id: "activities",
		path: "/user/$username/activities",
		icon: <IconActivity size={20} />,
		label: "Activity",
	},
];

const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

export const Route = createFileRoute("/_public/user_/$username")({
	loader: ({ context: { queryClient }, params }) => {
		return queryClient.prefetchQuery(userQueryOptions(params.username));
	},
	beforeLoad: ({ location, params }) => {
		const pathname = location.pathname.replace(/\/$/, "");

		if (pathname === `/user/${params.username}`) {
			throw redirect({
				to: "/user/$username/collections",
				params: {
					username: params.username,
				},
			});
		}
	},
	component: () => {
		const modal = useModal();
		const params = Route.useParams();
		const userQuery = useSuspenseQuery(userQueryOptions(params.username));
		const user = userQuery.data;
		const profileQuery = useQuery(profileQueryOptions());
		const profile = profileQuery.data;
		const { location } = useRouterState();

		tabItems = tabItems.map((tab) => {
			return {
				...tab,
				path: tab.path.replace("$username", (user.username || user.macronaut_id) as string),
			};
		});

		const currentPathName = location.pathname;

		const navigate = useNavigate({
			from: "/user/$username",
		});

		return (
			<PortalContainerProvider user={user}>
				<PortalContainer>
					<PortalContainerHead profile={user}>
						{profile ? (
							<PortalTopbar profile={profile} />
						) : (
							<div
								className={clsx(
									"z-[3] fixed left-0 right-0 flex flex-row items-center justify-between w-full gap-4 p-4 md:p-6 bg-gradient-to-b from-mvdark-950/80 to-transparent",
									isProduction ? "top-0" : "top-8",
								)}
							>
								<Link to="/" className="inline-flex h-[44px]">
									<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
								</Link>
								<div className="inline-flex flex-row w-auto ml-auto  gap-2">
									<Button className="font-quagmire backdrop-blur uppercase" type="button" variant="gradient">
										<ButtonLabel>Login</ButtonLabel>
									</Button>
								</div>
							</div>
						)}

						<ProfileHeader profile={user} />
					</PortalContainerHead>

					<PortalContainerBody>
						<PortalContainerContent>
							<div className="relative flex flex-col w-full gap-8 mt-4">
								<div className="flex flex-col w-full pb-4 border-b border-white/20">
									<div className="flex flex-col justify-center items-center w-full gap-2">
										<div className="flex flex-col justify-center items-center w-full gap-1">
											<div className="inline-flex flex-row items-center gap-2">
												<span className="font-semibold text-lg text-white leading-tight">
													{user.first_name} {user.last_name}
												</span>
												<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/40 font-bold text-2xs text-black">
													@{user.username || user.macronaut_id}
												</span>
											</div>
											<span className="text-md text-white/50">{user.headline}</span>
											<div className="inline-flex flex-col justify-center items-center sm:flex-row w-auto gap-4 sm:gap-6 mt-2">
												<div className="inline-flex flex-row items-center w-auto gap-6">
													<div className="inline-flex flex-col sm:flex-row items-center gap-0 sm:gap-2">
														<div className="inline-flex flex-row items-center gap-2">
															<IconCollections className="text-white" size={18} />
															<span className="text-white text-sm">0</span>
														</div>
														<span className="text-white/50 text-sm">Collections</span>
													</div>
													<div className="inline-flex flex-col sm:flex-row items-center gap-0 sm:gap-2">
														<div className="inline-flex flex-row items-center gap-2">
															<IconCheckCircleFilled className="text-white" size={18} />
															<span className="text-white text-sm">{user.followers_count}</span>
														</div>
														<span className="text-white/50 text-sm">Followers</span>
													</div>
													<div className="inline-flex flex-col sm:flex-row items-center gap-0 sm:gap-2">
														<div className="inline-flex flex-row items-center gap-2">
															<IconCheckCircle className="text-white" size={18} />
															<span className="text-white text-sm">{user.following_count}</span>
														</div>
														<span className="text-white/50 text-sm">Following</span>
													</div>
												</div>
												{profile && user.id !== profile?.id && <FollowButton user={user} />}
											</div>
										</div>
									</div>
								</div>

								<div className="flex flex-col w-full">
									<Tabs
										value={currentPathName}
										onValueChange={async (value) => {
											await navigate({
												to: value,
												params: {
													username: (user.username || user.macronaut_id) as string,
												},
											});
										}}
									>
										<TabsList className="border-none !gap-2 sm:!gap-4 !pb-8 sm:!pb-0">
											{tabItems.map((item) => {
												const isActiveTab = currentPathName === item.path;

												return (
													<TabsTrigger key={item.id} className={profileTabTrigger} value={item.path}>
														{isActiveTab ? (
															<div className="inline-flex flex-row items-center w-auto gap-2">
																<span className="inline-flex">{item.icon}</span>
																<span className="absolute sm:relative inline-flex top-full left-1/2 sm:left-0 -translate-x-1/2 sm:translate-x-0 mt-2 sm:mt-0 text-white/70 sm:text-white">
																	{item.label}
																</span>
															</div>
														) : (
															<Tooltip size="sm" content={item.label} sideOffset={8}>
																<div className="inline-flex flex-row items-center w-auto gap-2">
																	<span className="inline-flex">{item.icon}</span>
																</div>
															</Tooltip>
														)}
													</TabsTrigger>
												);
											})}
											{currentPathName === "/collections" && (
												<div className="absolute left-0 top-0 inline-flex">
													<ProfileFilter />
												</div>
											)}
											<div className="absolute right-0 top-0 inline-flex">
												<DropdownMenu modal={false}>
													<DropdownMenuTrigger asChild>
														<button
															type="button"
															className="inline-flex flex-col justify-center items-center w-[38px] h-[38px] rounded-xl data-[state=open]:bg-white data-[state=open]:text-mvdark-950 data-[state=closed]:bg-white/10 data-[state=closed]:text-white/50 data-[state=closed]:hover:text-white data-[state=closed]:hover:bg-white/20"
														>
															<IconDotsHor size={18} />
														</button>
													</DropdownMenuTrigger>
													<DropdownMenuContent sideOffset={-12} alignOffset={-24}>
														<DropdownMenuGroup>
															<DropdownMenuItem>
																<Link
																	to="/user/$username/about"
																	params={{
																		username: (user.username || user.macronaut_id) as string,
																	}}
																>
																	<div className="flex flex-row items-center gap-2 py-1">
																		<IconUser size={16} />
																		<span className="translate-y-[-1px] leading-1">Full Bio</span>
																	</div>
																</Link>
															</DropdownMenuItem>
															<DropdownMenuSeparator />

															<DropdownMenuItem>
																<button
																	onClick={() => {
																		modal.open({
																			title: "Wallet Options Modal",
																			component: <ShareProfileModal user={user} />,
																		});
																	}}
																	type="button"
																	className="flex flex-row items-center gap-2 py-1"
																>
																	<IconShare size={16} />
																	<span className="translate-y-[-1px] leading-1">Share Profile</span>
																</button>
															</DropdownMenuItem>

															{user?.metadata?.twitter_handler && (
																<>
																	<DropdownMenuSeparator />
																	<DropdownMenuItem
																		onSelect={() => {
																			modal.open({
																				component: (
																					<ExternalLinkModal
																						link={`https://www.instagram.com/${user.metadata.twitter_handler}`}
																					/>
																				),
																			});
																		}}
																	>
																		<div className="flex flex-row items-center gap-2 py-1">
																			<IconTwitterx size={16} />
																			<span className="translate-y-[-1px] leading-1">Twitter/X</span>
																		</div>
																	</DropdownMenuItem>
																</>
															)}

															{user?.metadata?.instagram_handler && (
																<>
																	<DropdownMenuSeparator />
																	<DropdownMenuItem
																		onSelect={() => {
																			modal.open({
																				component: (
																					<ExternalLinkModal
																						link={`https://www.instagram.com/${user.metadata.instagram_handler}`}
																					/>
																				),
																			});
																		}}
																	>
																		<div className="flex flex-row items-center gap-2 py-1">
																			<IconInstagram size={16} />
																			<span className="translate-y-[-1px] leading-1">Instagram</span>
																		</div>
																	</DropdownMenuItem>
																</>
															)}

															{user?.metadata?.website && (
																<>
																	<DropdownMenuSeparator />
																	<DropdownMenuItem
																		onSelect={() => {
																			modal.open({
																				component: <ExternalLinkModal link={user.metadata.website as string} />,
																			});
																		}}
																	>
																		<div className="flex flex-row items-center gap-2 py-1">
																			<IconWebsite size={16} />
																			<span className="translate-y-[-1px] leading-1">Website</span>
																		</div>
																	</DropdownMenuItem>
																</>
															)}

															{user.metadata?.custom_link && (
																<>
																	<DropdownMenuSeparator />
																	<DropdownMenuItem
																		onSelect={() => {
																			modal.open({
																				component: <ExternalLinkModal link={user.metadata.custom_link as string} />,
																			});
																		}}
																	>
																		<div className="flex flex-row items-center gap-2 py-1">
																			<IconExternalLink size={16} />
																			<span className="translate-y-[-1px] leading-1">Custom Link</span>
																		</div>
																	</DropdownMenuItem>
																</>
															)}
														</DropdownMenuGroup>
													</DropdownMenuContent>
												</DropdownMenu>
											</div>
										</TabsList>

										<TabsContent value={currentPathName}>
											<Outlet />
										</TabsContent>
									</Tabs>
								</div>
							</div>
						</PortalContainerContent>
					</PortalContainerBody>
				</PortalContainer>
			</PortalContainerProvider>
		);
	},
});
