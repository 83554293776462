import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconSecurity = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M27.185 6.88497L15.8482 0.140537C15.5331 -0.0468457 15.1407 -0.0468457 14.8256 0.140537L3.48873 6.88497C3.18574 7.06527 3 7.39187 3 7.74443V11.5944C3 19.7035 7.68516 27.1098 14.9758 29.9325C15.2079 30.0224 15.4656 30.0225 15.6979 29.9325C22.9709 27.1169 27.6737 19.7266 27.6737 11.5944V7.74443C27.6737 7.39181 27.488 7.06527 27.185 6.88497ZM20.2773 12.7529L14.0098 19.0205C13.6193 19.411 12.9861 19.411 12.5956 19.0205L10.396 16.8209C10.0055 16.4304 10.0055 15.7972 10.396 15.4067C10.7865 15.0162 11.4197 15.0162 11.8102 15.4067L13.3027 16.8991L18.8631 11.3387C19.2536 10.9482 19.8868 10.9482 20.2773 11.3387C20.6679 11.7293 20.6679 12.3624 20.2773 12.7529Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconSecurity;
