import { Slider } from "@/components/core/forms/range-slider.tsx";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Cropper, { type Area, type Point } from "react-easy-crop";

const CROP_WIDTH = 1200;
const CROP_HEIGHT = 240;
const MIN_ZOOM = 1;
const MAX_ZOOM = 5;

const BannerCropper = ({ image, onCrop }: { image: string; onCrop: (value: Area) => void }) => {
	const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [aspect, setAspect] = useState(CROP_WIDTH / CROP_HEIGHT);
	const [objectFit, setObjectFit] = useState<"contain" | "cover">("contain");

	useEffect(() => {
		const updateAspect = () => setAspect(CROP_WIDTH / CROP_HEIGHT);
		window.addEventListener("resize", updateAspect);
		return () => window.removeEventListener("resize", updateAspect);
	}, []);

	const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
		onCrop(croppedAreaPixels);
	};

	const handleZoomChange = (value: number) => {
		setZoom(Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, value)));
	};

	const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

	return ReactDOM.createPortal(
		<div
			className={clsx(
				"!z-[999] fixed left-0 right-0 flex w-full h-[240px] pointer-events-auto overflow-hidden",
				isProduction ? "top-0" : "top-8",
			)}
		>
			<div className="flex w-full bg-black">
				<Cropper
					image={image}
					cropSize={{ width: CROP_WIDTH, height: CROP_HEIGHT }}
					aspect={aspect}
					restrictPosition={true}
					crop={crop}
					zoom={zoom}
					onCropChange={setCrop}
					onZoomChange={setZoom}
					onCropComplete={onCropComplete}
					maxZoom={MAX_ZOOM}
					objectFit="cover"
					style={{
						containerStyle: {
							/*width: `${CROP_WIDTH}px`,*/
							height: `${CROP_HEIGHT}px`,
							left: "50%",
							transform: "translateX(-50%)",
							overflow: "visible",
							backgroundColor: "rgba(0,0,0,.5)",
						},
						mediaStyle: {
							objectFit,
							width: "auto",
							height: "100%",
							minWidth: `${CROP_WIDTH}px`,
							minHeight: `${CROP_HEIGHT}px`,
						},
					}}
					classes={{
						containerClassName: "banner-cropper-backdrop",
						cropAreaClassName: "banner-cropper-area",
					}}
				/>
			</div>

			{image && (
				<div className="z-[50] absolute bottom-4 left-1/2 -ml-[100px] flex flex-row justify-center items-center w-full max-w-[200px] py-2 px-3 bg-mvdark-950/70 backdrop-blur border border-white/20 rounded-full">
					<button type="button" onClick={() => handleZoomChange(zoom - 0.1)} className="text-white/50 hover:text-white">
						<IconMinus size={16} stroke={3} />
					</button>
					<Slider
						value={[zoom]}
						onValueChange={(value) => handleZoomChange(value[0])}
						min={MIN_ZOOM}
						max={MAX_ZOOM}
						step={0.1}
						aria-label="Zoom"
						className="zoom-range block w-full mx-2"
					/>
					<button type="button" onClick={() => handleZoomChange(zoom + 0.1)} className="text-white/50 hover:text-white">
						<IconPlus size={16} stroke={3} />
					</button>
				</div>
			)}
		</div>,
		document.body,
	);
};

export default BannerCropper;
