import { useProfileBackgroundColorMutation, useProfileMutation } from "@/api/queries/profile-queries.ts";
import { Button, ButtonLabel } from "@/components/core/buttons/button.tsx";
import { Form, FormField, FormGroup, FormItem, FormMessage } from "@/components/core/forms/form.tsx";
import { Input } from "@/components/core/forms/input.tsx";
import ReactTrixEditor from "@/components/core/forms/react-trix-editor.tsx";
import ProfileBackgroundPicker from "@/components/features/profile/profile-background-picker.tsx";
import { IconCheckCircle, IconExternalLink, IconInstagram, IconTwitterx, IconWebsite } from "@/components/mvicons";
import { type User, userSchema } from "@/types/user.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";

export default function ProfileForm({ profile, onSubmit }: { profile: User; onSubmit: (data: User) => void }) {
	const profileBackgroundMutation = useProfileBackgroundColorMutation();
	const form = useForm<User>({
		resolver: zodResolver(userSchema),
		defaultValues: {
			username: profile.username || "",
			first_name: profile.first_name || "",
			last_name: profile.last_name || "",
			email: profile.email || "",
			headline: profile.headline || "",
			bio: profile.bio || "",
			metadata: {
				twitter_handler: profile.metadata.twitter_handler || "",
				instagram_handler: profile.metadata.instagram_handler || "",
				website: profile.metadata.website || "",
				custom_link: profile.metadata.custom_link || "",
			},
		},
	});

	const profileMutation = useProfileMutation();

	const handleSubmit = async (data: User) => {
		try {
			await profileMutation.mutateAsync(data);

			onSubmit(data);
		} catch (e) {
			if (e instanceof AxiosError) {
				const errors = e.response?.data?.errors;
				form.setError("username", {
					type: "manual",
					message: errors?.message || errors?.username,
				});
			}
		}
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<div className="flex flex-col w-full gap-8 md:gap-16 mt-[40px]">
					<div className="flex flex-col w-full gap-8">
						{!profile.email && (
							<div className="flex flex-col w-full rounded-xl gap-4 p-6 md:p-8 bg-white bg-opacity-5">
								<div className="flex flex-row items-center w-full gap-3">
									<div className="inline-flex self-start items-center justify-center text-white">
										<IconCheckCircle size={28} />
									</div>
									<div className="flex flex-col w-full gap-1">
										<span className="text-sm text-white font-semibold leading-tight">
											Add your Email and get ready for the take off...
										</span>
										<span className="text-sm text-white/70 leading-tight">
											Connect your email to your profile. It will get you XP rewards, early access and more as
											Macroverse expands.
										</span>
									</div>
								</div>
								<div className="grid grid-cols-12 w-full gap-4">
									<div className="col-span-6 flex w-full">
										<FormField
											control={form.control}
											name="email"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="md" inputStyle="default">
														<Input autoComplete="off" placeholder="Email" {...field} />
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-6 flex w-full">
										<Button type="submit" className="font-quagmire w-full uppercase" size="md" variant="gradient">
											<ButtonLabel>Add Email</ButtonLabel>
										</Button>
									</div>
								</div>
							</div>
						)}

						<div className="flex flex-col w-full gap-0 md:gap-2">
							<span className="text-white text-sm">Choose your Macroverse Username</span>
							<div className="flex flex-col w-full">
								<FormField
									control={form.control}
									name="username"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="lg" inputStyle="outline">
												<Input
													className="md:!text-2xl md:!font-bold"
													prepend="@"
													autoComplete="off"
													placeholder={profile.username || profile.macronaut_id}
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>

						{/*<div className="flex flex-col w-full gap-0 md:gap-2">*/}
						{/*	<span className="text-white text-sm">*/}
						{/*		Choose your Macroverse collection address*/}
						{/*	</span>*/}
						{/*	<div className="flex flex-col w-full">*/}
						{/*		<FormField*/}
						{/*			control={form.control}*/}
						{/*			name="collectionAddress"*/}
						{/*			render={({ field }) => (*/}
						{/*				<FormItem>*/}
						{/*					<FormGroup inputSize="lg" inputStyle="outline">*/}
						{/*						<Input*/}
						{/*							indicator={*/}
						{/*								errors.username ? (*/}
						{/*									<IconCheckCircle*/}
						{/*										className="text-mvblue-300"*/}
						{/*										size={24}*/}
						{/*									/>*/}
						{/*								) : (*/}
						{/*									<IconWarningTriangle*/}
						{/*										className="text-mvred-600"*/}
						{/*										size={24}*/}
						{/*									/>*/}
						{/*								)*/}
						{/*							}*/}
						{/*							prepend="@"*/}
						{/*							autoComplete="off"*/}
						{/*							placeholder="macronaut001.verse.apt"*/}
						{/*							{...field}*/}
						{/*						/>*/}
						{/*					</FormGroup>*/}
						{/*					<FormMessage />*/}
						{/*				</FormItem>*/}
						{/*			)}*/}
						{/*		/>*/}
						{/*	</div>*/}
						{/*</div>*/}

						<div className="flex flex-col w-full gap-0 md:gap-2">
							<span className="text-white text-sm">What's your name?</span>
							<div className="grid grid-cols-12 w-full sm:gap-x-8">
								<div className="col-span-12 sm:col-span-6 flex flex-col w-full">
									<FormField
										control={form.control}
										name="first_name"
										render={({ field }) => (
											<FormItem>
												<FormGroup inputSize="lg" inputStyle="outline">
													<Input
														className="md:!text-2xl md:!font-semibold"
														autoComplete="off"
														placeholder="First Name"
														{...field}
													/>
												</FormGroup>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								<div className="col-span-12 sm:col-span-6 flex flex-col w-full">
									<FormField
										control={form.control}
										name="last_name"
										render={({ field }) => (
											<FormItem>
												<FormGroup inputSize="lg" inputStyle="outline">
													<Input
														className="md:!text-2xl md:!font-semibold"
														autoComplete="off"
														placeholder="Last Name"
														{...field}
													/>
												</FormGroup>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
							</div>
						</div>

						<div className="flex flex-col w-full gap-0 md:gap-2">
							<span className="text-white text-sm">One line short bio</span>
							<div className="flex flex-col w-full">
								<FormField
									control={form.control}
									name="headline"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="lg" inputStyle="outline">
												<Input autoComplete="off" placeholder="Short. Sweet. Awesome. You" {...field} />
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>

						<div id="bio" className="flex flex-col w-full gap-2">
							<span className="text-white text-sm">Full Bio</span>
							<div className="flex flex-col w-full">
								<Controller
									name="bio"
									control={form.control}
									render={({ field }) => (
										<FormItem>
											<ReactTrixEditor
												height={300}
												size="lg"
												placeholder="What should we know about you?"
												value={field.value}
												onChange={field.onChange}
											/>
											<FormMessage />
										</FormItem>
									)}
								/>

								{form.formState.errors?.bio && (
									<div className="flex flex-row items-center w-full gap-1 text-mvred-600">
										{form.formState.errors.bio.message}
									</div>
								)}
							</div>
						</div>

						<div className="flex flex-col w-full gap-0 md:gap-2">
							<span className="text-white text-sm">Connections</span>
							<div className="flex flex-col w-full">
								<FormField
									control={form.control}
									name="metadata.twitter_handler"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="lg" inputStyle="outline" hasIcon>
												<Input
													icon={<IconTwitterx size={22} />}
													prepend="@"
													autoComplete="off"
													placeholder="twittername"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="flex flex-col w-full">
								<FormField
									control={form.control}
									name="metadata.instagram_handler"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="lg" inputStyle="outline">
												<Input
													icon={<IconInstagram size={22} />}
													prepend="@"
													autoComplete="off"
													placeholder="instagramname"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="flex flex-col w-full">
								<FormField
									control={form.control}
									name="metadata.website"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="lg" inputStyle="outline">
												<Input
													icon={<IconWebsite size={22} />}
													autoComplete="off"
													placeholder="www.yoursite.com"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="flex flex-col w-full">
								<FormField
									name="metadata.custom_link"
									control={form.control}
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="lg" inputStyle="outline">
												<Input
													icon={<IconExternalLink size={22} />}
													autoComplete="off"
													placeholder="custom link"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>
					</div>

					<ProfileBackgroundPicker
						onBackgroundChange={async (value) => {
							await profileBackgroundMutation.mutateAsync({
								background_color: value,
							});
						}}
					/>

					<div className="flex flex-col justify-center items-center w-full">
						<div className="inline-flex flex-col w-full max-w-[300px] gap-4">
							<Button type="submit" className="font-quagmire w-full uppercase" size="lg" variant="gradient">
								<ButtonLabel>Update</ButtonLabel>
							</Button>
							<Link to="/overview" type="button" className="w-full">
								<Button type="submit" className="w-full" size="sm" variant="unstyled">
									<ButtonLabel>Cancel</ButtonLabel>
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</form>
		</Form>
	);
}
