import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { IconExp } from "@/components/mvicons";
import { useEffect, useState } from "react";
import XpEngine from "./animations/xpengine";

function QuestRewardGain() {
	return (
		<div className="z-[2] topbar-quest-reward-gain inline-flex flex-col justify-center items-center text-white pointer-events-none">
			<IconExp className="z-[2]" size={32} />
			<span className="z-[2] text-sm">+100</span>
			{/*<div className="z-[1] absolute inset-0 w-[12px] h-[12px] rounded-full bg-mvmain-gradient blur-lg opacity-80" />*/}
		</div>
	);
}

export function TopbarExpTimer({
	duration,
	onClaim,
}: {
	duration: number;
	onClaim?: () => void;
}) {
	const [timeLeft, setTimeLeft] = useState({
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
	const [isTimeUp, setIsTimeUp] = useState(false);
	const [targetTime, setTargetTime] = useState(Date.now() + duration * 1000);

	useEffect(() => {
		const timerInterval = setInterval(() => {
			const now = Date.now();
			const distance = targetTime - now;

			if (distance <= 0) {
				clearInterval(timerInterval);
				setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
				setIsTimeUp(true);
			} else {
				const hours = Math.floor(distance / (1000 * 60 * 60));
				const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((distance % (1000 * 60)) / 1000);
				setTimeLeft({ hours, minutes, seconds });
			}
		}, 1000);

		return () => clearInterval(timerInterval);
	}, [targetTime]);

	const handleClaimExp = () => {
		const questRewardGain = document.querySelector("#exp-machine .topbar-quest-reward-gain");

		questRewardGain?.classList.add("topbar-quest-reward-gain--animate");

		setTimeout(() => {
			onClaim?.();

			setIsTimeUp(false);

			questRewardGain?.classList.remove("topbar-quest-reward-gain--animate");

			setTargetTime(Date.now() + duration * 1000);
		}, 1000);
	};

	return (
		<div id="exp-machine" className="relative flex w-auto">
			<div className="flex flex-row w-full p-2 gap-2 rounded-xl bg-white bg-opacity-5">
				<div className="inline-flex">
					{isTimeUp ? (
						<div className="relative inline-flex w-[80px] h-[80px]">
							<QuestRewardGain />
							<img src="/svg/exp-engine-active.svg" alt="Macroverse" />
							<div className="z-[-1] absolute top-1/2 left-1/2 -ml-[30px] -mt-[30px] w-[60px] h-[60px] rounded-full blur-lg bg-mv-gradient-default transition-all duration-4000 animate-spinAndScale" />
						</div>
					) : (
						<XpEngine className="w-[80px] h-[80px]" />
					)}
				</div>
				<div className="flex flex-col justify-center w-auto gap-2">
					<span className="text-white text-sm">Claim your next XP in</span>
					<div className="relative flex flex-col w-full">
						{isTimeUp ? (
							<Button
								onClick={handleClaimExp}
								className="font-quagmire w-full h-[36px] max-w-[80px] bg-mvdark-950/60 uppercase"
								type="button"
								size="sm"
								variant="gradient"
								gradient="default"
							>
								<ButtonLabel>Claim</ButtonLabel>
							</Button>
						) : (
							<div className="font-aller inline-flex self-start flex-row items-center w-auto pt-1 pb-1.5 px-3 gap-1 rounded-2xl text-white bg-white bg-opacity-5">
								<span className="font-bold text-xs text-white leading-tight">
									{String(timeLeft.hours).padStart(2, "0")}
								</span>
								<span className="font-bold text-xs text-white/50 leading-tight">:</span>
								<span className="font-bold text-xs text-white leading-tight">
									{String(timeLeft.minutes).padStart(2, "0")}
								</span>
								<span className="font-bold text-xs text-white/50 leading-tight">:</span>
								<span className="font-bold text-xs text-white leading-tight">
									{String(timeLeft.seconds).padStart(2, "0")}
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
