import { profileQueryOptions, useProfileDismissWelcome } from "@/api/queries/profile-queries.ts";
import QuestBoard from "@/components/features/profile/quest-board.tsx";
import { IconDrop, IconPlus, IconWatchlist } from "@/components/mvicons";
import Welcome from "@/components/welcome.tsx";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";

export const Route = createFileRoute("/_profile/overview")({
	loader: ({ context: { queryClient } }) => {
		return queryClient.ensureQueryData(profileQueryOptions());
	},
	component: Page,
});

function Page() {
	const profileDismissMutation = useProfileDismissWelcome();
	const userQuery = useSuspenseQuery(profileQueryOptions());
	const user = userQuery.data;
	const hasModalOpened = useRef(false);
	const [isWelcomeOpen, setWelcomeOpen] = useState(false);

	useEffect(() => {
		if (!hasModalOpened.current && user.metadata.show_welcome) {
			setWelcomeOpen(true);
			hasModalOpened.current = true;
		}
	}, [user.metadata.show_welcome]);

	useEffect(() => {
		if (isWelcomeOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}

		return () => {
			document.body.style.overflow = "";
		};
	}, [isWelcomeOpen]);

	return (
		<div className="flex flex-col w-full gap-6">
			<Welcome
				isOpen={isWelcomeOpen}
				onClose={async () => {
					await profileDismissMutation.mutateAsync();
					setWelcomeOpen(false);
				}}
			/>

			{!isWelcomeOpen && <QuestBoard />}

			<div className="flex flex-col w-full gap-2">
				<div className="flex flex-row items-center gap-2">
					<span className="text-sm font-semibold text-white">Up Next</span>
					<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
						Coming Soon
					</span>
				</div>
				<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
					<div className="inline-flex text-white">
						<IconPlus size={38} />
					</div>
					<div className="flex flex-col w-full max-w-[280px] gap-2">
						<span className="text-sm font-semibold text-white leading-tight">Your latest and greatest content.</span>
						<span className="text-sm text-white/70 leading-tight">
							Series you’re reading are automatically waiting for you here. Add them to your list and queue them up here
							as well.
						</span>
					</div>
				</div>
			</div>

			{/* <div className="flex flex-col w-full gap-2">
				<div className="flex flex-row items-center gap-2">
					<span className="text-sm font-semibold text-white">Watchlist</span>
					<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
						Coming Soon
					</span>
				</div>
				<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
					<div className="inline-flex text-white">
						<IconWatchlist size={38} />
					</div>
					<div className="flex flex-col w-full max-w-[280px] gap-2">
						<span className="text-sm font-semibold text-white leading-tight">
							Never miss the latest creations from your favorite Makers.{" "}
						</span>
						<span className="text-sm text-white/70 leading-tight">
							Makers gotta make and we don’t want to miss their hottest new work. Your Watchlist will keep you in the
							know.
						</span>
					</div>
				</div>
			</div>

			<div className="flex flex-col w-full gap-2">
				<div className="flex flex-row items-center gap-2">
					<span className="text-sm font-semibold text-white">Drops</span>
					<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
						Coming Soon
					</span>
				</div>
				<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
					<div className="inline-flex text-white">
						<IconDrop size={38} />
					</div>
					<div className="flex flex-col w-full max-w-[280px] gap-2">
						<span className="text-sm font-semibold text-white leading-tight">
							Expand your collection with Drops connected to your favorite series.
						</span>
						<span className="text-sm text-white/70 leading-tight">
							As Drops are released you’ll find them here. Add them to your collection to keep them forever.
						</span>
					</div>
				</div>
			</div> */}
		</div>
	);
}
