import { Slider } from "@/components/core/forms/range-slider.tsx";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import React from "react";
import Cropper, { type Area } from "react-easy-crop";

const CROP_WIDTH = 170;
const CROP_HEIGHT = 170;
const MIN_ZOOM = 1;
const MAX_ZOOM = 5;

const ProfilePictureCropper = ({ image, onCrop }: { image: any; onCrop: (value: Area) => void }) => {
	const [crop, setCrop] = React.useState({ x: 0, y: 0 });
	const [zoom, setZoom] = React.useState(1);

	const onCropChange = (crop) => {
		setCrop(crop);
	};

	const onCropComplete = (croppedArea, croppedAreaPixels: Area) => {
		onCrop(croppedAreaPixels);
	};

	const handleZoomChange = (value: number) => {
		setZoom(Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, value)));
	};

	return (
		<div className="flex flex-col items-center w-full h-auto gap-6">
			<div className="relative flex w-[calc(100%+100px)] sm:w-[calc(100%+30px)] h-[320px] sm:-mt-12 bg-black overflow-hidden">
				<Cropper
					image={image}
					crop={crop}
					zoom={zoom}
					aspect={1}
					cropShape="round"
					showGrid={false}
					cropSize={{ width: CROP_WIDTH, height: CROP_HEIGHT }}
					restrictPosition={true}
					onCropComplete={onCropComplete}
					onCropChange={onCropChange}
					onZoomChange={handleZoomChange}
					objectFit="contain"
					style={{
						containerStyle: {
							/*width: `${CROP_WIDTH}px`,*/
							height: `${CROP_HEIGHT}px`,
							left: "50%",
							top: "50%",
							transform: "translate(-50%, -50%)",
							overflow: "visible",
							backgroundColor: "rgba(0,0,0,1)",
						},
						mediaStyle: {
							objectFit: "contain",
							width: "max-content",
							height: "100%",
							minWidth: `${CROP_WIDTH}px`,
							minHeight: `${CROP_HEIGHT}px`,
						},
					}}
					classes={{
						containerClassName: "avatar-cropper-backdrop",
						cropAreaClassName: "avatar-cropper-area",
					}}
				/>
				<div className="absolute bottom-4 left-1/2 -ml-[100px] flex flex-row justify-center items-center w-full max-w-[200px] py-2 px-3 bg-mvdark-950/70 backdrop-blur border border-white/20 rounded-full">
					<button type="button" onClick={() => handleZoomChange(zoom - 0.1)} className="text-white/50 hover:text-white">
						<IconMinus size={16} stroke={3} />
					</button>
					<Slider
						value={[zoom]}
						onValueChange={(value) => handleZoomChange(value[0])}
						min={MIN_ZOOM}
						max={MAX_ZOOM}
						step={0.1}
						aria-label="Zoom"
						className="zoom-range block w-full mx-2"
					/>
					<button type="button" onClick={() => handleZoomChange(zoom + 0.1)} className="text-white/50 hover:text-white">
						<IconPlus size={16} stroke={3} />
					</button>
				</div>
			</div>

			<div className="flex flex-col w-full max-w-[380px] gap-8">
				<div className="flex flex-col justify-center items-center w-full max-w-[380px] gap-4">
					<p className="font-semibold text-white leading-tight">
						Adjust the size and placement of your Profile Picture.
					</p>
					<p className="text-white/70 leading-tight">
						When it's perfect, update it and jump back into the Macroverse...
					</p>
				</div>
			</div>
		</div>
	);
};

export default ProfilePictureCropper;
