import { Button } from "@/components/core/buttons/button.tsx";
import { IconCircleCheck } from "@tabler/icons-react";

export function RequestPermissionModal({
	handleConfirm,
}: {
	handleConfirm: () => void;
}) {
	return (
		<div className="flex flex-col w-full gap-8">
			<div className="flex flex-col w-full gap-6">
				<div className="flex flex-col w-full gap-3">
					<div className="flex flex-row justify-center items-center gap-2 text-mvblue-300">
						<IconCircleCheck size={32} stroke={2} />
						<span className="text-center font-semibold text-xl leading-none tracking-tight">Enable Camera</span>
					</div>
					<span className="font-quagmire text-center font-semibold text-2xl leading-none tracking-tight uppercase text-white">
						Start QR Code Scan
					</span>
				</div>
				<div className="flex flex-col w-full gap-3">
					<span className="text-white/70 text-center">
						We’ll need your permission to use your camera to scan QR codes and redeem your digital twins and bonus perks
						and rewards.
					</span>
				</div>
			</div>
			<div className="flex flex-col w-full">
				<Button
					onClick={handleConfirm}
					className="font-quagmire self-center bg-mvdark-950 w-full uppercase"
					variant="gradient"
					size="lg"
				>
					Enable Camera
				</Button>
			</div>
		</div>
	);
}
