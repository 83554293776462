import { transformSearch } from "@/api/helper.ts";
import badgeService from "@/api/services/badge-service.ts";
import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

export const badgeSearchSchema = z.object({
	search: z.string().optional(),
	is_owned: z.boolean().optional(),
	include: z.string().optional(),
});

export const badgesQueryOptions = (search?: z.infer<typeof badgeSearchSchema>, options?: { enabled: boolean }) => {
	return queryOptions({
		queryKey: ["badges", search],
		queryFn: () => badgeService.list(transformSearch(search, ["search", "is_owned"])),
		...options,
	});
};
