import { Button, ButtonLabel } from "@/components/core/buttons/button.tsx";
import type {
	ConfirmModalProps,
	DefaultModalProps,
	ModalAction,
	ModalProps,
	ModalState,
} from "@/components/core/modals/types.ts";
import { useScreenWidth } from "@/hooks/use-screen-width.ts";
import * as ModalPrimitive from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { IconAlertTriangle, IconCircleCheck, IconX } from "@tabler/icons-react";
import clsx from "clsx";
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	type HTMLAttributes,
	type ReactNode,
	forwardRef,
	useReducer,
	useState,
} from "react";
import { useWindowSize } from "usehooks-ts";
import { Drawer } from "vaul";
import { ModalControlContext } from "./use-modal-controller.ts";
import { ModalContext } from "./use-modal.ts";

const Modal = ModalPrimitive.Root;
const DialogPortal = ModalPrimitive.Portal;

/**
 * Overlay
 */
const DialogOverlay = forwardRef<
	ElementRef<typeof ModalPrimitive.Overlay>,
	ComponentPropsWithoutRef<typeof ModalPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<ModalPrimitive.Overlay
		ref={ref}
		className={clsx(
			"fixed inset-0 z-[90] px-4 bg-mvdark-950/60 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
			className,
		)}
		{...props}
	/>
));
DialogOverlay.displayName = ModalPrimitive.Overlay.displayName;

/**
 * Main Modal
 */
const DialogContent = forwardRef<
	ElementRef<typeof ModalPrimitive.Content>,
	ComponentPropsWithoutRef<typeof ModalPrimitive.Content> & {
		/*open?: boolean;*/
		/*onOpenChange?: (open: boolean) => void;*/
	}
>(({ className, children, ...props }, ref) => {
	const { width = 0 } = useWindowSize();
	const isMobile = width < 640;
	const isTablet = width > 640 && width < 768;

	return (
		<DialogPortal>
			<DialogOverlay
				className={clsx("font-aller fixed inset-0 flex justify-center w-full h-full overflow-y-auto", {
					"items-center": !isMobile && !isTablet,
				})}
			>
				<ModalPrimitive.Content
					ref={ref}
					tabIndex={undefined}
					className={clsx(
						"duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
						className,
					)}
					{...props}
				>
					<VisuallyHidden>
						<ModalPrimitive.Title>Modal Title</ModalPrimitive.Title>
					</VisuallyHidden>
					{children}
				</ModalPrimitive.Content>
			</DialogOverlay>
		</DialogPortal>
	);
});
DialogContent.displayName = ModalPrimitive.Content.displayName;

/**
 * Modal Header
 */
const DialogHeader = ({
	className,
	hasCloseButton = true,
	...props
}: HTMLAttributes<HTMLDivElement> & { hasCloseButton?: boolean }) => (
	<div className={clsx("", className)}>
		<div {...props} />
		{hasCloseButton && (
			<ModalPrimitive.Close className="flex opacity-50 ring-offset-background transition-opacity hover:opacity-100 outline-none border-none ring-0 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
				<IconX size={16} stroke={2} />
				<span className="sr-only">Close</span>
			</ModalPrimitive.Close>
		)}
	</div>
);
DialogHeader.displayName = "ModalHeader";

/**
 * Modal Icon
 */
const ModalIcon = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
	<div className={clsx("", className)} {...props} />
);
ModalIcon.displayName = "ModalIcon";

/**
 * Modal Title
 */
const DialogTitle = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
	<div className={clsx("", className)} {...props} />
);
DialogTitle.displayName = "ModalTitle";

/**
 * Modal Description
 */
const ModalDescription = forwardRef<
	ElementRef<typeof ModalPrimitive.Description>,
	ComponentPropsWithoutRef<typeof ModalPrimitive.Description>
>(({ className, ...props }, ref) => (
	<ModalPrimitive.Description ref={ref} className={clsx("", className)} {...props} />
));
ModalDescription.displayName = ModalPrimitive.Description.displayName;

/**
 * Modal Body
 */
const ModalBody = ({
	className,
	hasCloseButton = false,
	...props
}: HTMLAttributes<HTMLDivElement> & { hasCloseButton?: boolean }) => {
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < 640;

	return (
		<div className={className}>
			<div className="flex flex-1 flex-col w-full h-full" {...props} />

			{!isMobile && hasCloseButton && (
				<ModalPrimitive.Close className="absolute left-4 top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 disabled:pointer-events-none bg-white/20 hover:bg-white/30 backdrop-blur text-white">
					<VisuallyHidden>Close</VisuallyHidden>
					<IconX size={28} stroke={2} />
				</ModalPrimitive.Close>
			)}
		</div>
	);
};
ModalBody.displayName = "ModalBody";

/**
 * Modal Footer
 */
const ModalFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={clsx("flex flex-row justify-end w-full py-4 px-5 gap-2", className)} {...props} />
);
ModalFooter.displayName = "ModalFooter";

/**
 * Base Modal
 */
const BaseModal = ({
	title,
	description,
	component,
	className,
	open,
	onOpenChange,
	size = "md",
	decor = null,
	children,
}: DefaultModalProps & {
	children?: React.ReactNode;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}) => {
	const handleClose = () => {
		onOpenChange?.(false);
	};
	const { width = 0 } = useWindowSize();
	const isMobile = width < 640;
	const isTablet = width > 640 && width < 768;

	return (
		<>
			{isMobile ? (
				<Drawer.Root open={open} onOpenChange={onOpenChange} fixed>
					<Drawer.Portal>
						<Drawer.Overlay className="z-[50] fixed inset-0 bg-mvdark-950/60 backdrop-blur-sm " />
						<Drawer.Content className="z-[60] flex flex-col fixed bottom-0 left-0 right-0 max-h-[82vh] rounded-t-4xl border border-white/10 bg-mvdark-950 bg-opacity-95 overflow-hidden">
							<div className="flex flex-col w-full overflow-y-auto">
								<div className="flex flex-row w-full pt-4">
									<Drawer.Handle />
								</div>
								<VisuallyHidden>
									<ModalPrimitive.Title>Modal Title</ModalPrimitive.Title>
								</VisuallyHidden>
								<div className="flex flex-col w-full p-8 pb-16">{component}</div>
							</div>
						</Drawer.Content>
					</Drawer.Portal>
				</Drawer.Root>
			) : (
				<Modal open={open} onOpenChange={handleClose}>
					<DialogContent
						open={open}
						onOpenChange={handleClose}
						className={clsx(
							"flex flex-col relative h-max max-h-max w-full rounded-4xl my-24 border border-white/10 bg-mvdark-950 bg-opacity-95 shadow-xl",
							{
								"self-center w-auto max-w-max": size === "auto",
								"max-w-[380px]": size === "xs",
								"max-w-[420px]": size === "sm",
								"max-w-[520px]": size === "md",
								"max-w-[720px]": size === "lg",
								"max-w-[1024px]": size === "xl",
							},
							{
								"overflow-hidden": decor,
							},
							className,
						)}
						onPointerDownOutside={(e) => e.preventDefault()}
					>
						<VisuallyHidden>
							<DialogHeader className="flex flex-row w-full justify-between bg-slate-100 rounded-t-lg py-4 px-5">
								<DialogTitle className="text-md font-semibold leading-none tracking-tight">{title}</DialogTitle>
								<ModalDescription>{description}</ModalDescription>
							</DialogHeader>
						</VisuallyHidden>
						<ModalBody hasCloseButton={true} className="z-[2] relative flex flex-1 flex-col w-full h-full py-12 px-4">
							{component}
						</ModalBody>
						{decor && (
							<div className="z-[1] absolute bottom-full left-0 right-0 -mb-32">
								<img src={decor} alt="Decorative Element" />
							</div>
						)}
					</DialogContent>
				</Modal>
			)}
		</>
	);
};

/**
 * Confirm Modal
 */
const ConfirmModal = ({
	title,
	description,
	open,
	onOpenChange,
	onConfirm,
	variant = "warning",
	children,
}: ConfirmModalProps) => {
	const [loading, setLoading] = useState(false);
	const handleClose = () => onOpenChange?.(false);

	const handleConfirm = async () => {
		setLoading(true);

		try {
			await onConfirm?.();

			handleClose();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal open={open} onOpenChange={handleClose}>
			<DialogContent
				className="flex flex-col relative h-max max-h-max w-full rounded-4xl my-12 border border-white/10 bg-mvdark-950 bg-opacity-95 shadow-xl self-center max-w-[420px]"
				onPointerDownOutside={(e) => e.preventDefault()}
			>
				{children ? (
					<ModalBody className="px-8 py-12" hasCloseButton={true}>
						{typeof children === "function"
							? children(handleConfirm) // If children is a function, call it with handleConfirm
							: children}
					</ModalBody>
				) : (
					<>
						<ModalBody className="px-12 pt-8 pb-0" hasCloseButton={true}>
							<div className="flex flex-col justify-center items-center w-full">
								<ModalIcon
									className={clsx(
										"inline-flex justify-center items-center self-center shrink-0 grow-0 w-[48px] h-[48px]",
										{
											"text-mvred-600": variant === "warning",
											"text-mvblue-300": variant === "success",
										},
									)}
								>
									{variant === "warning" && <IconAlertTriangle size={44} stroke={2} />}
									{variant === "success" && <IconCircleCheck size={44} stroke={2} />}
								</ModalIcon>
								<div className="flex flex-col w-full">
									<DialogTitle
										className={clsx("text-center font-semibold text-lg leading-none tracking-tight mb-2", {
											"text-mvred-600": variant === "warning",
											"text-mvblue-300": variant === "success",
										})}
									>
										{title}
									</DialogTitle>
									<ModalDescription className="flex flex-col w-full my-4 text-white text-center">
										{description}
									</ModalDescription>
								</div>
							</div>
						</ModalBody>
						<ModalFooter className="flex flex-col justify-center items-center w-full !px-12 !pb-8">
							<Button
								onClick={handleConfirm}
								className="font-quagmire self-center bg-mvdark-950 w-full uppercase"
								isLoading={loading}
								variant="gradient"
								size="lg"
							>
								<ButtonLabel>Yes, Continue</ButtonLabel>
							</Button>
							<Button
								onClick={() => {
									handleClose();
								}}
								className="self-center w-full"
								isLoading={loading}
								variant="unstyled"
								size="sm"
							>
								<ButtonLabel>Cancel</ButtonLabel>
							</Button>
						</ModalFooter>
					</>
				)}
			</DialogContent>
		</Modal>
	);
};

const actionTypes = {
	OPEN_MODAL: "OPEN_MODAL",
	CLOSE_MODAL: "CLOSE_MODAL",
	REMOVE_MODAL: "REMOVE_MODAL",
};

const reducer = (state: ModalState, action: ModalAction): ModalState => {
	switch (action.type) {
		case actionTypes.OPEN_MODAL:
			return {
				...state,
				modals: [...state.modals, { ...action.payload, isClosing: false }],
			};

		case actionTypes.CLOSE_MODAL:
			return {
				...state,
				modals: state.modals.map((modal) => (modal.id === action.payload.id ? { ...modal, isClosing: true } : modal)),
			};

		case actionTypes.REMOVE_MODAL:
			return {
				...state,
				modals: state.modals.filter((modal) => modal.id !== action.payload.id),
			};

		default:
			return state;
	}
};

const DialogProvider = ({ children }: { children?: ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, { modals: [] });

	const openModal = (modalProps: ModalProps) => {
		const id = Date.now();
		const modalType = modalProps.type || "default";

		if ("type" in modalProps && modalProps.type === "confirm") {
			// biome-ignore lint: <explanation>
			modalProps = { ...modalProps };
		} else {
			// biome-ignore lint: <explanation>
			modalProps = { ...modalProps, type: modalType };
		}

		dispatch({ type: "OPEN_MODAL", payload: { ...modalProps, id } });

		return id;
	};

	const getModal = (modalId: number) => state.modals.find((modal) => modal.id === modalId);

	const closeModal = (modalId: number) => {
		const currentModal = getModal(modalId);

		dispatch({ type: "CLOSE_MODAL", payload: { id: modalId } });

		if (currentModal !== undefined && currentModal.onDismiss !== undefined) {
			currentModal.onDismiss();
		}
	};

	const renderModals = () =>
		state.modals.map((modal) => (
			<ModalControlContext.Provider key={modal.id} value={{ close: () => closeModal(modal.id) }}>
				<div key={modal.id}>
					{modal.type === "confirm" ? (
						<ConfirmModal {...modal} onOpenChange={() => closeModal(modal.id)} open={!modal.isClosing} />
					) : (
						<BaseModal
							{...modal} // recognized as DefaultModalProps
							onOpenChange={() => closeModal(modal.id)}
							open={!modal.isClosing}
						/>
					)}
				</div>
			</ModalControlContext.Provider>
		));

	return (
		<ModalContext.Provider value={{ state, open: openModal, close: closeModal }}>
			{renderModals()}
			{children}
		</ModalContext.Provider>
	);
};

export { DialogProvider, DialogContent, DialogHeader, DialogTitle, DialogOverlay };
