import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/core/actions/dropdown-menu.tsx";
import { IconDotsHor, IconMaximize, IconMinimize, IconScroll, IconSwipe, IconTap } from "@/components/mvicons";
import ReaderDropdownItems from "@/components/reader/reader-dropdown-items.tsx";
import { IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ScrollArrowUp from "../mvsvg/scroll-arrow-up";
import SwipeArrowLeft from "../mvsvg/swipe-arrow-left";
import TapArrowLeft from "../mvsvg/tap-arrow-left";
import TapArrowRight from "../mvsvg/tap-arrow-right";
import ScrollReader from "./scroll-reader";
import SwipeReader from "./swipe-reader";
import TapReader from "./tap-reader";

function TapReaderPreview({ setShowReaderInfo, setShowReaderType, setShowReaderPreview }) {
	const [step, setStep] = useState(1);

	return (
		<motion.div
			className={clsx(
				"z-[8] fixed inset-0 flex flex-col justify-center items-center w-full h-full",
				step === 1 && "bg-black/90 backdrop-blur-xl",
				step === 2 && "bg-black/70 backdrop-blur",
				step === 3 && "bg-black/50 backdrop-blur-sm",
			)}
		>
			<AnimatePresence>
				<div className="z-[4] absolute inset-0 w-full h-full">
					{/* Step 1 Button */}
					{step === 1 && (
						<motion.button
							type="button"
							className="group absolute right-[10%] top-1/2 flex flex-row items-center w-auto gap-2"
							initial={{ x: 0, y: "-50%", opacity: 1 }}
							animate={{ x: 0, opacity: 1 }}
							transition={{
								duration: 0.2,
								ease: [0.33, 1, 0.68, 1],
							}}
							onClick={() => setStep(2)}
						>
							<div className="inline-flex flex-col w-auto text-white/80 group-hover:text-white">
								<span className="font-bold text-right">Click the RIGHT SIDE</span>
								<span className="font-bold text-right">to Go Forward</span>
							</div>
							<div className="inline-flex text-white group-hover:text-mvblue-300">
								<TapArrowRight />
							</div>
						</motion.button>
					)}

					{/* Step 2 Button */}
					{step === 2 && (
						<motion.button
							type="button"
							className="group absolute left-[10%] top-1/2 flex flex-row items-center w-auto gap-2"
							initial={{ x: -50, y: "-50%", opacity: 1 }}
							animate={{ x: 0, opacity: 1 }}
							transition={{
								duration: 0.2,
								ease: [0.33, 1, 0.68, 1],
							}}
							onClick={() => setStep(3)}
						>
							<div className="inline-flex text-white group-hover:text-mvblue-300">
								<TapArrowLeft />
							</div>
							<div className="inline-flex flex-col w-auto text-white/80 group-hover:text-white">
								<span className="font-bold text-left">Click the LEFT SIDE</span>
								<span className="font-bold text-left">to Go Back</span>
							</div>
						</motion.button>
					)}

					{/* Step 3 Button */}
					{step === 3 && (
						<motion.button
							type="button"
							className="group absolute right-[10%] top-1/2 flex flex-row items-center w-auto gap-2"
							initial={{ x: 50, y: "-50%", opacity: 1 }}
							animate={step > 3 ? { x: 50, opacity: 0 } : { x: 0, opacity: 1 }}
							transition={{
								duration: 0.2,
								ease: [0.33, 1, 0.68, 1],
							}}
							onClick={() => {
								setShowReaderInfo(false);
								setShowReaderType(false);
								setShowReaderPreview(false);
							}}
						>
							<div className="inline-flex flex-col w-auto text-white/80 group-hover:text-white">
								<span className="text-right text-xl font-bold uppercase italic">Let's Go</span>
							</div>
							<div className="inline-flex text-white group-hover:text-mvblue-300">
								<TapArrowRight />
							</div>
						</motion.button>
					)}
				</div>
			</AnimatePresence>
		</motion.div>
	);
}

function ScrollReaderPreview({ setShowReaderInfo, setShowReaderType, setShowReaderPreview }) {
	const handleWheel = (event) => {
		if (event.deltaY > 0) {
			setShowReaderInfo(false);
			setShowReaderType(false);
			setShowReaderPreview(false);
		}
	};

	return (
		<motion.div
			className="z-[8] fixed inset-0 flex flex-col justify-center items-center w-full h-full bg-black/80 backdrop-blur-xl"
			onWheel={handleWheel}
		>
			<AnimatePresence>
				<motion.div
					className={clsx(
						"group absolute top-1/2 left-1/2 w-[160px] h-[160px] -ml-[80px] -mt-[80px]",
						"flex flex-col items-center justify-center cursor-pointer gap-4",
						"text-white/70 hover:text-white",
					)}
					drag="y"
					dragConstraints={{ top: 0, bottom: 0 }}
					dragElastic={0.3}
					onDragEnd={(event, info) => {
						if (info.velocity.y < -200) {
							// Detect fast swipe up
							setShowReaderInfo(false);
							setShowReaderType(false);
							setShowReaderPreview(false);
						}
					}}
					initial={{ y: 0, opacity: 1 }}
					exit={{
						opacity: 0,
						transition: { duration: 0.3 },
					}}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				>
					<ScrollArrowUp className="inline-flex shrink-0 w-auto text-white group-hover:text-mvblue-300" />
					<span className="font-semibold text-left">Scroll to Start</span>
				</motion.div>
			</AnimatePresence>
		</motion.div>
	);
}

function SwipeReaderPreview({ setShowReaderInfo, setShowReaderType, setShowReaderPreview }) {
	const handleWheel = (event) => {
		if (event.deltaX > 0) {
			setShowReaderInfo(false);
			setShowReaderType(false);
			setShowReaderPreview(false);
		}
	};

	return (
		<motion.div
			className="z-[8] fixed inset-0 flex flex-col justify-center items-center w-full h-full bg-black/80 backdrop-blur-xl"
			onWheel={handleWheel}
		>
			<AnimatePresence>
				<motion.div
					className={clsx(
						"group absolute top-1/2 left-1/2 w-[400px] h-[100px] -ml-[200px] -mt-[50px]",
						"flex flex-row items-center justify-center gap-4 cursor-pointer",
						"text-white/70 hover:text-white",
					)}
					drag="x"
					dragConstraints={{ left: 0, right: 0 }}
					dragElastic={0.3}
					onDragEnd={(event, info) => {
						if (info.velocity.x < -200) {
							// Detect fast swipe up
							setShowReaderInfo(false);
							setShowReaderType(false);
							setShowReaderPreview(false);
						}
					}}
					initial={{ x: 0, opacity: 1 }}
					exit={{
						opacity: 0,
						transition: { duration: 0.3 },
					}}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				>
					<SwipeArrowLeft className="inline-flex shrink-0 w-auto text-white group-hover:text-mvblue-300" />
					<span className="font-semibold text-left">Swipe to Start</span>
				</motion.div>
			</AnimatePresence>
		</motion.div>
	);
}

const Reader = ({ isOpen, onClose }) => {
	const [activeReaderType, setActiveReaderType] = useState("scroll");
	const [controlsHidden, setControlsHidden] = useState(false);
	const [showReaderType, setShowReaderType] = useState(true);
	const [showReaderInfo, setShowReaderInfo] = useState(true);
	const [showReaderPreview, setShowReaderPreview] = useState(true);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
		return () => {
			document.body.style.overflow = "";
		};
	}, [isOpen]);

	const handleReaderType = (type) => {
		setActiveReaderType(type);
		setShowReaderPreview(true);
	};

	const handleCloseReader = () => {
		onClose();
		document.body.style.overflow = "";
	};

	const readerTypes = [
		{ type: "tap", icon: <IconTap size={24} />, label: "Tap" },
		{ type: "scroll", icon: <IconScroll size={24} />, label: "Scroll" },
		{ type: "swipe", icon: <IconSwipe size={24} />, label: "Swipe" },
	];

	const renderPreview = () => {
		switch (activeReaderType) {
			case "tap":
				return (
					<TapReaderPreview
						setShowReaderPreview={setShowReaderPreview}
						setShowReaderInfo={setShowReaderInfo}
						setShowReaderType={setShowReaderType}
					/>
				);
			case "scroll":
				return (
					<ScrollReaderPreview
						setShowReaderPreview={setShowReaderPreview}
						setShowReaderInfo={setShowReaderInfo}
						setShowReaderType={setShowReaderType}
					/>
				);
			case "swipe":
				return (
					<SwipeReaderPreview
						setShowReaderPreview={setShowReaderPreview}
						setShowReaderInfo={setShowReaderInfo}
						setShowReaderType={setShowReaderType}
					/>
				);
		}
	};

	const renderContent = () => {
		switch (activeReaderType) {
			case "tap":
				return <TapReader />;
			case "scroll":
				return <ScrollReader />;
			case "swipe":
				return <SwipeReader />;
		}
	};

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className={clsx(
						"z-50 fixed right-0 bottom-0 left-0 top-0 flex items-center justify-center min-h-screen w-full",
					)}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* reader ui overlay */}
					<motion.div
						className="absolute inset-0 h-full w-full bg-black"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* reader ui container */}
					<motion.div
						className="relative flex flex-col justify-center items-center h-full w-full"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* comics information */}
						<AnimatePresence>
							{showReaderInfo && (
								<motion.div
									className="z-[9] top-12 fixed left-1/2 inline-flex flex-col w-auto gap-6"
									initial={{ y: 0, x: "-50%", opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: -100, opacity: 0 }}
									transition={{ duration: 0.5, ease: [0.33, 1, 0.68, 1] }}
								>
									<div className="flex flex-col justify-center items-center w-full gap-3">
										<div className="inline-flex w-[160px]">
											<img className="block w-full" src="/svg/authentic-editions.svg" alt="Macroverse" />
										</div>
										<div className="inline-flex flex-row items-center divide-x">
											<span className="font-quagmire px-3 uppercase text-sm text-white/70 leading-tight">Season 1</span>
											<span className="font-quagmire px-3 uppercase text-sm text-white/70 leading-tight">
												Episode 1
											</span>
										</div>
									</div>
									<div className="flex flex-row items-center w-full">
										<span className="font-quagmire font-bold text-3xl text-white">11+</span>
										<div className="inline-flex flex-col w-auto py-1 pl-4 ml-4 border-l border-white">
											<span className="text-white/70 leading-tight">Cartoon Violence</span>
											<span className="text-white/70 leading-tight">Mature Themes</span>
											<span className="text-white/70 leading-tight">Some Rating Item</span>
											<span className="text-white/70 leading-tight">Another Rating Item</span>
										</div>
									</div>
								</motion.div>
							)}
						</AnimatePresence>

						{/* reader topbar */}
						<motion.div
							initial={{ y: 0 }}
							animate={{ y: controlsHidden ? "-100%" : 0 }}
							transition={{ duration: 0.25, ease: "easeInOut" }}
							className="z-[10] fixed top-0 left-0 right-0 flex flex-row items-center justify-between w-full h-[90px] px-6 gap-4 pointer-events-none"
						>
							{/* close reader button */}
							<button
								onClick={handleCloseReader}
								type="button"
								className="inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none ring-0 border-none bg-white/10 hover:bg-white/20 backdrop-blur text-white pointer-events-auto"
							>
								<IconX size={28} stroke={2} />
							</button>
							{/* reader dropdown menu */}
							<DropdownMenu modal={false}>
								<DropdownMenuTrigger asChild>
									<button
										type="button"
										className="inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none ring-0 border-none bg-white/10 hover:bg-white/20 backdrop-blur text-white pointer-events-auto"
									>
										<IconDotsHor size={18} />
									</button>
								</DropdownMenuTrigger>
								<DropdownMenuContent sideOffset={8} alignOffset={0}>
									<ReaderDropdownItems />
								</DropdownMenuContent>
							</DropdownMenu>
						</motion.div>

						{/* reader bottombar */}
						<motion.div
							initial={{ y: 0 }}
							animate={{ y: showReaderType ? 0 : "100%" }}
							transition={{ duration: 0.25, ease: "easeInOut" }}
							className="z-[10] fixed bottom-0 left-0 right-0 flex flex-row justify-center w-full h-[90px] px-6 gap-4 pointer-events-none"
						>
							{/* reader types toggle */}
							<div className="inline-flex items-center gap-8">
								{readerTypes.map(({ type, icon, label }) => (
									<button
										key={type}
										onClick={() => handleReaderType(type)}
										type="button"
										className={clsx(
											"flex flex-col items-center gap-2 text-white hover:opacity-100 pointer-events-auto",
											activeReaderType === type ? "opacity-100" : "opacity-50",
										)}
									>
										{icon}
										<span className="text-[10px] uppercase tracking-widest">{label}</span>
									</button>
								))}
							</div>
						</motion.div>

						{/* maximize/minimize control */}
						<div className="z-[10] fixed bottom-6 right-6 inline-flex flex-row justify-end items-end">
							<button
								onClick={() => setControlsHidden(!controlsHidden)}
								type="button"
								className="inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none ring-0 border-none bg-white/10 hover:bg-white/20 backdrop-blur text-white"
							>
								{controlsHidden ? <IconMinimize size={18} /> : <IconMaximize size={18} />}
							</button>
						</div>

						{/* main reader display */}
						<motion.div
							className="relative flex flex-col items-center gap-8 w-full bg-black"
							animate={{
								height: controlsHidden ? "100vh" : "calc(100vh - 180px)",
							}}
							transition={{ duration: 0.3, ease: "easeInOut" }}
						>
							<div className="relative flex flex-col justify-center items-center h-full w-screen overflow-hidden">
								{showReaderPreview && renderPreview()}
								{renderContent()}
							</div>
						</motion.div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
};

export default Reader;
