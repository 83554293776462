import { settingsTabTrigger } from "@/components/constants/tabs.ts";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/core/navigations/tabs.tsx";
import SettingsAccounts from "@/components/features/settings/settings-accounts.tsx";
import { IconNotifications, IconSecurity, IconTicket } from "@/components/mvicons";
import IconAccounts from "@/components/mvicons/icon-accounts.tsx";

export default function SettingsContent() {
	return (
		<div className="flex flex-col w-full">
			<Tabs defaultValue="accounts">
				<TabsList className="!py-4">
					{/*<TabsTrigger className={settingsTabTrigger} value="profile">
						<IconUser size={18} />
						<span className="inline-flex">Profile</span>
					</TabsTrigger>*/}
					<TabsTrigger className={settingsTabTrigger} value="accounts">
						<IconAccounts size={24} />
						<span className="hidden sm:inline-flex">Accounts</span>
					</TabsTrigger>
					<TabsTrigger className={settingsTabTrigger} value="notifications">
						<IconNotifications size={18} />
						<span className="hidden sm:inline-flex">Notifications</span>
					</TabsTrigger>
					<TabsTrigger className={settingsTabTrigger} value="security">
						<IconSecurity size={18} />
						<span className="hidden sm:inline-flex">Security</span>
					</TabsTrigger>
					{/*<TabsTrigger className={settingsTabTrigger} value="subscriptions">*/}
					{/*	<IconTicket size={22} />*/}
					{/*	<span className="hidden sm:inline-flex">Subscriptions</span>*/}
					{/*</TabsTrigger>*/}
				</TabsList>
				{/*<TabsContent value="profile">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-row items-center gap-2">
							<span className="text-sm font-semibold text-white">
								Your Profile
							</span>
							<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
								Coming Soon
							</span>
						</div>
						<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
							<div className="inline-flex text-white">
								<IconUser />
							</div>
							<div className="flex flex-col w-full max-w-[280px] gap-2">
								<span className="text-sm font-semibold text-white leading-tight">
									Your latest and greatest content.
								</span>
								<span className="text-sm text-white/70 leading-tight">
									Series you’re reading are automatically waiting for you here.
									Add them to your list and queue them up here as well.
								</span>
							</div>
						</div>
					</div>
				</TabsContent>*/}
				<TabsContent value="accounts">
					<SettingsAccounts />
				</TabsContent>
				<TabsContent value="notifications">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-row items-center gap-2">
							<span className="text-sm font-semibold text-white">Your Notifications</span>
							<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
								Coming Soon
							</span>
						</div>
						<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
							<div className="inline-flex text-white">
								<IconNotifications />
							</div>
							<div className="flex flex-col w-full max-w-[280px] gap-2">
								<span className="text-sm font-semibold text-white leading-tight">
									Your latest and greatest content.
								</span>
								<span className="text-sm text-white/70 leading-tight">
									Series you’re reading are automatically waiting for you here. Add them to your list and queue them up
									here as well.
								</span>
							</div>
						</div>
					</div>
				</TabsContent>
				<TabsContent value="security">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-row items-center gap-2">
							<span className="text-sm font-semibold text-white">Your Security</span>
							<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
								Coming Soon
							</span>
						</div>
						<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
							<div className="inline-flex text-white">
								<IconSecurity />
							</div>
							<div className="flex flex-col w-full max-w-[280px] gap-2">
								<span className="text-sm font-semibold text-white leading-tight">
									Your latest and greatest content.
								</span>
								<span className="text-sm text-white/70 leading-tight">
									Series you’re reading are automatically waiting for you here. Add them to your list and queue them up
									here as well.
								</span>
							</div>
						</div>
					</div>
				</TabsContent>
				<TabsContent value="subscriptions">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-row items-center gap-2">
							<span className="text-sm font-semibold text-white">Your Subscriptions</span>
							<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
								Coming Soon
							</span>
						</div>
						<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
							<div className="inline-flex text-white">
								<IconTicket />
							</div>
							<div className="flex flex-col w-full max-w-[280px] gap-2">
								<span className="text-sm font-semibold text-white leading-tight">
									Your latest and greatest content.
								</span>
								<span className="text-sm text-white/70 leading-tight">
									Series you’re reading are automatically waiting for you here. Add them to your list and queue them up
									here as well.
								</span>
							</div>
						</div>
					</div>
				</TabsContent>
			</Tabs>
		</div>
	);
}
