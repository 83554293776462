import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconQrcode = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path d="M22.9688 0V2.34375H27.6562V7.03125H30V0H22.9688Z" fill={color} />
			<path d="M2.34375 2.34375H7.03125V0H0V7.03125H2.34375V2.34375Z" fill={color} />
			<path d="M2.34375 22.9688H0V30H7.03125V27.6562H2.34375V22.9688Z" fill={color} />
			<path d="M27.6562 27.6562H22.9688V30H30V22.9688H27.6562V27.6562Z" fill={color} />
			<path
				d="M4.45312 13.8281H13.8281V4.45312H4.45312V13.8281ZM6.79688 6.79688H11.4844V11.4844H6.79688V6.79688Z"
				fill={color}
			/>
			<path
				d="M25.5469 4.45312H16.1719V13.8281H25.5469V4.45312ZM23.2031 11.4844H18.5156V6.79688H23.2031V11.4844Z"
				fill={color}
			/>
			<path
				d="M4.45312 25.5469H13.8281V16.1719H4.45312V25.5469ZM6.79688 18.5156H11.4844V23.2031H6.79688V18.5156Z"
				fill={color}
			/>
			<path d="M23.2031 16.1719H25.5469V18.5156H23.2031V16.1719Z" fill={color} />
			<path d="M20.8594 18.5156H23.2031V20.8594H20.8594V18.5156Z" fill={color} />
			<path d="M23.2031 20.8594H25.5469V23.2031H23.2031V20.8594Z" fill={color} />
			<path d="M23.2031 23.2031H18.5156V18.5156H20.8594V16.1719H16.1719V25.5469H23.2031V23.2031Z" fill={color} />
		</svg>
	</div>
);

export default IconQrcode;
