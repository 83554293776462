import { useAuth0 } from "@auth0/auth0-react";
import { IconLoader3 } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/login")({
	component: RouteComponent,
});

function RouteComponent() {
	const { loginWithRedirect } = useAuth0();

	useEffect(() => {
		loginWithRedirect();
	}, []);

	return (
		<div className="flex flex-col justify-center items-center w-screen h-screen">
			<div className="inline-flex flex-row items-center text-white gap-2">
				<IconLoader3 className="animate-spin" size={24} />
				<span className="text-lg">Redirecting...</span>
			</div>
		</div>
	);
}
