import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconNotifications = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M14.9351 30C17.3049 30 19.2337 28.0772 19.244 25.7096H10.6264C10.6367 28.0769 12.5652 30 14.9351 30Z"
				fill={color}
			/>
			<path
				d="M26.5877 23.9507V22.671C26.5877 21.2819 25.7518 20.0466 24.5204 19.5035V12.2285C24.5204 10.1777 23.8704 8.25783 22.6406 6.67648C21.5937 5.33043 20.1774 4.2865 18.5178 3.6319V3.58269C18.5178 1.60721 16.9106 0 14.9351 0C12.9596 0 11.3526 1.60721 11.3526 3.58269V3.6319C9.69301 4.2865 8.27669 5.33043 7.22978 6.67648C6 8.25783 5.34975 10.1777 5.34975 12.2285V19.5033C4.11859 20.0464 3.28271 21.2819 3.28271 22.671V23.9504L26.5877 23.9507Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconNotifications;
