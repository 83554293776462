import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import validator from "validator";

export default function ExternalLinkModal({ link }: { link: string }) {
	const { close } = useModalControl();

	const sanitizeLink = (url: string) => {
		let sanitizedUrl = url;

		if (!validator.isURL(sanitizedUrl, { require_protocol: true })) {
			sanitizedUrl = `https://${sanitizedUrl}`;
		}

		// Validate the URL
		if (
			validator.isURL(sanitizedUrl, {
				protocols: ["http", "https"],
				require_protocol: true,
			})
		) {
			return sanitizedUrl;
		}
		return null;
	};

	const sanitizedLink = sanitizeLink(link);

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col w-full max-w-[380px] gap-8">
				<div className="flex flex-col justify-center items-center w-full gap-6">
					<div className="flex flex-col justify-center items-center w-full">
						<div className="inline-flex">
							<img src="/svg/macroverse-logo.svg" alt="Macroverse" />
						</div>
						<span className="font-quagmire font-bold text-lg text-white leading-8">You're leaving Macroverse</span>
					</div>

					<div className="flex flex-col justify-center items-center w-full gap-4">
						<span className="text-center text-sm text-white/50 leading-tight">
							The link you clicked will take you outside of Macroverse and has not been verified by Macronauts. Are you
							sure you want to continue?
						</span>
						<div className="flex flex-col w-full py-3 px-4 bg-white bg-opacity-5 rounded-xl">
							{sanitizedLink ? (
								<a
									href={sanitizedLink}
									target="_blank"
									rel="noopener noreferrer"
									className="font-semibold text-center break-words text-mvblue-300 leading-tight"
								>
									{sanitizedLink}
								</a>
							) : (
								<span className="font-semibold text-center text-red-500">Invalid link</span>
							)}
						</div>
					</div>

					<Button
						onClick={() => {
							close();
						}}
						className="mt-2 font-quagmire bg-mvdark-950 uppercase w-full"
						type="button"
						variant="gradient"
						size="md"
					>
						<ButtonLabel>Back to Macroverse</ButtonLabel>
					</Button>
				</div>
			</div>
		</div>
	);
}
