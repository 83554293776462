import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconMinimize = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.5 11.5V0H7.5V7.5H0V11.5H11.5ZM30 11.5V7.5H22.5V0H18.5V11.5H30ZM18.5 30H22.5V22.5H30V18.5H18.5V30ZM0 18.5V22.5H7.5V30H11.5V18.5H0Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconMinimize;
