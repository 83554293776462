import { activitiesQueryOptions } from "@/api/queries/activity-queries.ts";
import { rewardQueryOptions, useRewardClaimMutation } from "@/api/queries/reward-queries";
import { parseTemplate } from "@/components/activities/activity-item.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { Avatar, AvatarImage } from "@/components/core/display/avatar.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/core/feedbacks/popover.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { ExpContext } from "@/components/exp-gain/exp-gain-context.tsx";
import { IconExp, IconUser } from "@/components/mvicons";
import { TopbarExpTimer } from "@/components/topbar-exp-timer.tsx";
import { queryClient } from "@/query-client.ts";
import type { User } from "@/types/user.ts";
import { formatNumber } from "@/utils/format-number.ts";
import { humanReadableDateTime } from "@/utils/human-readable-datetime.ts";
import { shortenAddress } from "@/utils/shorten-address.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { IconArrowRight } from "@tabler/icons-react";
import { useIsMutating, useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { differenceInSeconds } from "date-fns";
import { useContext, useState } from "react";
import AnimatedNumbers from "react-animated-numbers";
import UserMenuSkeleton from "./skeletons/user-menu-skeleton";

const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

export default function PortalTopbar({ profile }: { profile: User }) {
	const [open, setOpen] = useState(false);
	const { logout } = useAuth0();
	const navigate = useNavigate({
		from: "/",
	});
	const modal = useModal();
	const { triggerExpAnim, triggerAnimation } = useContext(ExpContext);
	const rewardMutation = useRewardClaimMutation();
	const rewardQuery = useQuery(rewardQueryOptions());
	const rewardStatus = rewardQuery.data;
	const activityQuery = useQuery(
		activitiesQueryOptions({
			per_page: 5,
		}),
	);
	const activities = activityQuery.data;
	const isLoading = useIsMutating();

	const handleLogout = async () => {
		await logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});

		await queryClient.invalidateQueries();

		await navigate({
			to: "/",
		});
	};

	const nextClaimInSeconds = rewardStatus ? differenceInSeconds(rewardStatus?.next_claim_at, new Date()) : 0;

	return (
		<div
			className={clsx(
				"!z-20 fixed left-0 right-0 flex flex-row items-center justify-between w-full gap-4 p-4 md:p-6 bg-gradient-to-b from-black/90 to-transparent",
				isProduction ? "top-0" : "top-8",
			)}
		>
			<Link to="/overview" className="inline-flex h-[44px]">
				<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
			</Link>
			<div className="inline-flex flex-row items-center w-auto gap-4">
				<div className="inline-flex flex-row items-center w-auto gap-1 text-white">
					<IconExp size={24} />
					{triggerExpAnim ? (
						<AnimatedNumbers
							className="text-sm text-white"
							includeComma
							transitions={(index) => ({
								type: "spring",
								duration: index + 0.5,
							})}
							animateToNumber={profile.total_exp || 0}
						/>
					) : (
						<span className="text-sm">{formatNumber(profile.total_exp, "0,0")}</span>
					)}
				</div>
				{profile?.aptos_address && isProduction && (
					<div className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/40 font-bold text-sm text-white">
						{shortenAddress(profile?.aptos_address)}
					</div>
				)}
				<Popover open={open} onOpenChange={setOpen}>
					<PopoverTrigger
						className="!outline-none"
						onMouseEnter={() => setOpen(true)}
						onMouseLeave={() => setOpen(true)}
					>
						<Avatar className="w-[48px] h-[48px] rounded-full border-2 border-white bg-mvclaimcard-gradient-a pointer-events-auto">
							<AvatarImage src={profile.avatar_url} />
							{!profile.avatar_url && <AvatarImage src="/macronaut-avatar.png" />}
						</Avatar>
					</PopoverTrigger>
					<PopoverContent
						className="min-w-[320px]"
						onMouseEnter={() => setOpen(true)}
						onMouseLeave={() => setOpen(false)}
						side="bottom"
						align="center"
						sideOffset={-30}
						allowClose={false}
						enableArrow={false}
					>
						{isLoading ? (
							<UserMenuSkeleton />
						) : (
							<div className="flex flex-col w-full max-w-[320px] sm:max-w-[420px] gap-2">
								<div className="flex flex-row items-center justify-center w-full gap-2">
									<Avatar className="w-[48px] h-[48px] rounded-full border-2 border-white bg-mvclaimcard-gradient-a pointer-events-auto">
										<AvatarImage src={profile.avatar_url} />
										{!profile.avatar_url && <AvatarImage src="/macronaut-avatar.png" />}
									</Avatar>
									<Button
										onClick={() => {
											modal.open({
												type: "confirm",
												variant: "warning",
												title: "Are you sure you want to logout?",
												description:
													"It looks like you're about to log out. We'll miss you! If you're all set, you can safely end your session now.",
												onConfirm: handleLogout,
											});
										}}
										className="!outline-none self-center font-quagmire bg-mvdark-950 backdrop-blur uppercase pointer-events-auto"
										type="button"
										variant="gradient"
										size="md"
									>
										<ButtonLabel>Logout</ButtonLabel>
									</Button>
								</div>

								{!rewardQuery.isFetching && rewardStatus && (
									<TopbarExpTimer
										onClaim={async () => {
											triggerAnimation();

											await rewardMutation.mutateAsync();
										}}
										duration={nextClaimInSeconds}
									/>
								)}

								<div className="flex flex-col w-full py-2 px-4 rounded-xl divide-y divide-white/20 bg-white bg-opacity-5">
									<Link
										to="/about"
										params={{
											username: (profile.username || profile.macronaut_id) as string,
										}}
									>
										<div className="flex flex-row items-center gap-2 py-1 text-white/70 hover:text-white">
											<IconUser size={16} />
											<span className="translate-y-[-1px] leading-1">Profile</span>
											<IconArrowRight className="ml-auto" size={18} stroke={2} />
										</div>
									</Link>
									{/* This will be added later on don't remove this comment */}
									{/*<Link to="/profile">
  									<div className="flex flex-row items-center gap-2 py-1 text-white/70 hover:text-white">
  										<IconExp size={18} />
  										<span className="translate-y-[-1px] leading-1">
  											Your Quests
  										</span>
  									</div>
  								</Link>*/}
								</div>

								{activities?.data.length !== 0 && (
									<div className="flex flex-col w-full mt-2 gap-2">
										<div className="flex flex-row justify-center w-full">
											<span className="font-semibold text-white">Latest Updates</span>
										</div>
										<div className="flex flex-col w-full rounded-xl divide-y divide-white/10 py-3 px-6 bg-white bg-opacity-5">
											{activities?.data.map((activity) => {
												const { template, variables } = activity.metadata;

												return (
													<div key={activity.id} className="flex flex-row items-center w-full py-3 gap-3">
														<Avatar className="self-start shrink-0 w-[36px] h-[36px] rounded-full border-2 border-white bg-mvlogo-gradient">
															<AvatarImage src={profile.avatar_url} />
															{!profile.avatar_url && <AvatarImage src="/macronaut-avatar.png" />}
														</Avatar>
														<div className="flex flex-col items-start w-full shrink grow">
															<p className="text-xs text-mvblue-300 leading-tight">
																{parseTemplate(template, variables)}
															</p>
															<span className="text-xs text-white/30  leading-tight">
																{humanReadableDateTime(activity.created_at)}
															</span>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								)}

								{/*<div className="flex flex-col w-full mt-2 gap-2">
									<div className="flex flex-row justify-center w-full">
										<span className="font-semibold text-white">New For You</span>
									</div>
									<div className="relative flex flex-col w-full p-4 gap-3 rounded-xl bg-white bg-opacity-5">
										<span className="absolute top-2 right-3 ml-auto text-xs text-white/30">18m ago</span>
										<div className="flex flex-row items-center w-full gap-3">
											<div className="relative inline-flex h-[50px] mt-1 shrink-0">
												<div className="absolute -bottom-[8px] -right-[4px] inline-flex justify-center items-center w-[24px] h-[24px] rounded-md bg-mvdark-950 border border-white/20 text-white">
													<IconComic size={14} />
												</div>
												<img className="block h-full w-auto" src="/sample-comic-solo.png" alt="Macroverse" />
											</div>
											<div className="flex flex-col w-full">
												<span className="font-semibold text-white leading-tight">New Issue</span>
												<span className="text-white/70 leading-tight">Chamber of chills #24 is now available...</span>
											</div>
										</div>
									</div>
								</div>*/}
							</div>
						)}
					</PopoverContent>
				</Popover>
			</div>
		</div>
	);
}
