import clsx from "clsx";
import { createElement, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import "trix";
import "trix/dist/trix.css";

type ReactTrixEditorProps = {
	height: number;
	size?: string;
	placeholder?: string;
	value?: string;
	onChange?: (value: string) => void;
	onAttachment?: (
		attachment: Array<{
			file: File;
			uuid: string;
			id: number;
			name: string;
		}>,
	) => void;
	onInit?: () => void;
	onKeyDown?: (e: KeyboardEvent) => void;
	onMention?: (value: string) => Promise<any>;
};

type TrixEditorProps = HTMLElement & {
	editor: any;
};

export default function ReactTrixEditor({
	height = 400,
	size = "md",
	placeholder,
	onChange,
	value,
	onAttachment,
	onInit,
	onKeyDown,
	onMention,
}: ReactTrixEditorProps) {
	const trixEditorRef = useRef();
	const elementId = v4();
	const editorId = `editor-${elementId}`;
	const [attachments, setAttachments] = useState<
		Array<{
			file: File;
			uuid: string;
			id: number;
			name: string;
		}>
	>([]);

	const handleInitialize = () => {
		const el = document.getElementById(editorId) as TrixEditorProps;

		if (el) {
			onInit?.();

			if (onMention) {
				// const tribute = new Tribute({
				//  containerClass: "trix-mentions",
				//  itemClass: "trix-mention-item",
				//  selectClass: "trix-mention-active",
				//  menuItemTemplate: (item: {
				//    original: Staff;
				//  }) => {
				//    return `<img src="${item.original.avatar_thumbnail}">${item.original.display_name}`;
				//  },
				//  values: async (value: string, callback) => {
				//    const response = await onMention(value);
				//
				//    callback(response);
				//  },
				//  lookup: "display_name",
				//  fillAttr: "display_name",
				// });
				//
				// tribute.attach(el);
				//
				// el.addEventListener("tribute-replaced", (e: any) => {
				//  if (e) {
				//    const item = e.detail.item.original;
				//    const length = el.editor.getDocument().toString().length;
				//    const match_size = (e.detail.item.string.match(/<span>/g) || [])
				//      .length;
				//
				//    el.editor.setSelectedRange([length - (match_size + 2), length]);
				//    el.editor.deleteInDirection("backward");
				//
				//    // @ts-ignore
				//    const attachment = new window.Trix.Attachment({
				//      content: `<user-mention data-staff-id="${item.id}" class="mention"><img src="${item.avatar_url}">${item.display_name}</user-mention>`,
				//    });
				//
				//    el.editor.insertAttachment(attachment);
				//    el.editor.insertString(" ");
				//  }
				// });

				el.addEventListener("keydown", (e) => {
					if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
						e.preventDefault();
						const event = new KeyboardEvent("keydown", {
							key: "Enter",
							ctrlKey: true,
							metaKey: true,
						});

						onKeyDown?.(event);
					}
				});
			}
		}
	};

	const handleChange = (e: any) => {
		onChange?.(e.target.innerHTML);
	};

	// const handleAttachmentAdd = async (e: any) => {
	//  if (e.attachment.file) {
	//    const attachment = e.attachment;
	//
	//    const fileObject = {
	//      file: e.attachment.file,
	//      uuid: v4(),
	//      id: e.attachment.id,
	//      name: e.attachment.name,
	//    };
	//
	//    const attributes = await mediaService.upload(fileObject, (progress) => {
	//      attachment.setUploadProgress(progress);
	//    });
	//
	//    attachment.setAttributes(attributes);
	//
	//    setAttachments((prevAttachments) => {
	//      const updatedAttachments = [...prevAttachments, fileObject];
	//
	//      if (onAttachment) {
	//        onAttachment(updatedAttachments);
	//      }
	//      return updatedAttachments;
	//    });
	//  }
	// };

	const handleAttachmentRemove = (e: any) => {
		if (e.attachment) {
			const attachment = e.attachment;
			const attachmentId = attachment.id;

			setAttachments((prevAttachments) => {
				const updatedAttachments = prevAttachments.filter((att) => att.id !== attachmentId);

				if (onAttachment) {
					onAttachment(updatedAttachments);
				}
				return updatedAttachments;
			});
		}
	};

	useEffect(() => {
		if (!value) {
			const el = document.getElementById(editorId) as TrixEditorProps;

			if (el) {
				el.editor.loadDocument();
			}
		}

		document.addEventListener("trix-initialize", handleInitialize);
		document.addEventListener("trix-change", handleChange);
		// document.addEventListener("trix-attachment-add", handleAttachmentAdd);
		document.addEventListener("trix-attachment-remove", handleAttachmentRemove);

		return () => {
			document.removeEventListener("trix-change", handleChange);
			// document.removeEventListener("trix-initialize", handleInitialize);
			document.removeEventListener("tribute-replace", () => {});
			// document.removeEventListener("trix-attachment-add", handleAttachmentAdd);
			// document.removeEventListener(
			//  "trix-attachment-remove",
			//  handleAttachmentRemove,
			// );
		};
	}, [value]);

	return (
		<div className="relative">
			<div className="trix-editor-wrapper">
				<input type="hidden" defaultValue={value || ""} id={`input-${elementId}`} />
				{createElement("trix-editor", {
					id: editorId,
					ref: trixEditorRef,
					class: clsx("font-aller trix-content font-normal placeholder-white/40 text-white", {
						"text-sm": size === "sm",
						"text-md": size === "md",
						"text-md sm:text-lg": size === "lg",
					}),
					placeholder: placeholder,
					style: {
						minHeight: `${height}px`,
					},
					input: `input-${elementId}`,
				})}
			</div>
		</div>
	);
}
