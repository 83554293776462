import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconUpdate = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M8.73956 30C6.15478 29.977 4.13611 29.4197 5.37396 27.044C6.6118 24.6682 9.27761 24.0844 11.7268 24.1114C14.3116 24.1624 16.301 24.7975 15.0346 27.2003V27.1495C13.8518 29.4202 11.4343 30 8.9168 30H8.73956ZM9.01239 21.6301L11.916 14.0475L5.29828 13.942L10.3606 0L21.8813 0.21296L17.7888 7.73689L25 7.87366L16.5501 21.761L9.01239 21.6301Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconUpdate;
