import clsx from "clsx";

type IconProps = {
	color?: string;
	title?: string;
	className?: string;
};

const ScrollArrowUp = ({ color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div className={clsx("inline-flex shrink-0 justify-center items-center", className)}>
		<svg
			width="69"
			height="156"
			viewBox="0 0 69 156"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.6213 8.98533L64.5 38.864L68.7426 34.6214L34.6213 0.500052L0.5 34.6214L4.74265 38.864L34.6213 8.98533Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.6213 5.74268L36.6213 155.743L32.6213 155.743L32.6213 5.74268L36.6213 5.74268Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default ScrollArrowUp;
