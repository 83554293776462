import ErrorWorldsSwiper from "@/components/swipers/error-worlds-swiper.tsx";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import { Link } from "@tanstack/react-router";

const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

export function NotFound() {
	return (
		<div className="font-aller relative flex w-full min-h-[100svh] bg-mvdark-950 overflow-x-hidden">
			<div className="z-[3] flex flex-col w-full max-w-[960px] gap-8 sm:gap-12 py-12 sm:py-24 px-12 mx-auto">
				<div className="flex flex-col w-auto">
					<span className="font-quagmire text-[28px] leading-[28px] font-bold uppercase italic text-mvred-600">
						Error
					</span>
					<div className="flex flex-col w-auto mt-2 gap-1">
						<span className="custom-shadow custom-shadow--404A font-quagmire text-[44px] md:text-[56px] leading-[44px] md:leading-[56px] font-bold uppercase italic text-mvred-600">
							404
						</span>
						<span className="custom-shadow custom-shadow--404B font-quagmire text-[44px] md:text-[56px] leading-[44px] md:leading-[56px] font-bold uppercase italic text-mvblue-300">
							Lost in the Macroverse!
						</span>
					</div>
				</div>
				<div className="flex flex-col w-auto gap-4">
					<div className="flex flex-row items-center gap-2">
						<IconAlertTriangleFilled className="text-yellow-400" size={28} />
						<span className="text-lg font-semibold text-white">Uh-oh, Macronaut!</span>
					</div>
					<p className="text-md text-white/70">
						Looks like you’ve wandered into an uncharted corner of the Macroverse. But fear not—every explorer gets
						turned around sometimes!
					</p>
					<p className="text-md text-white/70">
						Take a moment to recalibrate, and let us help guide you back to familiar coordinates... OR why not explore a
						new adventure while you’re here?
					</p>
				</div>

				{!isProduction && (
					<>
						<hr className="border-b border-mvblue-300/50" />
						<div className="flex flex-col w-full gap-6">
							<p className="text-lg font-semibold text-mvblue-300 leading-tight">
								Jump to one of these Worlds we think you’ll love:
							</p>
							<ErrorWorldsSwiper />
						</div>
					</>
				)}

				<div className="flex flex-col w-auto gap-4">
					<p className="font-semibold text-md text-white">
						Keep exploring, and remember, every great journey has a few detours!
					</p>
					<p className="text-md text-white/70">
						<Link to="/overview" className="text-mvblue-300 underline">
							Jump to Home Base
						</Link>{" "}
						or{" "}
						<a href="/" className="text-mvblue-300 underline">
							Explore more Worlds
						</a>{" "}
						to keep adventuring!
					</p>
				</div>
			</div>
			<div className="z-[2] absolute error-page-gradient inset-0 blur-[20px] overflow-hidden" />
		</div>
	);
}
