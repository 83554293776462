import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

export default function ErrorWorldsSwiper() {
	return (
		<Swiper
			autoHeight={true}
			slidesPerView={1}
			spaceBetween={20}
			pagination={{
				enabled: false,
				clickable: true,
			}}
			breakpoints={{
				768: {
					spaceBetween: 20,
					slidesPerView: 2,
				},
			}}
			modules={[Pagination]}
			className="overflow-visible w-full error-worlds-swiper"
		>
			<SwiperSlide>
				<div className="relative flex flex-row w-full pl-[40px] lg:pl-[80px]">
					<div className="absolute top-3 sm:top-0 -left-2 bottom-0 inline-flex w-[100px] lg:w-auto h-[100px] lg:h-auto">
						<img src="/worlds-sample-1.png" alt="Macroverse Worlds" />
					</div>
					<div className="flex flex-col w-full pl-[40px] lg:pl-[90px] rounded-xl bg-white bg-opacity-5">
						<div className="flex flex-col w-full p-4 gap-3">
							<div className="inline-flex flex-col w-[80px] lg:w-[100px] gap-1">
								<img src="/svg/the-world-of.svg" alt="Macroverse Worlds" />
								<img src="/svg/series-logo.svg" alt="Macroverse Worlds" />
							</div>
							<div className="flex flex-row w-auto gap-1">
								<div className="inline-flex items-center justify-center h-[24px] px-2 rounded-full bg-black">
									<span className="text-xs text-white uppercase">Genre Tag</span>
								</div>
								<div className="inline-flex items-center justify-center h-[24px] px-2 rounded-full bg-black">
									<span className="text-xs text-white uppercase">Genre Tag</span>
								</div>
							</div>
							<div className="inline-flex flex-col w-full">
								<span className="text-white leading-tight">Sub Title via Admin</span>
								<p className="text-white/70 leading-tight">Two line Synopsis added via Admin Backend.</p>
							</div>
						</div>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="relative flex flex-row w-full pl-[40px] lg:pl-[80px]">
					<div className="absolute top-3 sm:top-0 -left-2 bottom-0 inline-flex w-[100px] lg:w-auto h-[100px] lg:h-auto">
						<img src="/worlds-sample-2.png" alt="Macroverse Worlds" />
					</div>
					<div className="flex flex-col w-full pl-[40px] lg:pl-[90px] rounded-xl bg-white bg-opacity-5">
						<div className="flex flex-col w-full p-4 gap-3">
							<div className="inline-flex flex-col w-[80px] lg:w-[100px] gap-1">
								<img src="/svg/the-world-of.svg" alt="Macroverse Worlds" />
								<img src="/svg/series-logo.svg" alt="Macroverse Worlds" />
							</div>
							<div className="flex flex-row w-auto gap-1">
								<div className="inline-flex items-center justify-center h-[24px] px-2 rounded-full bg-black">
									<span className="text-xs text-white uppercase">Genre Tag</span>
								</div>
								<div className="inline-flex items-center justify-center h-[24px] px-2 rounded-full bg-black">
									<span className="text-xs text-white uppercase">Genre Tag</span>
								</div>
							</div>
							<div className="inline-flex flex-col w-full">
								<span className="text-white leading-tight">Sub Title via Admin</span>
								<p className="text-white/70 leading-tight">Two line Synopsis added via Admin Backend.</p>
							</div>
						</div>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="relative flex flex-row w-full pl-[40px] lg:pl-[80px]">
					<div className="absolute top-3 sm:top-0 -left-2 bottom-0 inline-flex w-[100px] lg:w-auto h-[100px] lg:h-auto">
						<img src="/worlds-sample-3.png" alt="Macroverse Worlds" />
					</div>
					<div className="flex flex-col w-full pl-[40px] lg:pl-[90px] rounded-xl bg-white bg-opacity-5">
						<div className="flex flex-col w-full p-4 gap-3">
							<div className="inline-flex flex-col w-[80px] lg:w-[100px] gap-1">
								<img src="/svg/the-world-of.svg" alt="Macroverse Worlds" />
								<img src="/svg/series-logo.svg" alt="Macroverse Worlds" />
							</div>
							<div className="flex flex-row w-auto gap-1">
								<div className="inline-flex items-center justify-center h-[24px] px-2 rounded-full bg-black">
									<span className="text-xs text-white uppercase">Genre Tag</span>
								</div>
								<div className="inline-flex items-center justify-center h-[24px] px-2 rounded-full bg-black">
									<span className="text-xs text-white uppercase">Genre Tag</span>
								</div>
							</div>
							<div className="inline-flex flex-col w-full">
								<span className="text-white leading-tight">Sub Title via Admin</span>
								<p className="text-white/70 leading-tight">Two line Synopsis added via Admin Backend.</p>
							</div>
						</div>
					</div>
				</div>
			</SwiperSlide>
		</Swiper>
	);
}
