import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconEdit = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M0 23.7514V30.0044H6.249L24.68 11.5694L18.431 5.32041L0 23.7514ZM29.513 6.73741C29.6675 6.58322 29.7901 6.40007 29.8737 6.19845C29.9574 5.99683 30.0004 5.78069 30.0004 5.56241C30.0004 5.34413 29.9574 5.12799 29.8737 4.92637C29.7901 4.72474 29.6675 4.5416 29.513 4.38741L25.613 0.487407C25.4588 0.332901 25.2757 0.210322 25.074 0.126686C24.8724 0.0430503 24.6563 0 24.438 0C24.2197 0 24.0036 0.0430503 23.802 0.126686C23.6003 0.210322 23.4172 0.332901 23.263 0.487407L20.213 3.53741L26.462 9.78641L29.512 6.73641L29.513 6.73741Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconEdit;
