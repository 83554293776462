import clsx from "clsx";
import * as React from "react";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	inputStyle?: string;
	prepend?: string;
	indicator?: boolean | React.ReactNode;
	icon?: boolean | React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, inputStyle, prepend, indicator, icon, ...props }, ref) => {
		return (
			<div className="relative flex flex-row items-center w-full">
				{icon && (
					<div className="absolute left-0 top-1/2 -translate-y-1/2 inline-flex justify-center items-center w-6 h-6  text-white/40 text-md sm:text-lg">
						{icon}
					</div>
				)}
				{prepend && (
					<div
						className={clsx(
							"absolute top-1/2 -translate-y-1/2 inline-flex justify-center items-center w-6 h-6  text-white/40 text-md sm:text-lg",
							{
								"left-0": !icon,
								"left-7": icon,
							},
						)}
					>
						{prepend}
					</div>
				)}
				<input
					type={type}
					ref={ref}
					spellCheck={false}
					className={clsx(
						prepend && "pl-6",
						icon && "pl-8",
						prepend && icon && "pl-[54px]",
						props.disabled && "opacity-30 cursor-not-allowed",
						className,
					)}
					{...props}
				/>
				{indicator && (
					<div className="absolute right-0 top-1/2 -translate-y-1/2 text-white/40 text-md sm:text-lg inline-flex justify-center items-center">
						{indicator}
					</div>
				)}
			</div>
		);
	},
);
Input.displayName = "Input";

export { Input };
