import clsx from "clsx";

type IconProps = {
	color?: string;
	title?: string;
	className?: string;
};

const TapArrowRight = ({ color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div className={clsx("inline-flex shrink-0 justify-center items-center", className)}>
		<svg
			width="39"
			height="69"
			viewBox="0 0 39 69"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.8787 34.1214L7.41807e-07 64L4.24264 68.2427L38.364 34.1214L4.24265 3.12594e-05L1.11901e-05 4.24268L29.8787 34.1214Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default TapArrowRight;
