import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconWallet = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 49 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<g id="Icons/MV">
				<path
					id="Icon_Wallet"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.5 4.41235C0.5 5.9627 1.76206 7.22469 3.3125 7.22469H40.0625V3.19992C40.0625 2.31631 39.3462 1.60001 38.4625 1.60001H3.3125C1.76206 1.60001 0.5 2.862 0.5 4.41235ZM35.8438 40.3416C30.4165 40.3416 24.4 35.9293 24.4 28.8985C24.4 21.8676 30.4165 17.4554 35.8438 17.4554H45.6875V13.2369C45.6875 12.3532 44.97 11.6369 44.0864 11.6369H3.3125C2.28341 11.6369 1.33062 11.3391 0.5 10.8547V39.1292C0.5 43.0058 3.65441 46.16 7.53125 46.16H44.0875C44.9712 46.16 45.6875 45.4437 45.6875 44.5601V40.3416H35.8438ZM35.8438 21.8676C31.9669 21.8676 28.8125 25.0218 28.8125 28.8985C28.8125 32.7751 31.9669 35.9293 35.8438 35.9293H46.9C47.7837 35.9293 48.5 35.213 48.5 34.3294V23.4675C48.5 22.5839 47.7837 21.8676 46.9 21.8676H35.8438ZM35.7 31.9983C37.4673 31.9983 38.9 30.5657 38.9 28.7985C38.9 27.0312 37.4673 25.5986 35.7 25.5986C33.9327 25.5986 32.5 27.0312 32.5 28.7985C32.5 30.5657 33.9327 31.9983 35.7 31.9983Z"
					fill={color}
				/>
			</g>
		</svg>
	</div>
);

export default IconWallet;
