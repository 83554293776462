type ShortenAddressOptions = {
	chars?: number;
};

export function shortenAddress(address: string, options: ShortenAddressOptions = {}): string {
	const chars = options.chars ?? 6;

	if (!address || address.length < chars * 2) {
		throw new Error("Invalid address format");
	}

	const prefix = address.slice(0, chars);
	const suffix = address.slice(-chars);
	return `${prefix}...${suffix}`;
}
