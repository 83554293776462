import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconCollectionSecured = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.45312 0H27.606C27.6475 0 27.6885 0.000976562 27.7295 0.00317383L27.7969 0.00756836C27.8906 0.0151367 27.9839 0.0283203 28.0762 0.0473633C28.2192 0.0766602 28.3599 0.119629 28.4956 0.175781C28.6167 0.22583 28.7329 0.285889 28.8433 0.355225C28.9897 0.447266 29.1265 0.555908 29.25 0.678955C29.3599 0.789062 29.4585 0.909424 29.5439 1.03857C29.6265 1.1626 29.6968 1.29443 29.7539 1.43262C29.8408 1.64307 29.897 1.86475 29.9194 2.09058C29.9272 2.16748 29.9312 2.24463 29.9312 2.32202V22.4751C29.9312 22.7805 29.8716 23.083 29.7549 23.3652C29.6382 23.6475 29.4668 23.9038 29.251 24.1199C29.0352 24.3359 28.7783 24.5071 28.4961 24.6238C28.3604 24.6799 28.2202 24.7229 28.0776 24.7524C28.0083 24.7666 27.9385 24.7776 27.8682 24.7856C27.7812 24.7954 27.6938 24.8003 27.606 24.8H7.45312C7.14795 24.8 6.8457 24.7397 6.56348 24.623C6.28174 24.5061 6.02588 24.3347 5.81006 24.1189C5.59424 23.9028 5.42334 23.6465 5.30713 23.3645C5.19043 23.0825 5.13037 22.7803 5.13086 22.4751V2.32202C5.13086 1.70605 5.37549 1.11548 5.81104 0.680176C6.24658 0.244629 6.8374 0 7.45312 0ZM23.8936 3.55371L27.3843 7.20068L14.7075 20.4463L7.61572 13.0366L11.106 9.3894L14.7075 13.1521L23.8936 3.55371Z"
				fill={color}
			/>
			<path
				d="M2.78076 5.18896V25.1411C2.78076 25.4854 2.86914 25.8218 3.0332 26.1191C3.125 26.2856 3.24023 26.4399 3.37695 26.5769C3.53174 26.7314 3.7085 26.8586 3.8999 26.9553C4.18164 27.0972 4.49512 27.1731 4.81592 27.1731H24.7998V27.689C24.7998 27.9941 24.7397 28.2964 24.623 28.5784C24.5059 28.8601 24.335 29.1162 24.1187 29.332C24.0547 29.396 23.9873 29.4561 23.9165 29.512C23.8652 29.5525 23.812 29.5908 23.7573 29.627C23.6333 29.7085 23.502 29.7783 23.3647 29.8352C23.0825 29.9517 22.7803 30.0115 22.4751 30.011H2.32178C2.01709 30.011 1.71533 29.9509 1.43359 29.8342C1.38184 29.8127 1.33105 29.7896 1.28076 29.7646C1.21924 29.7339 1.15918 29.7002 1.10107 29.6641C0.94873 29.5698 0.807129 29.4583 0.680176 29.3308C0.464355 29.1152 0.293457 28.8594 0.176758 28.5776C0.138672 28.4851 0.106445 28.3904 0.0805664 28.2942C0.050293 28.1826 0.0283203 28.0691 0.0151367 27.9543C0.0117188 27.928 0.00927734 27.9016 0.00732422 27.8752L0.00439453 27.8333C0.00146484 27.7852 0 27.7371 0 27.689V7.51099C0 6.89526 0.244629 6.30444 0.680176 5.86914C1.11572 5.43359 1.70605 5.18896 2.32178 5.18896H2.78076Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconCollectionSecured;
