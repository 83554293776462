import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IconComic, IconQrcode } from "./mvicons";

export default function ScanSequence({
	start,
	cover,
	supply,
	issueNumber,
	releaseDate,
	onAnimationEnd,
	className,
}: {
	start: boolean;
	cover: string;
	supply?: string;
	issueNumber?: string;
	releaseDate: string;
	onAnimationEnd?: () => void;
	className: string;
}) {
	const [step, setStep] = useState(0);
	const [dots, setDots] = useState("");
	const carouselItemWidth = 48;
	const carouselItemCount = 12;
	const carouselItemGutter = 4;
	const carouselTotalGutter = carouselItemGutter * (carouselItemCount - 1);
	const carouselTotalWidth = carouselItemWidth * (carouselItemCount - 1) + carouselTotalGutter;

	useEffect(() => {
		if (start) {
			setStep(1);
		} else {
			setStep(0);
			setDots("");
		}
	}, [start]);

	useEffect(() => {
		if (step < 1 || step >= 4) {
			setDots("");
			return;
		}

		const interval = setInterval(() => {
			setDots((prev) => `${prev.length < 3 ? `${prev}.` : "."}`);
		}, 500);

		return () => clearInterval(interval);
	}, [step]);

	return (
		<div
			className={clsx(
				"relative flex flex-row items-center w-full max-w-[300px] p-6 mx-auto gap-3 rounded-lg bg-black/30 backdrop-blur overflow-hidden",
				className,
			)}
		>
			<div className="flex flex-col gap-4 items-center">
				<motion.div
					className="relative flex justify-center items-center w-[60px] h-[80px] p-1 rounded-lg border-2 overflow-hidden"
					initial={{ borderColor: "rgba(255, 255, 255, 0)" }}
					animate={step >= 1 ? { borderColor: "rgba(255, 255, 255, 1)" } : {}}
					transition={{ duration: 1.5, ease: [0.25, 1, 0.5, 1] }}
					onAnimationComplete={() => step === 1 && setStep(2)}
				>
					<motion.div
						className="relative flex justify-center items-center w-full h-full"
						initial={{ opacity: 1 }}
						animate={step >= 3 ? { opacity: 0 } : {}}
						transition={{ duration: 1, ease: "easeInOut" }}
					>
						<motion.div
							className="z-[2] inline-flex text-white"
							animate={step >= 1 ? { scale: 0.5 } : {}}
							transition={{ duration: 0.5, ease: [0.25, 1, 0.5, 1] }}
						>
							<IconComic size={54} />
						</motion.div>
						<motion.div
							className="z-[1] absolute rounded bottom-0 left-0 right-0 bg-mvmain-gradient"
							initial={{ height: "0%" }}
							animate={step >= 2 ? { height: "100%" } : {}}
							transition={{ duration: 0.8, ease: [0.25, 1, 0.5, 1] }}
							onAnimationComplete={() => step === 2 && setStep(3)}
						/>
					</motion.div>
					<motion.div
						className="z-[3] absolute left-0 flex flex-row h-full w-auto p-1"
						style={{ gap: `${carouselItemGutter}px` }}
						initial={{ opacity: 0, x: 0 }}
						animate={step >= 3 ? { opacity: 1, x: -carouselTotalWidth } : {}}
						transition={{
							opacity: { duration: 1, ease: "easeInOut" },
							x: {
								delay: 1,
								duration: 2,
								ease: [0.25, 1, 0.5, 1],
							},
						}}
						onAnimationComplete={() => {
							setStep(4);
							if (onAnimationEnd) onAnimationEnd();
						}}
					>
						{Array.from({ length: carouselItemCount }).map((_, index) => (
							<div
								key={index}
								className="inline-flex justify-center items-center shrink-0 rounded bg-white/10 overflow-hidden"
								style={{ width: `${carouselItemWidth}px` }}
							>
								<img className="block w-full h-full object-cover" src={cover} alt="Macroverse" />
							</div>
						))}
					</motion.div>
				</motion.div>
			</div>
			{step >= 4 ? (
				<div className="flex flex-col gap-2">
					<div className="flex flex-row items-center gap-1">
						<IconQrcode className="text-mvblue-300" size={14} />
						<span className="text-xs text-mvblue-300">Issue Confirmed</span>
					</div>
					<div className="flex flex-col">
						<span className="text-xs text-white leading-tight">
							{supply} {issueNumber}
						</span>
						<span className="text-xs text-white leading-tight">Authentic Edition</span>
						<span className="text-xs text-white leading-tight">{releaseDate}</span>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-2">
					<div className="flex flex-row items-center gap-1">
						<IconQrcode className="text-mvblue-300" size={14} />
						<span className="text-xs text-mvblue-300">Identifying Issue{dots}</span>
					</div>
					<div className="flex flex-col">
						<span className="text-xs text-white leading-tight">Finding Title{dots}</span>
						<span className="text-xs text-white leading-tight">Locating Edition{dots}</span>
						<span className="text-xs text-white leading-tight">Confirming Details{dots}</span>
					</div>
				</div>
			)}
			{step >= 1 && step < 4 && (
				<div className="z-[1] hidden md:flex absolute scanner-dialog-border scanner-dialog-border--sm inset-0" />
			)}
		</div>
	);
}
