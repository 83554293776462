import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconPetra = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M4.82964 3.26199C4.22605 3.47398 3.99601 3.74371 4.00005 4.41345C4.03196 9.49508 4.00208 14.577 4.03118 19.659C4.03641 20.5888 4.14124 21.5154 4.34387 22.4229C5.33424 26.8175 9.39157 30.0689 13.7901 29.9989V0.0530747C13.4908 0.146461 13.273 0.205451 13.0617 0.282339C10.3192 1.27862 7.58314 2.29529 4.82964 3.26199Z"
				fill={color}
			/>
			<path
				d="M25.2922 3.39787C24.118 2.93094 22.9272 2.5037 21.7388 2.07225C19.8784 1.39691 18.016 0.728727 15.9948 0V1.00904C15.9948 6.13763 15.9948 11.2663 15.9948 16.395C15.9948 17.5477 16.1487 17.707 17.31 17.4806C21.4968 16.6641 24.3902 14.2664 25.4133 10.1074C25.8978 8.13802 25.7232 6.00351 25.8015 3.94216C25.8083 3.76255 25.5078 3.48394 25.2917 3.3974"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconPetra;
