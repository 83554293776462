import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconCollectionSecure = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M1.88623 29.9585C2.0293 29.9861 2.17432 30 2.32031 30H22.4648C22.6274 29.9998 22.7896 29.9824 22.9482 29.9485C23.0864 29.9189 23.2222 29.8767 23.3535 29.8223C23.4912 29.7651 23.6226 29.6953 23.7461 29.6138C23.875 29.5286 23.9956 29.4304 24.1055 29.3203C24.3208 29.1047 24.4907 28.8481 24.6074 28.5664C24.6538 28.4539 24.6914 28.3381 24.7202 28.2202C24.7632 28.043 24.7852 27.8608 24.7852 27.6777V27.1621H4.81445C4.64746 27.1624 4.48145 27.1418 4.32031 27.1016C4.22314 27.0774 4.12793 27.0461 4.03516 27.0078C3.78809 26.9055 3.56396 26.7554 3.375 26.5664C3.18604 26.3774 3.03564 26.1533 2.93359 25.9062C2.83154 25.6592 2.7793 25.3943 2.7793 25.127V5.18555H2.32031C1.70508 5.18579 1.11475 5.43018 0.679688 5.86524C0.244629 6.30029 0.000488281 6.89063 0 7.50586V27.6777C0 27.9824 0.059082 28.2847 0.175781 28.5664C0.29248 28.8481 0.462402 29.1047 0.677734 29.3203C0.893066 29.5359 1.1499 29.7056 1.43164 29.8223C1.57861 29.8831 1.73096 29.9287 1.88623 29.9585Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.25 10.9885H23C23.3979 10.9885 23.7793 11.1467 24.0605 11.4285C24.3418 11.71 24.5 12.092 24.5 12.4902V18.498C24.5 18.8965 24.3418 19.2783 24.0605 19.5601C23.7793 19.8416 23.3979 20 23 20H12C11.6021 20 11.2207 19.8416 10.9395 19.5601C10.6582 19.2783 10.5 18.8965 10.5 18.498V12.4902C10.5 12.092 10.6582 11.71 10.9395 11.4285C11.2207 11.1467 11.6021 10.9885 12 10.9885H12.75V8.7356C12.7554 7.47778 13.2583 6.27319 14.1484 5.38574C15.0386 4.49829 16.2437 4 17.5 4C18.7563 4 19.9614 4.49829 20.8516 5.38574C21.7417 6.27319 22.2446 7.47778 22.25 8.7356V10.9885ZM15.25 8.7356V10.9885H19.75V8.7356C19.75 8.13818 19.5132 7.56494 19.0908 7.14258C18.6689 6.71997 18.0967 6.48267 17.5 6.48267C17.0801 6.48267 16.6729 6.6001 16.3213 6.8169C16.1729 6.9082 16.0342 7.01733 15.9092 7.14258C15.7534 7.29858 15.623 7.4751 15.5205 7.66577C15.3447 7.99146 15.25 8.35864 15.25 8.7356Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.80859 24.1035C6.24365 24.5388 6.8335 24.7847 7.44922 24.7852H27.5938C28.2095 24.7847 28.7993 24.5388 29.2344 24.1035C29.6694 23.6682 29.9141 23.0784 29.9141 22.4629V2.32031C29.9141 2.01563 29.853 1.71314 29.7363 1.43164C29.6196 1.15015 29.4497 0.895264 29.2344 0.679688C29.019 0.464112 28.7642 0.292237 28.4824 0.175782C28.2007 0.0593269 27.8984 -0.000243393 27.5938 7.47395e-07H7.44922C7.14453 -0.000243393 6.84229 0.0593269 6.56055 0.175782C6.40723 0.239259 6.26172 0.319337 6.12646 0.413819C6.01318 0.492677 5.90674 0.581544 5.80859 0.679688C5.59326 0.895264 5.42139 1.15015 5.30469 1.43164C5.18799 1.71314 5.12891 2.01563 5.12891 2.32031V22.4629C5.12891 23.0784 5.37354 23.6682 5.80859 24.1035ZM6.84766 22.4629V2.32031L6.85156 2.32227C6.85107 2.16333 6.91357 2.01123 7.02539 1.89844C7.08398 1.83936 7.15381 1.79346 7.22998 1.76318C7.29932 1.7356 7.37354 1.72095 7.44922 1.7207H27.5938C27.7529 1.7207 27.9053 1.78394 28.0176 1.89649C28.1299 2.00903 28.1934 2.16113 28.1934 2.32031V22.4629C28.1934 22.6221 28.1299 22.7742 28.0176 22.8867C27.9053 22.9993 27.7529 23.0625 27.5938 23.0625H7.44922C7.29004 23.0625 7.13574 22.9993 7.02344 22.8867C6.91113 22.7742 6.84766 22.6221 6.84766 22.4629Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconCollectionSecure;
