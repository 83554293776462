import { IconX } from "@tabler/icons-react";
import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";
import QuestBoard from "./features/profile/quest-board";

export default function Welcome({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) {
	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className="z-50 fixed inset-0 font-aller flex items-center justify-center min-h-screen w-full overflow-hidden"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* overlay */}
					<motion.div
						className="fixed inset-0 bg-mvdark-950/60"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* main container */}
					<motion.div
						className="relative flex flex-col items-center h-[100svh] w-screen overflow-y-auto overflow-x-hidden"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* main content */}
						<div className="relative flex flex-col flex-1 lg:flex-none h-auto w-full max-w-[1024px] lg:my-12 xl:my-24 rounded-none lg:rounded-4xl border border-white/10 bg-mvdark-950 bg-opacity-95 shadow-xl">
							{/* close button */}
							<button
								onClick={() => {
									onClose();
								}}
								type="button"
								className="z-10 absolute left-4 top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
							>
								<IconX size={28} stroke={2} />
							</button>

							<div className="flex flex-col w-full p-12 gap-8">
								<div className="flex flex-col justify-center items-center w-full gap-4">
									<div className="inline-flex flex-row items-center gap-2 text-white">
										<span className="font-semibold text-lg sm:text-2xl text-white">Welcome</span>
										<div className="w-[36px] sm:w-[44px]">
											<img className="block w-full" src="/svg/profile-macronaut.svg" alt="" />
										</div>
										<span className="font-semibold text-lg sm:text-2xl text-white">Macronaut</span>
									</div>
								</div>

								<div className="flex flex-col w-full gap-4">
									<p className="font-bold text-md text-white">Thanks for joining the mission!</p>
									<div className="flex flex-col w-full">
										<p className=" text-md text-white/50">
											Together, we&apos;re building a universe of storytelling and we can&apos;t do it without you!
										</p>
										<p className=" text-md text-white/50">
											Let&apos;s get you some rewards while you set-up your Macroverse account.
										</p>
									</div>
									<p className="font-bold text-md text-white">Where do you want to start?</p>
								</div>

								<div className="flex flex-row w-full min-h-[380px] shrink grow overflow-hidden">
									<QuestBoard />
								</div>

								<div className="flex flex-col w-full gap-4">
									<p className="text-md text-white/50">
										We&apos;ll be adding a ton of exciting new content, features and creators over the next weeks and
										months and we look forward to sharing the adventure.
									</p>
									<p className="font-bold text-md text-white">Suit up and Let&apos;s Go!</p>
									<p className="font-bold italic text-md text-white">Eben, Adam & Ricci</p>
								</div>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
}
