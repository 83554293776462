import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconDashboard = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.9706 1C19.7302 1 20.4045 1.486 20.6447 2.20653L24.2131 12.9105H28.2353C29.2099 12.9105 30 13.7005 30 14.675C30 15.6495 29.2099 16.4395 28.2353 16.4395H22.9412C22.1816 16.4395 21.5072 15.9535 21.267 15.233L18.9706 8.34441L12.7036 27.1435C12.4634 27.864 11.789 28.35 11.0294 28.35C10.2698 28.35 9.59547 27.864 9.35526 27.1435L5.7869 16.4395H1.76471C0.790086 16.4395 0 15.6495 0 14.675C0 13.7005 0.790086 12.9105 1.76471 12.9105H7.05882C7.81841 12.9105 8.49277 13.3965 8.73297 14.117L11.0294 21.0056L17.2964 2.20653C17.5366 1.486 18.211 1 18.9706 1Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconDashboard;
