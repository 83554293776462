import { profileQueryOptions } from "@/api/queries/profile-queries";
import { Button, ButtonIcon, ButtonLabel } from "@/components/core/buttons/button";
import { IconCheckCircle, IconLink } from "@/components/mvicons";
import { useScreenWidth } from "@/hooks/use-screen-width.ts";
import type { Suppliable } from "@/types/suppliable";
import { macronautProfileLink } from "@/utils/macronaut-link";
import { canNativeShare, nativeShare } from "@/utils/social-share";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import { useCopyToClipboard } from "usehooks-ts";

export default function ShareCollectionModal({ suppliable }: { suppliable: Suppliable }) {
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < 640;
	const profileQuery = useQuery(profileQueryOptions());
	const profile = profileQuery.data;
	const [_, copy] = useCopyToClipboard();

	const macronautShareLink = macronautProfileLink((profile?.username || profile?.macronaut_id) ?? "");

	const handleCopy = () => {
		copy(macronautShareLink)
			.then(() => {
				toast.success("Copied to clipboard");
			})
			.catch((_error) => {
				toast.error("Failed to copy to clipboard");
			});
	};

	const isIssue = true;

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col w-full max-w-[380px] gap-8">
				<div className="flex flex-col justify-center items-center w-full gap-4">
					<div className="flex flex-col justify-center items-center w-full">
						<span className="font-quagmire uppercase font-normal text-2xl text-mvblue-300 leading-8">
							You got it. Share it!
						</span>
					</div>

					<div className="flex flex-col w-full gap-4">
						<div
							className="flex flex-col w-full p-6 gap-4 rounded-4xl bg-black border border-white/20 shadow overflow-hidden"
							style={{
								filter: `
                  drop-shadow(0px 0px 4px rgba(0,0,0,.3))
                  drop-shadow(0px 0px 8px rgba(0,0,0,0.2))
                  drop-shadow(0px 0px 12px rgba(0,0,0,0.1))`,
							}}
						>
							<div className="inline-flex items-center justify-center h-[160px] sm:h-[220px]">
								{/*<img className="block h-full w-auto" src="/precode-horror.png" alt="Macroverse" />*/}
								{/*<img className="block h-full w-auto" src="/wutang.png" alt="Macroverse" />*/}
								<img className="block h-full w-auto" src={suppliable.image.original_url} alt="Macroverse" />
							</div>
							<div className="flex flex-row justify-center items-center w-full p-3 gap-2 rounded-lg bg-mvblue-300/30 backdrop-blur shadow">
								<IconCheckCircle className="text-mvblue-300" size={isMobile ? 18 : 32} />
								<span className="font-bold text-xs sm:text-sm text-mvblue-300 leading-tight">
									I collected this badge in the Macroverse!
								</span>
							</div>
							<div className="flex flex-col">
								<span className="font-semibold text-sm sm:text-md text-white leading-tight">{suppliable.name}</span>
								<span className="text-xs sm:text-sm text-white/50 leading-tight">{suppliable?.edition ?? ""}</span>
								{/* {isIssue && (
									<span className="text-xs sm:text-sm text-white/50 leading-tight">Is Printing | April 2025</span>
								)} */}
							</div>
						</div>
						<div className="hidden sm:flex flex-row justify-center w-full px-4 py-3 rounded-2xl bg-black border border-white/20 shadow">
							<a href="/" className="text-center text-mvblue-300 leading-tight">
								{macronautShareLink}
							</a>
						</div>
					</div>
					<div className="hidden sm:flex flex-col justify-center items-center w-full gap-4">
						<span className="text-center text-sm text-white leading-tight">
							Share this unique link and show off what you found in the Macroverse!
						</span>
					</div>

					<div className="flex flex-col w-full gap-2">
						<Button
							onClick={() => handleCopy()}
							className="mt-2 font-quagmire bg-mvdark-950 uppercase w-full !gap-2"
							type="button"
							variant="outline"
							color="blue"
							size="md"
						>
							<ButtonIcon>
								<IconLink size={22} />
							</ButtonIcon>
							<ButtonLabel>Copy Link</ButtonLabel>
						</Button>
						{canNativeShare && (
							<Button
								className="mt-2 font-quagmire bg-mvdark-950 uppercase w-full"
								type="button"
								variant="gradient"
								size="lg"
								onClick={() => {
									nativeShare({
										title: "Macroverse",
										text: "Check out this Macroverse Profile",
										url: macronautShareLink,
									});
								}}
							>
								<ButtonLabel>Share</ButtonLabel>
							</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
