import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconCollectionAdd = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M2.32031 30C2.01551 29.9999 1.71319 29.939 1.43164 29.8223C1.15009 29.7055 0.893167 29.5359 0.677735 29.3203C0.462303 29.1047 0.292301 28.8481 0.175781 28.5664C0.0592616 28.2848 -0.000131133 27.9825 2.17387e-07 27.6777V7.50586C0.000265228 6.89064 0.244661 6.30026 0.679688 5.86523C1.11471 5.43021 1.70509 5.18581 2.32031 5.18555H2.7793V25.127C2.77917 25.3942 2.83137 25.6593 2.93359 25.9062C3.03582 26.1532 3.18601 26.3774 3.375 26.5664C3.56399 26.7554 3.7882 26.9056 4.03516 27.0078C4.28211 27.11 4.54718 27.1622 4.81445 27.1621H24.7852V27.6777C24.7853 27.9825 24.7239 28.2848 24.6074 28.5664C24.4909 28.8481 24.3209 29.1047 24.1055 29.3203C23.89 29.5359 23.6351 29.7055 23.3535 29.8223C23.072 29.939 22.7696 29.9999 22.4648 30H2.32031ZM7.44922 24.7852C6.83373 24.7846 6.24362 24.5389 5.80859 24.1035C5.37357 23.6681 5.12891 23.0784 5.12891 22.4629V2.32031C5.12877 2.01561 5.18814 1.71318 5.30469 1.43164C5.42123 1.15011 5.59314 0.895146 5.80859 0.679688C6.02405 0.46423 6.27901 0.292326 6.56055 0.175781C6.84208 0.0592371 7.14452 -0.000131205 7.44922 2.17718e-07H27.5938C27.8985 -0.000131205 28.2009 0.0592371 28.4824 0.175781C28.764 0.292326 29.0189 0.46423 29.2344 0.679688C29.4498 0.895146 29.6198 1.15011 29.7363 1.43164C29.8529 1.71318 29.9142 2.01561 29.9141 2.32031V22.4629C29.9141 23.0784 29.6694 23.6681 29.2344 24.1035C28.7993 24.5389 28.2092 24.7846 27.5938 24.7852H7.44922ZM6.84766 2.32031V22.4629C6.84766 22.622 6.91092 22.7742 7.02344 22.8867C7.13596 22.9992 7.29009 23.0625 7.44922 23.0625H27.5938C27.7529 23.0625 27.9051 22.9992 28.0176 22.8867C28.1301 22.7742 28.1934 22.622 28.1934 22.4629V2.32031C28.1934 2.16118 28.1301 2.00901 28.0176 1.89648C27.9051 1.78396 27.7529 1.7207 27.5938 1.7207H7.44922C7.29026 1.7215 7.13732 1.78557 7.02539 1.89844C6.91346 2.01131 6.85103 2.16331 6.85156 2.32227L6.84766 2.32031ZM15.5332 20.2852V14.4121H9.6582V10.4004H15.5332V4.52539H19.543V10.4004H25.418V14.4121H19.543V20.2852H15.5332Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconCollectionAdd;
