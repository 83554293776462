import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { mobileDarklandS1Pages } from "@/constants/mobile-darkland-s1-pages.ts";
import { FreeMode, Mousewheel, Pagination } from "swiper/modules";

const ScrollReader = () => {
	return (
		<div className="z-[1] flex w-full h-full bg-black">
			<Swiper
				slidesPerView={1}
				centeredSlides={false}
				allowTouchMove={true}
				direction="vertical" // Enable vertical direction
				mousewheel={true} // Enable mouse wheel scrolling
				freeMode={true} // Enable free-flowing scrolling
				spaceBetween={0}
				pagination={{
					type: "progressbar", // Define type
					clickable: false, // Make it clickable
				}}
				modules={[Pagination, Mousewheel, FreeMode]} // Include necessary modules
				className="scroll-reader w-screen h-full"
			>
				{mobileDarklandS1Pages.map(({ src, altText }, index) => (
					<SwiperSlide key={index} className="flex justify-center w-full h-full">
						<div className="flex flex-col justify-center items-center h-full">
							<img className="block h-full" src={src} alt={altText} />
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default ScrollReader;
