import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { mobileRemindS1Pages } from "@/constants/mobile-remind-s1-pages.ts";
import { tabletRemindS1Pages } from "@/constants/tablet-remind-s1-pages.ts";
import { useScreenWidth } from "@/hooks/use-screen-width.ts";
import { Navigation, Pagination } from "swiper/modules";

const TapReader = () => {
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < 640;
	/*const isTablet = screenWidth > 640 && screenWidth < 768;*/

	return (
		<div className="z-[1] flex justify-center items-center w-screen h-full">
			<Swiper
				slidesPerView={1}
				spaceBetween={0}
				allowTouchMove={false}
				speed={0}
				pagination={{
					type: "progressbar", // Define type
					clickable: false, // Make it clickable
				}}
				navigation={{
					enabled: true,
					prevEl: ".swiper-button-prev", // Customize previous button
					nextEl: ".swiper-button-next", // Customize next button
				}}
				modules={[Pagination, Navigation]} // Include necessary modules
				className="tap-reader w-screen h-full"
			>
				<div className="z-50 absolute inset-0 grid grid-cols-2 w-full h-full gap-1">
					<div className="swiper-button-prev col-span-1 inline-flex justify-center items-center w-full h-full bg-transparent text-white" />
					<div className="swiper-button-next col-span-1 inline-flex justify-center items-center w-full h-full bg-transparent text-white" />
				</div>
				{isMobile
					? mobileRemindS1Pages.map(({ src, altText }, index) => (
							<SwiperSlide key={index} className="flex justify-center items-center w-auto h-full bg-black">
								<div className="flex w-auto h-full">
									<img className="w-auto h-full object-contain" src={src} alt={altText} />
								</div>
							</SwiperSlide>
						))
					: tabletRemindS1Pages.map(({ src, altText }, index) => (
							<SwiperSlide key={index} className="flex justify-center items-center w-full h-full bg-black">
								<div className="flex max-w-full max-h-full">
									<img className="w-full h-auto max-h-full object-contain" src={src} alt={altText} />
								</div>
							</SwiperSlide>
						))}
			</Swiper>
		</div>
	);
};

export default TapReader;
