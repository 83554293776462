import { Button, ButtonIcon, ButtonLabel } from "@/components/core/buttons/button";
import { Avatar, AvatarImage } from "@/components/core/display/avatar.tsx";
import { IconCheckCircle, IconCheckCircleFilled, IconCollections, IconLink } from "@/components/mvicons";
import type { User } from "@/types/user.ts";
import { macronautProfileLink } from "@/utils/macronaut-link";
import { canNativeShare, nativeShare } from "@/utils/social-share";
import { toast } from "sonner";
import { useCopyToClipboard } from "usehooks-ts";

export default function ShareProfileModal({ user }: { user: User }) {
	const userPermaLink = macronautProfileLink((user.username || user.macronaut_id) ?? "");
	const [_, copy] = useCopyToClipboard();

	const handleCopy = () => {
		copy(userPermaLink)
			.then(() => {
				toast.success("Copied to clipboard");
			})
			.catch((_error) => {
				toast.error("Failed to copy to clipboard");
			});
	};

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col w-full max-w-[380px] gap-8">
				<div className="flex flex-col justify-center items-center w-full gap-4">
					<div className="flex flex-col justify-center items-center w-full">
						<span className="font-quagmire uppercase font-normal text-2xl text-mvblue-300 leading-8">
							Share Your Profile
						</span>
					</div>

					<div className="flex flex-col w-full gap-3">
						<div className="flex flex-col w-full rounded-4xl bg-black border border-white/20 shadow overflow-hidden">
							<div className="relative flex flex-col items-center justify-end w-full h-[120px] mb-16 bg-mvmain-gradient-angled">
								<Avatar className="z-[2] w-[120px] h-[120px] -mb-[60px] rounded-full bg-mvmain-gradient-angled border-[3px] border-white">
									<AvatarImage src={user.avatar_url} />
									{!user.avatar_url && <AvatarImage src="/macronaut-avatar.png" />}
								</Avatar>
								{user?.banner_url ? (
									<img
										className="absolute inset-0 w-full h-full object-cover"
										src={user?.banner_url}
										alt="Macroverse"
									/>
								) : (
									<div className="z-[1] absolute -bottom-4 left-0 right-0 inset-0 w-full h-full bg-gradient-to-t from-black via-transparent to-transparent" />
								)}
							</div>
							<div className="flex flex-col justify-center items-center w-full p-6 pt-3">
								<div className="inline-flex justify-center flex-row flex-wrap items-center gap-2">
									<span className="text-center font-semibold text-lg text-white leading-tight">
										{user.first_name} {user.last_name}
									</span>
									<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/40 font-bold text-2xs text-black">
										@{user.username || user.macronaut_id}
									</span>
								</div>
								{user.headline && <span className="text-md text-white/50">{user.headline}</span>}
								<div className="inline-flex flex-col justify-center items-center sm:flex-row w-auto gap-4 sm:gap-6 mt-2">
									<div className="inline-flex flex-row items-center w-auto gap-6">
										<div className="inline-flex flex-col items-center">
											<div className="inline-flex flex-row items-center gap-2">
												<IconCollections className="text-white" size={18} />
												<span className="text-white text-sm">0</span>
											</div>
											<span className="text-white/50 text-xs">Collectibles</span>
										</div>
										<div className="inline-flex flex-col items-center">
											<div className="inline-flex flex-row items-center gap-2">
												<IconCheckCircleFilled className="text-white" size={18} />
												<span className="text-white text-sm">{user?.followers_count}</span>
											</div>
											<span className="text-white/50 text-xs">Followers</span>
										</div>
										<div className="inline-flex flex-col items-center">
											<div className="inline-flex flex-row items-center gap-2">
												<IconCheckCircle className="text-white" size={18} />
												<span className="text-white text-sm">{user?.following_count}</span>
											</div>
											<span className="text-white/50 text-xs">Following</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-row justify-center w-full px-4 py-3 rounded-2xl bg-black border border-white/20 shadow">
							<a href={userPermaLink} className="text-center text-mvblue-300 leading-tight">
								{userPermaLink}
							</a>
						</div>
					</div>
					<div className="flex flex-col justify-center items-center w-full gap-4">
						<span className="text-center text-sm text-white leading-tight">
							This is your unique public Profile link. Share it to invite others to check out your collection and
							connect in the Macroverse!
						</span>
					</div>

					<div className="flex flex-col w-full gap-2">
						<Button
							onClick={() => handleCopy()}
							className="mt-2 font-quagmire bg-mvdark-950 uppercase w-full !gap-2"
							type="button"
							variant="outline"
							color="blue"
							size="md"
						>
							<ButtonIcon>
								<IconLink size={22} />
							</ButtonIcon>
							<ButtonLabel>Copy Link</ButtonLabel>
						</Button>
						{canNativeShare && (
							<Button
								className="mt-2 font-quagmire bg-mvdark-950 uppercase w-full"
								type="button"
								variant="gradient"
								size="lg"
								onClick={() => {
									nativeShare({
										title: "Macroverse",
										text: "Check out this Macroverse Profile",
										url: userPermaLink,
									});
								}}
							>
								<ButtonLabel>Share</ButtonLabel>
							</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
