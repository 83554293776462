import questService from "@/api/services/quest-service.ts";
import { queryClient } from "@/query-client.ts";
import type { Quest } from "@/types/quest.ts";
import { queryOptions, useMutation } from "@tanstack/react-query";
import { z } from "zod";

export const questSearchQuery = z.object({
	tags: z.string().optional(),
	include: z.string().optional(),
});

export const questQueryOptions = (search?: z.infer<typeof questSearchQuery>) => {
	return queryOptions({
		queryKey: ["quests", search],
		queryFn: () => questService.list(search),
	});
};

export const useQuestClaimMutation = () => {
	return useMutation({
		mutationFn: questService.claim,
		onMutate: async ({ quest_id }) => {
			await queryClient.cancelQueries({ queryKey: ["quests"] });
			const previousQuests = queryClient.getQueryData(["quests", { include: "media" }]);

			queryClient.setQueryData(["quests", { include: "media" }], (oldQuests: { data: Quest[] }) => {
				return {
					data: oldQuests?.data.filter((quest: Quest) => quest.id !== quest_id),
				};
			});

			return { previousQuests };
		},
		onError: (_err, _questId, context: any) => {
			queryClient.setQueryData(["quests", { include: "media" }], context?.previousQuests);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["quests"] });
			queryClient.invalidateQueries({ queryKey: ["profile"] });
			queryClient.invalidateQueries({ queryKey: ["user"] });
		},
	});
};
