import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import axiosThrottle from "axios-request-throttle";
import queryString from "query-string";
import { useEffect } from "react";

export const apiClient = axios.create({
	withCredentials: true,
	baseURL: import.meta.env.VITE_PUBLIC_API_BASE_URL || "https://api.macroverse.dev/api/",
	paramsSerializer: {
		serialize(params) {
			return queryString.stringify(params, { arrayFormat: "bracket" });
		},
	},
	timeout: 1000 * 60 * 5,
});

axiosThrottle.use(apiClient, { requestsPerSecond: 50 });

export const useApiClient = () => {
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		const interceptor = apiClient.interceptors.request.use(async (config) => {
			try {
				const token = await getAccessTokenSilently();
				config.headers = config.headers || {};
				config.headers.Authorization = `Bearer ${token}`;
			} catch (error) {
				console.error("Failed to get access token:", error);
			}
			return config;
		});

		return () => {
			apiClient.interceptors.request.eject(interceptor);
		};
	}, [getAccessTokenSilently]);

	return apiClient;
};
