import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconActivity = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 31 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M21.8 28.2498H4.30014C3.83601 28.2498 3.3909 28.0654 3.06271 27.7372C2.73453 27.409 2.55015 26.9639 2.55015 26.4998C2.55015 26.0357 2.73453 25.5906 3.06271 25.2624C3.3909 24.9342 3.83601 24.7498 4.30014 24.7498H21.8C22.2641 24.7498 22.7092 24.9342 23.0374 25.2624C23.3656 25.5906 23.55 26.0357 23.55 26.4998C23.55 26.9639 23.3656 27.409 23.0374 27.7372C22.7092 28.0654 22.2641 28.2498 21.8 28.2498Z"
				fill={color}
			/>
			<path
				d="M28.6747 20.6662H2.42491C1.94434 20.6823 1.47702 20.5071 1.12548 20.179C0.773951 19.8509 0.566916 19.3968 0.549805 18.9162C0.566916 18.4357 0.773951 17.9816 1.12548 17.6535C1.47702 17.3254 1.94434 17.1502 2.42491 17.1663H28.6747C29.1553 17.1502 29.6226 17.3254 29.9741 17.6535C30.3257 17.9816 30.5327 18.4357 30.5498 18.9162C30.5327 19.3968 30.3257 19.8509 29.9741 20.179C29.6226 20.5071 29.1553 20.6823 28.6747 20.6662Z"
				fill={color}
			/>
			<path
				d="M28.6747 13.0835H2.42491C1.94434 13.0996 1.47702 12.9244 1.12548 12.5963C0.773951 12.2682 0.566916 11.8141 0.549805 11.3335C0.566916 10.853 0.773951 10.3989 1.12548 10.0708C1.47702 9.74272 1.94434 9.56749 2.42491 9.58356H28.6747C29.1553 9.56749 29.6226 9.74272 29.9741 10.0708C30.3257 10.3989 30.5327 10.853 30.5498 11.3335C30.5327 11.8141 30.3257 12.2682 29.9741 12.5963C29.6226 12.9244 29.1553 13.0996 28.6747 13.0835Z"
				fill={color}
			/>
			<path
				d="M19.7996 5.49997H2.29979C1.83567 5.49997 1.39055 5.3156 1.06236 4.98741C0.734178 4.65923 0.549805 4.21411 0.549805 3.74999C0.549805 3.28586 0.734178 2.84074 1.06236 2.51256C1.39055 2.18437 1.83567 2 2.29979 2H19.7996C20.2638 2 20.7089 2.18437 21.0371 2.51256C21.3653 2.84074 21.5496 3.28586 21.5496 3.74999C21.5496 4.21411 21.3653 4.65923 21.0371 4.98741C20.7089 5.3156 20.2638 5.49997 19.7996 5.49997Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconActivity;
