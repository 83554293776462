export const nativeShare = async ({
	title,
	text,
	url,
	onError,
	onNonNative,
}: ShareData & { onError?: (error: Error) => void; onNonNative?: () => void }): Promise<void> => {
	if (navigator.share) {
		navigator
			.share({
				title,
				text,
				url,
			})
			.catch((error) => (onError ? onError(error) : () => {}));
	} else {
		onNonNative ? onNonNative() : () => {};
	}
};

export const canNativeShare = navigator.share;

type LinkShareSocials = "facebook" | "twitter" | "linkedin" | "whatsapp" | "telegram" | "reddit" | "mail" | "sms";

interface LinkShareData extends ShareData {
	url: string;
	social: LinkShareSocials;
}

export const linkShare = ({ url, title, text, social }: LinkShareData) => {
	url = encodeURIComponent(url);

	text = encodeURIComponent(text ?? "");

	title = encodeURIComponent(title ?? "");

	switch (social) {
		case "facebook":
			return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
		case "twitter":
			return `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
		case "linkedin":
			return `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
		case "whatsapp":
			return `https://wa.me/?text=${text}%20${url}`;
		case "telegram":
			return `https://t.me/share/url?url=${url}&text=${text}`;
		case "reddit":
			return `https://www.reddit.com/submit?url=${url}&title=${text}`;
		case "mail":
			return `mailto:?subject=${text}&body=${text}%0A${url}`;
		case "sms":
			return `sms:?body=${text}%20${url}`;
		default:
			return "";
	}
};
