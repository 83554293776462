import { apiClient } from "@/api/api-client.ts";
import type { Pagination } from "@/api/base-service.ts";
import type { Badge } from "@/types/badge.ts";

const badgeService = {
	list: async (params: any) => {
		const response = await apiClient.get<{ data: Badge[]; meta: Pagination }>("/badges", { params });

		return response.data;
	},
};

export default badgeService;
