import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import ChangeBannerImageModal from "@/components/change-banner-image-modal.tsx";
import CustomToast from "@/components/core/feedbacks/custom-toast.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import ProfileAvatarModal from "@/components/features/profile/profile-avatar-modal.tsx";
import ProfileForm from "@/components/features/profile/profile-form.tsx";
import { PortalContainerProvider } from "@/components/layouts/portal-container-context.tsx";
import {
	PortalContainer,
	PortalContainerBody,
	PortalContainerContent,
	PortalContainerHead,
} from "@/components/layouts/portal-container.tsx";
import IconUser from "@/components/mvicons/icon-user.tsx";
import PortalTopbar from "@/components/portal-topbar.tsx";
import { IconPencil } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";

export const Route = createFileRoute("/profile/edit")({
	loader: ({ context: { queryClient } }) => {
		queryClient.prefetchQuery(profileQueryOptions());
	},
	component: Page,
});

function Page() {
	const modal = useModal();
	const profileQuery = useSuspenseQuery(profileQueryOptions());
	const profile = profileQuery.data;
	const navigate = useNavigate({
		from: "/profile/edit",
	});

	return (
		<PortalContainerProvider user={profile}>
			<PortalContainer>
				<PortalContainerHead profile={profile}>
					<PortalTopbar profile={profile} />

					<div className="z-[3] absolute left-1/2 -bottom-[55px] md:-bottom-[80px] -translate-x-1/2">
						<div className="relative inline-flex justify-center items-center flex-col w-auto">
							<div className="inline-flex justify-center items-end w-[110px] md:w-[160px] h-[110px] md:h-[160px] rounded-full bg-mvclaimcard-gradient-a border-4 border-white overflow-hidden">
								{profile.avatar_url ? (
									<img className="block w-full" src={profile.avatar_url} alt={profile.username} />
								) : (
									<img className="block w-full" src="/macronaut-avatar.png" alt={profile.username} />
								)}
							</div>

							<div className="absolute bottom-0 md:bottom-1 right-1 md:right-2 cursor-pointer">
								<div className="relative flex flex-col w-full h-[auto]">
									<button
										onClick={() => {
											modal.open({
												title: "Change Profile Photo",
												className: "overflow-hidden",
												component: <ProfileAvatarModal profile={profile} />,
											});
										}}
										type="button"
										className="inline-flex flex-row justify-center items-center w-[40px] h-[40px] rounded-full gap-1 bg-mvdark-950/50 backdrop-blur border-2 border-white text-white/50 hover:text-white"
									>
										<IconPencil className="translate-y-[-1px]" size={24} stroke={2} />
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="z-[3] absolute bottom-0 left-1/2 -translate-x-1/2 flex flex-row justify-end w-full max-w-[960px] gap-4 p-4">
						<button
							onClick={() => {
								modal.open({
									title: "Change Profile Photo",
									className: "mt-[300px]",
									component: <ChangeBannerImageModal profile={profile} />,
								});
							}}
							type="button"
							className="inline-flex self-end flex-row items-center gap-1 text-white opacity-70 hover:opacity-100"
						>
							<span>
								<IconPencil className="translate-y-[-1px]" size={20} stroke={2} />
							</span>
							<span className="font-semibold text-sm uppercase">Edit</span>
						</button>
					</div>
				</PortalContainerHead>

				<PortalContainerBody>
					<PortalContainerContent>
						<ProfileForm
							profile={profile}
							onSubmit={async () => {
								toast(<CustomToast icon={<IconUser size={72} />} message="Profile Updated" />, {
									duration: 2000,
									position: "top-center",
									className:
										"flex flex-row justify-center p-0 bg-transparent shadow-none !border-none !mt-[calc(50vh-65px)]",
								});

								await navigate({
									to: "/overview",
								});
							}}
						/>
					</PortalContainerContent>
				</PortalContainerBody>
			</PortalContainer>
		</PortalContainerProvider>
	);
}
