import clsx from "clsx";

type IconProps = {
	color?: string;
	title?: string;
	className?: string;
};

const SwipeArrowLeft = ({ color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div className={clsx("inline-flex shrink-0 justify-center items-center", className)}>
		<svg
			width="156"
			height="70"
			viewBox="0 0 156 70"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.24263 35L39.1213 5.1213L34.8787 0.878662L0.757347 35L34.8787 69.1213L39.1213 64.8787L9.24263 35Z"
				fill={color}
			/>
			<path fillRule="evenodd" clipRule="evenodd" d="M6 33H156V37H6V33Z" fill={color} />
		</svg>
	</div>
);

export default SwipeArrowLeft;
