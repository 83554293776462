import { apiClient } from "@/api/api-client.ts";
import type { Scannable } from "@/types/scannable.ts";

const scannableService = {
	findByCode: async (code: string) => {
		const response = await apiClient.get<{ data: Scannable }>(`/scannable/${code}`);

		return response.data.data;
	},
};

export default scannableService;
