import type { AxiosError } from "axios";

function formatError(error: AxiosError) {
	if (error.response) {
		return error.response.data.message;
	}

	return error.message;
}

function transformSearch<T extends Record<string, any> | undefined>(
	search: T,
	keys: T extends Record<string, any> ? (keyof T)[] : [],
): Record<string, any> {
	const result: Record<string, any> = {};

	if (!search) {
		return result;
	}

	for (const key in search) {
		if ((keys as (keyof T)[]).includes(key as keyof T)) {
			result[`filter[${key}]`] = search[key];
		} else {
			result[key] = search[key];
		}
	}

	return result;
}

function transformObjectValuesToString(obj: Record<string, string[]>, separator = ",") {
	const result: Record<string, string> = {};

	for (const key in obj) {
		// if (obj[key].length === 0) {
		// 	result[key] = "";
		// 	continue;
		// }

		result[key] = obj[key].join(separator);
	}

	return result;
}

function transformStringToObjectValues(obj: Record<string, string>, keys: string[], separator = ",") {
	const result: Record<string, string[]> = {};

	for (const key of keys) {
		if (!obj[key]) {
			result[key] = [];
			continue;
		}

		result[key] = obj[key].split(separator);
	}

	return result;
}

export { formatError, transformSearch, transformObjectValuesToString, transformStringToObjectValues };
