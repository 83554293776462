import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconCheckCircle = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0432 16.9568L21 8.00004L23.2302 10.2302L12.0432 21.4172L6 15.374L8.23019 13.1438L12.0432 16.9568Z"
				fill={color}
			/>
			<path
				d="M15 3C11.7947 3 8.78122 4.24822 6.51472 6.51472C4.24822 8.78122 3 11.7947 3 15C3 18.2053 4.24822 21.2188 6.51472 23.4853C8.78122 25.7518 11.7947 27 15 27C18.2053 27 21.2188 25.7518 23.4853 23.4853C25.7518 21.2188 27 18.2053 27 15C27 11.7947 25.7518 8.78122 23.4853 6.51472C21.2188 4.24822 18.2053 3 15 3ZM15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconCheckCircle;
