import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconWebsite = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 1.81818C8.38179 1.81818 6.79992 2.29804 5.45443 3.19707C4.10894 4.0961 3.06025 5.37392 2.44099 6.86895C1.82173 8.36398 1.6597 10.0091 1.9754 11.5962C2.29109 13.1833 3.07034 14.6412 4.21458 15.7854C5.35883 16.9297 6.81669 17.7089 8.40381 18.0246C9.99093 18.3403 11.636 18.1783 13.131 17.559C14.6261 16.9397 15.9039 15.8911 16.8029 14.5456C17.702 13.2001 18.1818 11.6182 18.1818 10C18.1818 8.92555 17.9702 7.86162 17.559 6.86895C17.1478 5.87629 16.5452 4.97433 15.7854 4.21458C15.0257 3.45483 14.1237 2.85216 13.131 2.44098C12.1384 2.02981 11.0745 1.81818 10 1.81818ZM4.4443 1.6853C6.08879 0.58649 8.02219 0 10 0C11.3132 0 12.6136 0.258658 13.8268 0.761204C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17316C19.7413 7.38642 20 8.68678 20 10C20 11.9778 19.4135 13.9112 18.3147 15.5557C17.2159 17.2002 15.6541 18.4819 13.8268 19.2388C11.9996 19.9957 9.98891 20.1937 8.0491 19.8078C6.10929 19.422 4.32746 18.4696 2.92894 17.0711C1.53041 15.6725 0.578004 13.8907 0.192152 11.9509C-0.1937 10.0111 0.00433286 8.00043 0.761209 6.17316C1.51809 4.3459 2.79981 2.78412 4.4443 1.6853Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.47565e-06 10C4.47565e-06 9.49792 0.407018 9.09091 0.909095 9.09091H19.0909C19.593 9.09091 20 9.49792 20 10C20 10.5021 19.593 10.9091 19.0909 10.9091H0.909095C0.407018 10.9091 4.47565e-06 10.5021 4.47565e-06 10Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 0C10.2554 0 10.499 0.107419 10.6712 0.295979C13.0937 2.9481 14.4704 6.38987 14.5453 9.98106C14.5455 9.99368 14.5455 10.0063 14.5453 10.0189C14.4704 13.6101 13.0937 17.0519 10.6712 19.704C10.499 19.8926 10.2554 20 10 20C9.74462 20 9.50101 19.8926 9.32878 19.704C6.90627 17.0519 5.52956 13.6101 5.45475 10.0189C5.45448 10.0063 5.45448 9.99368 5.45475 9.98106C5.52956 6.38987 6.90627 2.9481 9.32878 0.295979C9.50101 0.107419 9.74462 0 10 0ZM7.27294 10C7.33525 12.7953 8.2966 15.4865 10 17.6811C11.7034 15.4865 12.6648 12.7953 12.7271 10C12.6648 7.20469 11.7034 4.51349 10 2.31891C8.2966 4.51349 7.33525 7.20469 7.27294 10Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconWebsite;
