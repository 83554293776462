import { badgesQueryOptions } from "@/api/queries/badge-queries.ts";
import { collectionsQueryOptions } from "@/api/queries/collection-queries.ts";
import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import BadgeModal from "@/components/badge-modal.tsx";
import CollectionBadges from "@/components/collections/collection-badges";
import { Collections } from "@/components/collections/collections.tsx";
import { Switch } from "@/components/core/forms/switch.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { useWindowSize } from "usehooks-ts";

export const Route = createFileRoute("/_profile/collections")({
	component: Page,
	loader: ({ context: { queryClient } }) => {
		queryClient.ensureQueryData(
			badgesQueryOptions({
				include: "media",
			}),
		);

		return queryClient.ensureQueryData(
			collectionsQueryOptions({
				include: "media",
			}),
		);
	},
});

function Page() {
	const modal = useModal();
	const { width = 0 } = useWindowSize();
	const isMobile = width < 640;
	const isTablet = width >= 640 && width < 768;
	const displayBadgeCount = isMobile ? 4 : isTablet ? 5 : 7;

	const [showAllItems, setShowAllItems] = useState(false);
	const collectionQuery = useSuspenseQuery(
		collectionsQueryOptions({
			include: "media",
		}),
	);
	const collections = collectionQuery.data;

	const badgeQuery = useQuery(
		badgesQueryOptions({
			include: "media",
			is_owned: true,
		}),
	);
	const badges = badgeQuery.data;
	const profileQuery = useQuery(profileQueryOptions());
	const profile = profileQuery.data;

	const badgeSeeMoreCount = Math.max((badges?.meta.total ?? 0) - displayBadgeCount, 0);
	const showBadgePlaceholder = badges
		? (isMobile && badges.data.length < 2) ||
			(isTablet && badges.data.length < 3) ||
			(!isMobile && !isTablet && badges.data.length < 4)
		: true;
	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-4">
				<div className="flex flex-row items-start sm:items-end justify-between gap-2 sm:gap-4">
					<div className="inline-flex flex-col w-auto">
						<span className="text-sm font-semibold text-white">Your Badges</span>
					</div>
					<div className="inline-flex flex-row items-center w-auto gap-1">
						{badges?.data.length !== 0 && badgeSeeMoreCount ? (
							<span className="text-sm text-white/50">+{badgeSeeMoreCount} |</span>
						) : (
							<></>
						)}
						<button
							onClick={() => {
								modal.open({
									title: "Wallet Options Modal",
									size: "md",
									component: <BadgeModal badges={badges?.data || []} />,
								});
							}}
							type="button"
							className="text-sm text-white/50 hover:text-white"
						>
							See All
						</button>
					</div>
				</div>
				<CollectionBadges
					badges={badges?.data || []}
					displayCount={displayBadgeCount}
					showPlaceholder={showBadgePlaceholder}
				/>
			</div>

			<div className="flex flex-col w-full gap-4">
				<div className="flex flex-row items-start sm:items-end justify-between gap-2 sm:gap-4">
					<div className="inline-flex flex-col w-auto">
						<span className="text-sm font-semibold text-white">Your Collection</span>
					</div>
					<div className="inline-flex flex-row items-center w-auto gap-3">
						<span className="text-sm text-white/50">Show what's missing</span>
						<Switch checked={showAllItems} onCheckedChange={setShowAllItems} />
					</div>
				</div>

				<Collections user={profile} collections={collections.data} showAllItems={showAllItems} />
			</div>
		</div>
	);
}
