import { IconAlertTriangle } from "@tabler/icons-react";

export function ErrorCameraPermissionModal() {
	return (
		<div className="flex flex-col w-full gap-8">
			<div className="flex flex-col w-full gap-6">
				<div className="flex flex-col w-full gap-3">
					<div className="flex flex-row justify-center items-center gap-2 text-mvred-600">
						<IconAlertTriangle size={32} stroke={2} />
						<span className="text-center font-semibold text-xl leading-none tracking-tight">Doh!</span>
					</div>
					<span className="font-quagmire text-center font-semibold text-2xl leading-none tracking-tight uppercase text-mvred-600">
						We Need Camera Access
					</span>
				</div>
				<div className="flex flex-col w-full gap-3">
					<span className="text-white/70 text-center">
						We need camera access to scan QR codes and redeem your digital twins and other perks.
					</span>
					<span className="font-semibold text-white text-center">
						Allow Camera Access in Settings to start scanning.
					</span>
				</div>
			</div>
		</div>
	);
}
