import { apiClient } from "@/api/api-client.ts";
import type { Pagination } from "@/api/base-service.ts";
import type { Activity } from "@/types/activity.ts";

const activityService = {
	list: async (params: any) => {
		const response = await apiClient.get<{
			data: Activity[];
			meta: Pagination;
		}>("/activities", { params });

		return response.data;
	},
};

export default activityService;
