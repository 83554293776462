import { apiClient } from "@/api/api-client.ts";
import type { Pagination } from "@/api/base-service.ts";
import type { Collection } from "@/types/collection.ts";
import type { Quest } from "@/types/quest.ts";

const questService = {
	list: async (params: any) => {
		const response = await apiClient.get<{ data: Collection[]; meta: Pagination }>("/quests", { params });

		return response.data;
	},
	claim: async (payload: {
		quest_id: string;
	}) => {
		await apiClient.post<{ data: Quest }>("/quest_claim", payload);
	},
};

export default questService;
