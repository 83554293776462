import activityService from "@/api/services/activity-service.ts";
import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

export const activitySearchQuery = z.object({
	page: z.string().optional(),
	per_page: z.number().optional(),
});

export const activitiesQueryOptions = (search?: z.infer<typeof activitySearchQuery>) => {
	return queryOptions({
		queryKey: ["activities", search],
		queryFn: () => activityService.list(search),
	});
};
