import { Button, ButtonLabel } from "@/components/core/buttons/button";
import Tooltip from "@/components/core/feedbacks/tooltip";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { IconInstagram, IconKickstarter, IconXtwitter } from "@/components/mvicons";
import { useAuth0 } from "@auth0/auth0-react";
import { IconBookmark, IconCircleCheck, IconPlayerPlay, IconShare3 } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";

export const Route = createFileRoute("/comic-stack")({
	component: Page,
});

function Page() {
	const modal = useModal();
	const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";
	const { loginWithRedirect } = useAuth0();

	const handleLogin = async () => {
		await loginWithRedirect();
	};

	return (
		<div className="flex flex-col items-center w-full min-h-screen bg-mvdark-950">
			<div
				className={clsx(
					"z-[3] fixed left-0 right-0 flex flex-row items-center justify-between w-full gap-4 p-4 md:p-6 bg-gradient-to-b from-mvdark-950/80 to-transparent",
					isProduction ? "top-0" : "top-8",
				)}
			>
				<div className="inline-flex h-[44px]">
					<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
				</div>
				<div className="inline-flex flex-row w-auto gap-2">
					<Button
						className="font-quagmire backdrop-blur uppercase"
						onClick={handleLogin}
						type="button"
						variant="gradient"
					>
						<ButtonLabel>Login</ButtonLabel>
					</Button>
				</div>
			</div>

			{/* content */}
			<div className="flex flex-col items-center w-full max-w-[1400px] h-full">
				<div className="relative flex flex-col justify-end items-center w-full">
					{/*<div className="absolute bottom-0 right-0 left-0 flex flex-col items-center w-full gap-6 p-8">
						<div className="inline-flex h-[70px] w-auto">
							<img
								className="block h-full"
								src="/svg/authentic-editions.svg"
								alt="Macroverse"
							/>
						</div>
						<div className="relative flex flex-row w-full max-w-[720px] h-[6px] rounded-full bg-mvdark-700 overflow-hidden">
							<div className="absolute w-[75%] h-full bg-gradient-to-r from-mvblue-600 via-mvblue-500 to-mvblue-300" />
						</div>
						<div className="flex flex-row w-auto gap-4">
							<div className="font-quagmire inline-flex flex-row justify-center items-center w-auto h-[32px] px-4 rounded-full bg-green-400 gap-1 text-mvdark-950">
								<IconCircleCheck size={18} stroke={2} />
								<span className=" mb-[2px] uppercase ">Print Ready</span>
							</div>
							<div className="inline-flex flex-row w-auto gap-1.5">
								<Tooltip content="Watch Video" sideOffset={8}>
									<button
										type="button"
										className="font-quagmire inline-flex justify-center items-center h-[32px] w-[32px] rounded-full border-2 border-white/30 hover:border-white text-white uppercase"
									>
										<IconPlayerPlay size={18} />
									</button>
								</Tooltip>
								<Tooltip content="Share This" sideOffset={8}>
									<button
										type="button"
										className="font-quagmire inline-flex justify-center items-center h-[32px] w-[32px] rounded-full border-2 border-white/30 hover:border-white text-white uppercase"
									>
										<IconShare3 size={18} />
									</button>
								</Tooltip>
								<Tooltip content="Bookmark This" sideOffset={8}>
									<button
										type="button"
										className="font-quagmire inline-flex justify-center items-center h-[32px] w-[32px] rounded-full border-2 border-white/30 hover:border-white text-white uppercase"
									>
										<IconBookmark size={18} />
									</button>
								</Tooltip>
							</div>
						</div>
					</div>*/}
					<img className="block w-full -mt-12" src="/comic-stack-header.png" alt="Horror" />
				</div>
				<div className="flex flex-row w-full h-full max-w-[1200px] px-4">
					<div className="flex flex-col w-full border-r border-white/10">
						<div className="flex flex-col w-full p-8 pl-0 gap-4 border-b border-white/10 last:border-b-0">
							<span className="text-lg font-bold text-white">Story</span>
							<p className="text-sm text-white/70">
								Only 4 characters have been continuously published in monthly comic books since the beginning of The
								Golden Age, thrilling hundreds of millions and enthralling the globe: DC Comics’ iconic trio of
								Superman, Batman, and Wonder Woman and that amazing archetype of Americana…
							</p>
							<p className="text-sm text-white/70">
								While the first appearances of Superman, Batman, and Wonder Woman have all been celebrated in
								best-selling, chart-topping facsimile editions in the past 3 years, the fourth character in this
								timeless quartet has been noticeably absent.
							</p>
						</div>
						<div className="flex flex-col w-full p-8 pl-0 gap-4 border-b border-white/10 last:border-b-0">
							<span className="text-lg font-bold text-white">The Collection</span>
							{/*<div className="flex flex-col w-full p-8 rounded-xl bg-mvdark-900/20">
                <img className="block w-full" src="/archie-sample-image.png" alt="Archie" />
              </div>*/}
							<p className="text-sm text-white/70">
								No serious comic book collection will be complete without these landmark issues! Buying individual
								copies of the first appearance of Archie can set you back $250,000 – if you can find it! Many of the
								early issues published during World War II were lost to paper recycling campaigns that swept the United
								States. What’s more, Archie comics were not kept and preserved for collections the way superhero comics
								were held for future generations by fans! Most copies were read and then thrown away by Mom cleaning the
								kids’ rooms...
							</p>
							<p className="text-sm text-white/70">
								In 1941, the comic book publishing company MLJ put out the 22nd issue of their superhero anthology
								series Pep Comics. While the world has forgotten The Shield, The Hangman, Jolly Roger, and Danny in
								Wonderland, this issue of Pep took a chance on a new character, Archie Andrews, and his friends Jughead
								and Betty. An instant hit, Archie quickly graduated to his own starring series, the self-titled Archie
								Comics!
							</p>
							<p className="text-sm text-white/70">
								Realizing what they had, MLJ surrendered their super-heroes to Archie and remixed themselves around
								their new star. Soon Archie was not just headlining his own comic, he was starring in multiple monthlies
								and spinning off successful side series! It’s been a deluge of Archie titles starring Archie himself,
								and an amazing array of supporting players, ever since, some of which have become iconic characters in
								their own right. The world can’t get enough!
							</p>
						</div>
						<div className="flex flex-col w-full p-8 pl-0 gap-4 border-b border-white/10 last:border-b-0">
							<span className="text-lg font-bold text-white">Risk and Challenges</span>
							<p className="text-sm text-white/70">
								With over fifty years of comic book publishing experience, the Authentic Editions team has generated
								some of the best-selling comic books of all time.
							</p>
							<p className="text-sm text-white/70">
								While the team has printed millions of comic books in their career, this is only the second Authentic
								Editions project and Kickstarter campaign.
							</p>
							<p className="text-sm text-white/70">
								To address this, Authentic Editions has hired a third party fulfillment company with over ten years of
								experience in the arena with an excellent track record of handling collectibles and delivering packages
								with minimal damages.
							</p>
							<p className="text-sm text-white/70">Questions? Email the support team at hq@macroverse.com</p>
						</div>
					</div>
					<div className="flex shrink-0 flex-col w-[420px]">
						<div className="flex flex-col w-full p-8 pr-0 gap-4 border-b border-white/10 last:border-b-0">
							<div className="inline-flex flex-row items-center justify-between w-full gap-4">
								<span className="text-lg font-bold text-white">Ticket to Series</span>
								<span className="text-lg font-bold text-white">$1</span>
							</div>
							<div className="flex flex-col items-center w-full gap-6">
								<div className="relative flex flex-row w-full h-[6px] rounded-full bg-mvdark-700 overflow-hidden">
									<div className="absolute w-[75%] h-full bg-gradient-to-r from-mvblue-600 via-mvblue-500 to-mvblue-300" />
								</div>
								<div className="flex flex-row w-full justify-between gap-4">
									<div className="font-quagmire inline-flex flex-row justify-center items-center w-auto h-[32px] px-4 rounded-full bg-green-400 gap-1 text-mvdark-950">
										<IconCircleCheck size={18} stroke={2} />
										<span className=" mb-[2px] uppercase ">Print Ready</span>
									</div>
									<div className="inline-flex flex-row w-auto gap-1.5">
										<Tooltip content="Watch Video" sideOffset={8}>
											<button
												type="button"
												className="font-quagmire inline-flex justify-center items-center h-[32px] w-[32px] rounded-full border-2 border-white/30 hover:border-white text-white uppercase"
											>
												<IconPlayerPlay size={18} />
											</button>
										</Tooltip>
										<Tooltip content="Share This" sideOffset={8}>
											<button
												type="button"
												className="font-quagmire inline-flex justify-center items-center h-[32px] w-[32px] rounded-full border-2 border-white/30 hover:border-white text-white uppercase"
											>
												<IconShare3 size={18} />
											</button>
										</Tooltip>
										<Tooltip content="Bookmark This" sideOffset={8}>
											<button
												type="button"
												className="font-quagmire inline-flex justify-center items-center h-[32px] w-[32px] rounded-full border-2 border-white/30 hover:border-white text-white uppercase"
											>
												<IconBookmark size={18} />
											</button>
										</Tooltip>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-col w-full p-8 pr-0 gap-4 border-b border-white/10 last:border-b-0">
							<div className="flex flex-col w-full gap-2">
								<div className="inline-flex flex-col">
									<span className="text-xs text-white/50">Pledged of $10,000 goal</span>
									<span className="text-lg font-bold text-white">$104,404</span>
								</div>
								<div className="inline-flex flex-col">
									<span className="text-xs text-white/50">Backers </span>
									<span className="text-lg font-bold text-white">470</span>
								</div>
								<div className="inline-flex flex-col">
									<span className="text-xs text-white/50">Shipping</span>
									<span className="text-lg font-bold text-white">Anywhere in the world</span>
								</div>
								<div className="inline-flex flex-col">
									<span className="text-xs text-white/50">Estimated Delivery </span>
									<span className="text-lg font-bold text-white">Jun 2025</span>
								</div>
							</div>
						</div>
						<div className="flex flex-col w-full p-8 pr-0 gap-4 border-b border-white/10 last:border-b-0">
							<div className="flex flex-col w-full gap-3">
								<Button className="font-quagmire w-full" onClick={handleLogin} type="button" variant="gradient">
									<ButtonLabel>Pledge US$ 1</ButtonLabel>
								</Button>
								<Button className="font-quagmire w-full" onClick={handleLogin} type="button" variant="gradient">
									<ButtonLabel>Pre-order Copies</ButtonLabel>
								</Button>
							</div>
						</div>
					</div>
				</div>

				{/* footer */}
				<div className="flex flex-col w-full py-12 px-4 md:px-6 gap-4">
					<div className="flex flex-col md:flex-row justify-center items-center w-full gap-6 md:gap-12">
						<div className="inline-flex flex-row items-center w-auto gap-6 md:gap-12">
							<div className="inline-flex w-[75px] mr-2">
								<img className="block h-auto w-full" src="/svg/macroverse-logo-colored-layered.svg" alt="Macroverse" />
							</div>
							<a
								href="https://macroverse.onelink.me/VuAp/mvweb"
								className="group inline-flex p-[2px] rounded-xl bg-mvlogo-gradient shadow-lg"
							>
								<div className="relative inline-flex flex-row items-center w-auto p-2 pr-4 rounded-xl gap-2 bg-mvdark-600 overflow-hidden">
									<div className="z-[2] inline-flex w-[40px] h-[40px] rounded-lg shadow-lg bg-mvdark-900 overflow-hidden">
										<img src="/macroverse-app-icon.png" alt="" />
									</div>
									<div className="z-[2] flex flex-col w-auto">
										<span className="font-semibold text-sm text-white leading-4">Get the</span>
										<span className="font-quagmire font-bold text-md text-white uppercase leading-4">
											Macroverse App
										</span>
									</div>
									<div className="z-[1] absolute inset-0 w-full h-full bg-mvlogo-gradient opacity-0 transition-all group-hover:opacity-20 pointer-events-none" />
								</div>
							</a>
						</div>
						<div className="flex flex-row items-center w-auto gap-8">
							<div className="inline-flex flex-row items-center gap-4">
								<a href="https://www.instagram.com/macroversehq" className="font-semibold text-white">
									<IconInstagram size={24} />
								</a>
								<a href="https://x.com/macroverse" className="font-semibold text-white">
									<IconXtwitter size={24} />
								</a>
								<a href="https://www.kickstarter.com/profile/macroverse/created" className="font-semibold text-white">
									<IconKickstarter size={24} />
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-row justify-center w-full">
							<span className="text-xs text-white/50">© 2025 Macroverse Media Inc. All Rights Reserve</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
