import Skeleton from "./skeleton";

export default function UserMenuSkeleton() {
	return (
		<div className="flex flex-col w-full gap-4">
			<div className="flex flex-row justify-center items-center w-full gap-2">
				<Skeleton className="inline-flex w-12 h-12 rounded-full" animate />
				<Skeleton className="w-24 h-8 rounded-xl" animate />
			</div>
			<div className="flex flex-col w-full gap-2">
				<Skeleton className="w-full h-24 rounded-xl" animate />
				<Skeleton className="w-full h-12 rounded-xl" animate />
			</div>
			<div className="flex flex-col justify-center items-center w-full gap-2">
				<Skeleton className="w-32 h-4 rounded-xl" animate />
				<Skeleton className="w-full h-24 rounded-xl" animate />
			</div>
		</div>
	);
}
