import * as TabsPrimitive from "@radix-ui/react-tabs";
import clsx from "clsx";
import React from "react";

interface TabsGroupProps {
	children?: React.ReactNode;
	label?: string;
}

const Tabs = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Root
		ref={ref}
		className={clsx(
			"flex w-full data-[orientation=vertical]:flex-row data-[orientation=horizontal]:flex-col",
			className,
		)}
		{...props}
	/>
));
Tabs.displayName = TabsPrimitive.Root.displayName;

const TabsList = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.List
		ref={ref}
		className={clsx(
			"relative flex w-full justify-center gap-8 data-[orientation=horizontal]:flex data-[orientation=horizontal]:pb-2 data-[orientation=horizontal]:border-b data-[orientation=horizontal]:border-white/20 data-[orientation=vertical]:flex-col data-[orientation=vertical]:pr-4 data-[orientation=vertical]:border-r data-[orientation=vertical]:border-slate-200 data-[orientation=vertical]:max-w-[240px]",
			className,
		)}
		{...props}
	/>
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsGroup = ({ children, label }: TabsGroupProps) => {
	return (
		<div className="flex flex-col w-full mb-3">
			{label && (
				<span className="pl-3 mb-1.5 font-semibold text-2xs uppercase tracking-wide text-gray-300">{label}</span>
			)}
			{children}
		</div>
	);
};

const TabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Trigger
		ref={ref}
		className={clsx(
			'relative inline-flex justify-center items-center leading-tight before:content-[""] before:absolute data-[orientation=horizontal]:before:left-0 data-[orientation=horizontal]:before:right-0 data-[orientation=horizontal]:before:-bottom-2 data-[orientation=vertical]:before:-right-4 data-[orientation=horizontal]:before:-mb-[1px] data-[orientation=vertical]:before:-mr-[1px]data-[orientation=horizontal]:before:w-full data-[orientation=vertical]:before:w-[2px] data-[orientation=horizontal]:before:h-[2px] data-[orientation=vertical]:before:h-full before:opacity-0 before:pointer-events-none disabled:pointer-events-none disabled:opacity-30',
			className,
		)}
		{...props}
	/>
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Content
		ref={ref}
		className={clsx(
			"w-full ring-offset-background focus-visible:outline-none focus-visible:ring-0 data-[orientation=horizontal]:mt-4 data-[orientation=vertical]:ml-8",
			className,
		)}
		{...props}
	/>
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent, TabsGroup };
