import { IconAchievements } from "@/components/mvicons";
import type { Badge } from "@/types/badge.ts";
import clsx from "clsx";

export default function CollectionBadges({
	badges,
	displayCount,
	showPlaceholder,
}: { badges: Badge[]; displayCount: number; showPlaceholder: boolean }) {
	return (
		<div
			className={clsx(
				"flex flex-row w-full rounded-xl gap-6 p-8 bg-white bg-opacity-5",
				badges.length >= 5 && "justify-center",
				badges.length < 5 && "justify-start",
			)}
		>
			{badges.slice(0, displayCount).map((badge, index) => (
				<div key={index} className="flex justify-center items-center h-[100px] rounded-xl text-white">
					<img src={badge?.image?.original_url} alt={badge.name} className="h-full object-contain rounded-xl" />
				</div>
			))}

			{showPlaceholder && (
				<div className="flex flex-row justify-center w-full max-w-[320px] gap-3 ml-3">
					<div className="inline-flex text-white">
						<IconAchievements size={38} />
					</div>
					<div className="flex flex-col w-full gap-2">
						<span className="text-sm font-semibold text-white leading-tight line-clamp-1">Achievements Rewarded!</span>
						<span className="text-sm text-white/70 leading-tight line-clamp-2 sm:line-clamp-3 md:line-clamp-4">
							Unlock and collect special badges to show off your fandom, activity, and impeccable taste!
						</span>
					</div>
				</div>
			)}
		</div>
	);
}
