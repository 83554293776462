import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { tabletCocS19Pages } from "@/constants/tablet-coc-s19-pages.ts";
import clsx from "clsx";
import { Navigation, Pagination } from "swiper/modules";
import { TapArrowLeft, TapArrowRight } from "../mvsvg";

type ImageData = {
	src: string;
	altText: string;
};

const SwipeReader = () => {
	return (
		<div className="relative flex flex-col h-full w-full bg-black">
			{/* 1 page slide actual page viewer */}
			<div className="z-[1] flex 2xl:hidden w-full h-full bg-black">
				<Swiper
					slidesPerView="auto"
					centeredSlides={true}
					spaceBetween={0}
					allowTouchMove={true}
					pagination={{
						type: "progressbar", // Define type
						clickable: false, // Make it clickable
					}}
					navigation={{
						enabled: true,
						prevEl: ".swiper-button-prev", // Customize previous button
						nextEl: ".swiper-button-next", // Customize next button
					}}
					modules={[Pagination, Navigation]} // Include necessary modules
					className="swipe-reader w-auto h-full"
				>
					<div className="z-[50] absolute inset-0 top-1/2 -translate-y-1/2 h-[40px] flex justify-between w-full px-6 md:px-24 pointer-events-none">
						<div className="swiper-button-prev inline-flex justify-center items-center outline-none border-none ring-0 text-white hover:text-mvblue-300 pointer-events-auto cursor-pointer">
							<TapArrowLeft className="w-[24px]" />
						</div>
						<div className="swiper-button-next inline-flex justify-center items-center outline-none border-none ring-0 text-white hover:text-mvblue-300 pointer-events-auto cursor-pointer">
							<TapArrowRight className="w-[24px]" />
						</div>
					</div>

					{/* 1 page per slide */}
					{tabletCocS19Pages.map(({ src, altText }, index) => (
						<SwiperSlide key={index} className="flex justify-center items-center w-auto h-full max-w-[90%]">
							<div className="flex w-auto h-full">
								<img className="w-auto h-full object-contain" src={src} alt={altText} />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>

			{/* 2 page slide actual page viewer */}
			<div className="z-[1] hidden 2xl:flex w-full h-full bg-black">
				<Swiper
					slidesPerView="auto"
					centeredSlides={true}
					spaceBetween={0}
					allowTouchMove={true}
					pagination={{
						type: "progressbar", // Define type
						clickable: false, // Make it clickable
					}}
					navigation={{
						enabled: true,
						prevEl: ".swiper-button-prev", // Customize previous button
						nextEl: ".swiper-button-next", // Customize next button
					}}
					modules={[Pagination, Navigation]} // Include necessary modules
					className="swipe-reader w-auto h-full"
				>
					<div className="z-[50] absolute inset-0 top-1/2 -translate-y-1/2 h-[40px] flex justify-between w-full px-6 md:px-24 pointer-events-none">
						<div className="swiper-button-prev inline-flex justify-center items-center outline-none border-none ring-0 text-white hover:text-mvblue-300 pointer-events-auto cursor-pointer">
							<TapArrowLeft className="w-[24px]" />
						</div>
						<div className="swiper-button-next inline-flex justify-center items-center outline-none border-none ring-0 text-white hover:text-mvblue-300 pointer-events-auto cursor-pointer">
							<TapArrowRight className="w-[24px]" />
						</div>
					</div>

					{/* 2 page per slide */}
					{tabletCocS19Pages
						.reduce<ImageData[][]>((acc, curr, index) => {
							if (index === 0) {
								acc.push([curr]); // First slide has one image
							} else if (index % 2 === 1) {
								acc.push([curr, tabletCocS19Pages[index + 1]]); // Pair the rest
							}
							return acc;
						}, [])
						.map((pair, index) => (
							<SwiperSlide key={index} className="flex justify-center items-center w-auto h-full max-w-[90%]">
								<div className="flex w-auto h-full justify-center">
									{pair.map(
										(img, i) =>
											img && ( // Ensure the second image exists in pairs
												<img
													key={i}
													className={clsx("object-contain h-full w-auto", {
														"max-w-full": pair.length === 1,
														"max-w-1/2": pair.length !== 1,
													})}
													src={img.src}
													alt={img.altText}
												/>
											),
									)}
								</div>
							</SwiperSlide>
						))}
				</Swiper>
			</div>
		</div>
	);
};

export default SwipeReader;
