import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { IconCheck, IconCreditCard, IconPlus, IconPlusCircle } from "@/components/mvicons";
import clsx from "clsx";
import { Pagination } from "swiper/modules";

const paymentCards = [
	{
		cardNumber: "**** 281",
		cardType: "Mastercard",
		isActive: true,
	},
	{
		cardNumber: "**** 857",
		cardType: "American Express",
		isActive: false,
	},
	{
		cardNumber: "**** 914",
		cardType: "Visa",
		isActive: false,
	},
	{
		cardNumber: "**** 123",
		cardType: "Discover",
		isActive: false,
	},
	{
		cardNumber: "**** 456",
		cardType: "JCB",
		isActive: false,
	},
];

export default function SettingsCreditCardsSwiper() {
	return (
		<Swiper
			autoHeight={true}
			slidesPerView={4}
			spaceBetween={10}
			pagination={{
				enabled: false,
				clickable: true,
			}}
			modules={[Pagination]}
			className="overflow-visible w-full"
		>
			{paymentCards.map((card, index) => (
				<SwiperSlide key={index}>
					<div
						className={clsx("group flex flex-col h-[140px] w-full justify-between rounded-xl gap-4 p-8", {
							"bg-white bg-opacity-10": card.isActive,
							"bg-white bg-opacity-5 hover:bg-opacity-10": !card.isActive,
						})}
					>
						{card.isActive ? (
							<div className="absolute top-2 left-2 inline-flex flex-row items-center w-auto h-[24px] px-3 gap-1 rounded-lg bg-black bg-opacity-20 text-white">
								<IconCheck size={12} />
								<span className="text-xs">Default Card</span>
							</div>
						) : (
							<button
								type="button"
								className="absolute top-2 left-2 inline-flex flex-row items-center w-auto h-[24px] px-3 gap-1 rounded-lg bg-black bg-opacity-20 text-white opacity-0 group-hover:opacity-100"
							>
								<IconPlus size={12} />
								<span className="text-xs">Make Default</span>
							</button>
						)}
						<div className="flex flex-row justify-center items-center w-full h-full gap-4">
							<div className="inline-flex justify-center items-center text-white">
								<IconCreditCard size={32} />
							</div>
							<div className="inline-flex flex-col w-auto gap-1">
								<div className="flex flex-col w-full">
									<span className="text-sm text-white/50 leading-tight">Card Number</span>
									<span className="text-sm text-white leading-tight">{card.cardNumber}</span>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
			))}
			<SwiperSlide>
				<button
					type="button"
					className="flex flex-row justify-center items-center h-[140px] w-full gap-2 rounded-xl border-2 border-dashed border-white/5 bg-white bg-opacity-[0.025] hover:bg-opacity-[0.05] hover:bg-opacity-10 text-white p-4"
				>
					<IconPlusCircle size={24} />
					<span className="text-sm text-white leading-tight">Add New Card</span>
				</button>
			</SwiperSlide>
		</Swiper>
	);
}
