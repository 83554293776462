import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconAchievements = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 48 43"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M25.2605 12.2953C24.7881 11.334 23.2116 11.334 22.7391 12.2953L20.4595 16.9137L15.3592 17.6553C14.8318 17.7322 14.3896 18.1029 14.2248 18.6125C14.06 19.1206 14.1973 19.6795 14.5819 20.053L18.2706 23.6496L17.3972 28.7281C17.3065 29.2554 17.5236 29.7883 17.9575 30.1027C18.3915 30.42 18.9655 30.4584 19.4379 30.2099L24 27.8134L28.562 30.2099C29.0511 30.4674 29.6244 30.408 30.0424 30.1027C30.4764 29.7883 30.6933 29.2554 30.6028 28.7281L29.7294 23.6496L33.4181 20.053C33.8026 19.6795 33.9399 19.1206 33.7752 18.6125C33.6104 18.1029 33.1681 17.7322 32.6408 17.6553L27.5404 16.9137L25.2605 12.2953Z"
				fill={color}
			/>
			<path
				d="M3.43442 22.3863L1.28389 22.5772C0.51758 22.6458 -0.0537306 23.3174 0.00401923 24.0851C0.108363 25.4446 0.262206 26.535 0.479143 27.4757C0.888361 29.5425 1.9752 31.3374 3.52423 32.1353C6.41556 33.7594 8.70025 31.5034 8.98168 31.3896C9.51446 32.1714 10.078 32.8457 10.6681 33.4358C9.55018 33.8588 8.58887 34.5563 7.894 35.4627L6.49328 37.3249C6.05931 37.9016 6.13075 38.7105 6.65809 39.2008C8.49878 40.909 10.2181 42.3772 12.6016 42.3772C15.1501 42.3772 17.1145 40.6163 17.6251 38.105L17.8393 37.0914C18.0453 37.1216 18.2541 37.145 18.4656 37.1642C19.1632 37.2205 19.9185 36.6629 19.99 35.887C20.0586 35.1139 19.4874 34.4314 18.7128 34.3627C14.3166 33.9768 9.55749 29.8985 9.10534 23.2843C8.71759 18.8192 10.7835 13.6839 15.546 11.1912C16.6776 10.6968 17.6417 9.78766 18.5068 8.38141C20.3903 5.15577 19.387 3.04002 19.4901 1.50946C19.4332 0.724779 18.75 0.133406 17.952 0.206249C14.7188 0.51778 11.9129 1.35018 10.6928 4.40718C9.99793 5.99333 10.1023 7.88295 10.9675 9.45541L11.5223 10.4812C10.9263 10.9962 10.3687 11.5689 9.85787 12.1882C9.49262 11.0841 8.92403 10.1269 8.2044 9.41416L6.56472 7.82248C5.947 7.22782 4.94622 7.31276 4.44438 8.00792C3.82095 8.87313 3.16723 9.80285 2.67007 10.7874C0.72758 14.2577 1.7622 19.0028 5.52925 19.9898L6.38069 20.2219C6.25713 21.1461 6.21588 22.0841 6.2681 23.0207C5.27922 22.5222 4.41213 22.3052 3.43442 22.3863Z"
				fill={color}
			/>
			<path
				d="M37.285 4.44842C36.5654 2.65077 34.9477 1.23496 33.1103 0.765274C32.2039 0.500243 31.2178 0.323056 30.0094 0.206338C29.2018 0.130775 28.5274 0.735367 28.4714 1.50955C28.179 4.4547 28.1186 6.08023 29.4684 8.41169C30.3171 9.78634 31.2811 10.6954 32.3139 11.1404C37.2762 13.7335 39.2493 18.7737 38.8508 23.3118C38.4652 28.9097 34.6434 33.8866 29.2487 34.3627C28.4742 34.4313 27.903 35.1139 27.9716 35.887C28.0402 36.6629 28.7846 37.226 29.4959 37.1642C29.7047 37.1464 29.9134 37.1216 30.1194 37.0927L30.3309 38.0952C30.8404 40.5958 32.7761 42.3771 35.3736 42.3771C36.5629 42.3771 37.7741 41.9926 38.7849 41.262C39.6968 40.6673 40.496 39.9477 41.3007 39.202C41.8308 38.7118 41.9022 37.9001 41.4683 37.3248L40.0647 35.4626C40.062 35.4599 40.0592 35.4558 40.0592 35.4558C39.3753 34.5577 38.414 33.8601 37.2907 33.4357C37.8886 32.8338 38.4389 32.1833 38.977 31.3895C39.2625 31.5051 41.6424 33.7674 44.4843 32.1599C46.08 31.3373 47.1146 29.5424 47.5157 27.5127C47.7437 26.5061 47.8974 25.4157 47.9963 24.0809C48.0513 23.3146 47.4827 22.6445 46.7165 22.5772L44.5659 22.3863C44.5659 22.3863 44.5604 22.3863 44.555 22.3849C43.5909 22.308 42.6877 22.5208 41.6908 23.0248C41.7402 22.0869 41.6991 21.1476 41.5755 20.222C41.8597 20.0764 44.91 19.8446 45.9343 16.5471C46.5166 14.7453 46.2694 12.5385 45.3163 10.8411C44.7972 9.81391 44.171 8.91719 43.5201 8.01082C43.0089 7.30282 42.0035 7.23579 41.3997 7.82266C41.3997 7.82266 39.7518 9.41978 39.7491 9.4225C39.0323 10.1283 38.4664 11.0842 38.1011 12.1897C37.5903 11.569 37.0355 10.9977 36.4394 10.4813L36.9915 9.46506C37.8591 7.88294 37.9634 5.99332 37.285 4.44842Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconAchievements;
