import scannableService from "@/api/services/scannable-service.ts";
import { queryOptions } from "@tanstack/react-query";

export const scannableQueryOptions = (code: string, options?: { enabled: boolean }) => {
	return queryOptions({
		queryKey: ["scannables", code],
		queryFn: () => scannableService.findByCode(code),
		...options,
	});
};
