import { useApiClient } from "@/api/api-client"; // Import the custom hook
import type { User } from "@/types/user";
import { formatError } from "@/utils/helper";
import axios, { AxiosError } from "axios";
import { type ReactNode, createContext, useContext, useEffect, useReducer } from "react";

export type UserContextType = AuthState & {
	csrf: () => Promise<void>;
	fetchProfile: () => Promise<User>;
	sync: ({ token }: { token: string }) => Promise<User>;
};

type AuthState = {
	user: User | null;
	isLoading: boolean;
	error: Error | null;
};

type AuthAction =
	| { type: "REQUEST" }
	| { type: "SUCCESS"; payload: User }
	| { type: "FAILURE"; payload: AxiosError }
	| { type: "INVALID_CREDENTIALS"; payload: AxiosError }
	| { type: "LOGOUT" }
	| { type: "IDLE" };

const initialState = {
	user: null,
	isLoading: true,
	error: null,
};

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
	switch (action.type) {
		case "REQUEST":
			return { ...state, isLoading: true, error: null };
		case "SUCCESS":
			return { ...state, isLoading: false, user: action.payload, error: null };
		case "INVALID_CREDENTIALS":
			return { ...state, isLoading: false, error: action.payload };
		case "FAILURE":
			return { ...state, isLoading: false, error: action.payload };
		case "LOGOUT":
			return { ...initialState };
		case "IDLE":
			return { ...state, isLoading: false };
		default:
			return state;
	}
};

const baseUrl = (import.meta.env.VITE_PUBLIC_API_BASE_URL || "").replace(/\/api$/, "");

const baseClient = axios.create({
	baseURL: baseUrl,
	headers: {
		"X-Requested-With": "XMLHttpRequest",
		"Content-Type": "application/json",
		Accept: "application/json",
	},
	timeout: 1000 * 15,
	withCredentials: true,
	withXSRFToken: true,
});

const UserContext = createContext<UserContextType | null>(null);

const UserProvider = ({ children }: { children: ReactNode }) => {
	const apiClient = useApiClient();

	const [state, dispatch] = useReducer(authReducer, initialState);

	const fetchProfile = async () => {
		try {
			const response = await apiClient.get<{ data: User }>("/profile");

			dispatch({ type: "SUCCESS", payload: response.data.data });
			return response.data.data;
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				if (error.response.status !== 409) throw error;
				dispatch({ type: "FAILURE", payload: formatError(error) });
			}

			throw new Error("Failed to fetch user profile");
		}
	};

	const sync = async ({
		token,
	}: {
		token: string;
	}) => {
		try {
			const response = await apiClient.post<{ data: User }>("/profile/sync", {
				token,
			});

			dispatch({ type: "SUCCESS", payload: response.data.data });
			return response.data.data;
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				if (error.response.status !== 409) throw error;
				dispatch({ type: "FAILURE", payload: formatError(error) });
			}
			throw new Error("Failed to fetch user profile");
		}
	};

	const csrf = async () => {
		try {
			await baseClient.get("/auth/csrf");
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				dispatch({ type: "INVALID_CREDENTIALS", payload: formatError(error) });
				throw error;
			}
			throw new Error("An error has occurred and we're working to fix the problem! We'll be up and running shortly.");
		}
	};

	useEffect(() => {
		const checkAuthStatus = async () => {
			dispatch({ type: "REQUEST" });

			await fetchProfile();
		};

		checkAuthStatus();
	}, []);

	const contextValue: UserContextType = {
		...state,
		csrf,
		sync,
		fetchProfile,
	};

	return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

const useUser = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("useUser must be used within an UserProvider");
	}
	return context;
};

export { UserProvider, useUser };
