import { apiClient } from "@/api/api-client.ts";

const supplyService = {
	claimSupply: async (payload: {
		code: string;
	}) => {
		const response = await apiClient.post<{ data: Issue }>("/claim_supply", payload);

		return response.data.data;
	},
};

export default supplyService;
