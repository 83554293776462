import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconUser = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M15 0C12.0333 0 9.13319 0.879734 6.66645 2.52796C4.19971 4.17618 2.27713 6.51885 1.14181 9.25974C0.00649926 12.0006 -0.290551 15.0166 0.288227 17.9263C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16394 29.133 12.0736 29.7118C14.9834 30.2905 17.9994 29.9935 20.7403 28.8582C23.4811 27.7229 25.8238 25.8003 27.472 23.3335C29.1203 20.8668 30 17.9667 30 15C30 11.0217 28.4196 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM15 5.8065C16.0526 5.8065 17.0815 6.11863 17.9567 6.70342C18.8319 7.2882 19.5141 8.11939 19.9169 9.09186C20.3197 10.0643 20.4251 11.1344 20.2197 12.1668C20.0144 13.1991 19.5075 14.1474 18.7632 14.8917C18.0189 15.636 17.0706 16.1429 16.0383 16.3482C15.0059 16.5536 13.9358 16.4482 12.9634 16.0454C11.9909 15.6426 11.1597 14.9604 10.5749 14.0852C9.99013 13.21 9.678 12.1811 9.678 11.1285C9.67781 10.4295 9.81532 9.73726 10.0827 9.09139C10.3501 8.44553 10.742 7.85866 11.2363 7.36431C11.7305 6.86995 12.3172 6.4778 12.963 6.21025C13.6088 5.94271 14.301 5.8065 15 5.8065ZM15 26.6055C13.3118 26.6047 11.6442 26.2351 10.1137 25.5226C8.58323 24.8101 7.22688 23.7719 6.1395 22.4805C6.70964 21.3927 7.56575 20.4811 8.61563 19.8438C9.6655 19.2065 10.8694 18.8677 12.0975 18.864C12.243 18.8648 12.3875 18.887 12.5265 18.93C13.3236 19.2006 14.1583 19.3439 15 19.3545C15.8413 19.3468 16.6761 19.206 17.4735 18.9375C17.6125 18.8945 17.757 18.8723 17.9025 18.8715C19.1307 18.8752 20.3345 19.214 21.3844 19.8513C22.4343 20.4886 23.2904 21.4002 23.8605 22.488C22.7724 23.778 21.4157 24.8148 19.8853 25.526C18.3549 26.2372 16.6876 26.6056 15 26.6055Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconUser;
