import * as ScrollArea from "@radix-ui/react-scroll-area";
import clsx from "clsx";

type ScrollerProps = {
	width?: number;
	height?: number;
	children: React.ReactNode;
	className?: string;
};

const Scroller = ({ height, width, children, className }: ScrollerProps) => (
	<ScrollArea.Root
		className={clsx("overflow-hidden", className)}
		style={{ height: `${height}px`, maxWidth: `${width}px` }}
	>
		<ScrollArea.Viewport className="w-full h-full">{children}</ScrollArea.Viewport>
		<ScrollArea.Scrollbar
			className="flex select-none touch-none bg-white/0 transition-colors duration-[160ms] ease-out hover:bg-black/0 data-[orientation=vertical]:w-1 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
			orientation="vertical"
		>
			<ScrollArea.Thumb className="flex-1 bg-white/70 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
		</ScrollArea.Scrollbar>
		{width && (
			<ScrollArea.Scrollbar
				className="flex select-none touch-none p-[3px] bg-white/0 transition-colors duration-[160ms] ease-out hover:bg-black/0 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
				orientation="horizontal"
			>
				<ScrollArea.Thumb className="flex-1 bg-white rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
			</ScrollArea.Scrollbar>
		)}
		<ScrollArea.Corner className="bg-black/30" />
	</ScrollArea.Root>
);

export default Scroller;
