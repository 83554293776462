import { apiClient } from "@/api/api-client.ts";
import type { Reward } from "@/types/reward.ts";

const rewardService = {
	getRewardStatus: async () => {
		const response = await apiClient.get<{ data: Reward }>("/reward_status");

		return response.data.data;
	},
	claimReward: async () => {
		const response = await apiClient.post<Reward>("/reward_claim");

		return response.data;
	},
};

export default rewardService;
