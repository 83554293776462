import { activitiesQueryOptions } from "@/api/queries/activity-queries.ts";
import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import { ActivityItem } from "@/components/activities/activity-item.tsx";
import { IconActivity } from "@/components/mvicons";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_profile/activities")({
	loader: ({ context: { queryClient } }) => {
		return queryClient.ensureQueryData(activitiesQueryOptions());
	},
	component: Page,
});

function Page() {
	const profileQuery = useSuspenseQuery(profileQueryOptions());
	const profile = profileQuery.data;
	const activityQuery = useSuspenseQuery(activitiesQueryOptions());
	const activities = activityQuery.data;

	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-2">
				<div className="flex flex-row items-center gap-2">
					<span className="text-sm font-semibold text-white">Real Time Updates</span>
				</div>
				<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
					<div className="inline-flex text-white">
						<IconActivity size={38} />
					</div>
					<div className="flex flex-col w-full gap-2">
						<span className="text-sm font-semibold text-white leading-tight">Traveling the ‘verse.</span>
						<span className="text-sm text-white/70 leading-tight">
							As you explore, engage, participate and collect you can see a record of all your travels here. Sooo handy
							when you need to find that perfect thing from last Tuesday.
						</span>
					</div>
				</div>
				{activities.data.length !== 0 && (
					<div className="flex flex-col w-full rounded-xl divide-y divide-white/10 py-3 px-6 bg-white bg-opacity-5">
						{activities?.data.map((activity) => (
							<ActivityItem key={activity.id} activity={activity} profile={profile} />
						))}
					</div>
				)}
			</div>
		</div>
	);
}
