import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconMessage = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 31 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M30.5878 14.1665C30.5937 16.3666 30.0798 18.5369 29.0878 20.5007C27.9121 22.8538 26.1044 24.833 23.8672 26.2167C21.6301 27.6004 19.0519 28.3339 16.4214 28.335C14.2213 28.3409 12.051 27.8269 10.0872 26.835L0.587891 30L3.75503 20.5007C2.76312 18.5369 2.24916 16.3666 2.25503 14.1665C2.25595 11.5362 2.98912 8.95817 4.37241 6.72106C5.75571 4.48394 7.7345 2.67612 10.0872 1.50005C12.051 0.508138 14.2213 -0.00581783 16.4214 4.96798e-05H17.255C20.728 0.193866 24.0078 1.66079 26.4675 4.12042C28.9271 6.58005 30.394 9.85985 30.5878 13.3329V14.1665Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconMessage;
