import supplyService from "@/api/services/supply-service.ts";
import { queryClient } from "@/query-client.ts";
import { queryOptions, useMutation } from "@tanstack/react-query";

export const supplyQueryOptions = (id: string, options?: { enabled: boolean }) => {
	return queryOptions({
		queryKey: ["supplies", id],
		queryFn: () => supplyService.claimSupply(id),
		...options,
	});
};

export const useSupplyClaimMutation = () => {
	return useMutation({
		mutationKey: ["supplies"],
		mutationFn: supplyService.claimSupply,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["supplies"] });
			queryClient.invalidateQueries({ queryKey: ["collections"] });
			queryClient.invalidateQueries({ queryKey: ["profile"] });
		},
	});
};
