import { Slot } from "@radix-ui/react-slot";
import { IconLoader3 } from "@tabler/icons-react";
import clsx from "clsx";
import React, { createContext, useContext } from "react";

type ButtonContextType = {
	variant?: ButtonVariant;
	disabled?: boolean;
	isLoading?: boolean;
	color: ButtonColors;
	gradient: ButtonGradientColors;
	mode: ButtonModes;
	size: ButtonSizes;
};

type ButtonColors = "default" | "red" | "blue" | "white";
type ButtonModes = "default" | "icon";
type ButtonSizes = "xs" | "sm" | "md" | "lg";
type ButtonGradientColors = "default" | "accounts" | "collect" | "bonus";
type ButtonVariant = "default" | "outline" | "light" | "unstyled" | "gradient" | "shadow";

type ButtonProps = {
	asChild?: boolean;
	children?: React.ReactNode;
	className?: string;
	type?: "button" | "submit";
	disabled?: boolean;
	isLoading?: boolean;
	color?: ButtonColors;
	mode?: ButtonModes;
	size?: ButtonSizes;
	variant?: ButtonVariant;
	gradient?: ButtonGradientColors;
	onClick?: () => void;
};

type ButtonLabelProps = {
	children?: React.ReactNode;
	className?: string;
	isLoading?: boolean;
};

type ButtonIconProps = {
	children?: React.ReactNode;
	className?: string;
};

type ButtonBadgeProps = {
	children?: React.ReactNode;
	className?: string;
};

const ButtonContext = createContext<ButtonContextType>({
	disabled: false,
	isLoading: false,
	color: "default",
	mode: "default",
	size: "md",
	variant: "default",
	gradient: "default",
});

const buttonFilledColors = {
	default: "bg-mvgray-800 hover:bg-mvgray-700 border-mvgray-800 hover:border-mvgray-700 text-white",
	red: "bg-mvred-600 hover:bg-mvred-500 border-mvred-600 hover:border-mvred-500 text-black",
	blue: "bg-mvblue-300 hover:bg-mvblue-200 border-mvblue-300 hover:border-mvblue-200 text-black",
	white: "bg-white border-white hover:opacity-90 text-black",
};

const buttonOutlineColors = {
	default:
		"bg-transparent hover:bg-mvgray-600 border-mvgray-600 hover:border-mvgray-600 text-white/50 hover:text-black",
	red: "bg-transparent hover:bg-mvred-500 border-mvred-600 hover:border-mvred-600 text-mvred-600 hover:text-black",
	blue: "bg-transparent hover:bg-mvblue-300 border-mvblue-300 hover:border-mvblue-300 text-mvblue-300 hover:text-black",
	white: "bg-transparent hover:bg-white border-white hover:border-white text-white hover:text-mvdark-950",
};

const buttonLightColors = {
	default: "bg-mvgray-500 hover:bg-mvgray-400 border-mvgray-500 hover:border-mvgray-400 text-white",
	red: "bg-mvred-600 hover:bg-mvred-500 border-mvred-600 hover:border-mvred-500 text-white",
	blue: "bg-mvblue-300 hover:bg-mvblue-200 border-mvblue-300 hover:border-mvblue-200 text-white",
	white: "bg-mvblue-300 hover:bg-mvblue-200 border-mvblue-300 hover:border-mvblue-200 text-white",
};

const buttonUnstyledColors = {
	default: "bg-transparent border-transparent text-white/50 hover:text-white",
	red: "bg-transparent border-transparent text-mvred-600/70 hover:text-mvred-600",
	blue: "bg-transparent border-transparent text-mvblue-300/70 hover:text-mvblue-300",
	white: "bg-transparent border-transparent text-mvblue-300/70 hover:text-mvblue-300",
};

const buttonGradientColors = {
	default: "mv-gradient-button mv-gradient-button--default border-none text-white",
	accounts: "mv-gradient-button mv-gradient-button--accounts border-none text-white",
	collect: "mv-gradient-button mv-gradient-button--collect border-none text-white",
	bonus: "mv-gradient-button mv-gradient-button--bonus border-none text-white",
};

const disabledClassName = "bg-mvdark-600 border-mvdark-600 text-white/50 cursor-not-allowed pointer-events-none";

const buttonModes = {
	default: {
		xs: "rounded-full before:rounded-full h-[18px] min-h-[18px] max-h-[18px] leading-[10px] pb-[1px] px-[10px] text-[10px]",
		sm: "rounded-[10px] before:rounded-[10px] h-[24px] min-h-[24px] max-h-[24px] leading-[16px] pb-[1px] px-[12px] text-[14px]",
		md: "rounded-[14px] before:rounded-[14px] h-[40px] min-h-[40px] max-h-[40px] leading-[22px] gap-[2px] pb-[1px] px-[18px] text-[22px]",
		lg: "rounded-[14px] sm:rounded-[22px] before:rounded-[14px] sm:before:rounded-[22px] h-[38px] sm:h-[54px] min-h-[38px]sm:min-h-[54px] max-h-[38px] sm:max-h-[54px] leading-[22px] sm:leading-[28px] gap-[2px] pb-[1px] px-[18px] sm:px-[24px] text-[22px] sm:text-[28px]",
	},
	icon: {
		xs: "w-[18px] min-w-[18px] max-w-[18px] h-[18px] min-h-[18px] max-h-[18px] leading-[12px] px-0 text-[12px]",
		sm: "w-[24px] min-w-[24px] max-w-[24px] h-[24px] min-h-[24px] max-h-[24px] leading-[16px] px-0 text-[16px]",
		md: "w-[38px] min-w-[38px] max-w-[38px] h-[38px] min-h-[38px] max-h-[38px] leading-[22px] px-0 text-[22px]",
		lg: "w-[38px] sm:w-[54px] min-w-[38px] sm:min-w-[54px] max-w-[38px] sm:max-w-[54px] h-[38px] sm:h-[54px] min-h-[38px] sm:min-h-[54px] max-h-[38px] sm:max-h-[54px] leading-[28px] px-0 text-[28px]",
	},
};

const loaderSizes = {
	xs: 12,
	sm: 18,
	md: 22,
	lg: 28,
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			asChild = false,
			type = "button",
			children,
			className,
			disabled,
			isLoading,
			color = "default",
			mode = "default",
			size = "md",
			variant = "default",
			gradient = "default",
			...props
		},
		ref,
	) => {
		const Comp = asChild ? Slot : "button";
		const loaderSize = loaderSizes[size];
		const buttonContent = isLoading ? (
			<>
				<ButtonIcon className="mr-1">
					<IconLoader3 className="animate-spin" size={loaderSize} stroke={2} />
				</ButtonIcon>
				<ButtonLabel>Submitting</ButtonLabel>
			</>
		) : (
			children
		);

		return (
			<ButtonContext.Provider
				value={{
					disabled,
					isLoading,
					color,
					mode,
					size,
					variant,
					gradient,
				}}
			>
				{variant === "shadow" ? (
					<div className={clsx("group relative inline-flex w-auto !p-0", buttonModes[mode][size])}>
						<Comp
							ref={ref}
							type={type}
							className={clsx(
								"z-[1] inline-flex flex-row items-center justify-center self-start before:hidden gap-1 border-2 border-white bg-white text-mvdark-950",
								buttonModes[mode][size],
								{ [disabledClassName]: disabled || isLoading },
								className,
							)}
							{...props}
						>
							{buttonContent}
						</Comp>
						<div
							className={clsx(
								"absolute inset-0 w-full h-full transition-all bg-mvred-600 translate-x-[-1px] translate-y-[-1px] group-hover:translate-x-[-3px] group-hover:translate-y-[-2px]",
								buttonModes[mode][size],
							)}
						/>
						<div
							className={clsx(
								"absolute inset-0 w-full h-full transition-all bg-mvblue-300 translate-x-[2px] translate-y-[2px] group-hover:translate-x-[4px] group-hover:translate-y-[3px]",
								buttonModes[mode][size],
							)}
						/>
					</div>
				) : (
					<Comp
						ref={ref}
						type={type}
						className={clsx(
							"inline-flex flex-row items-center justify-center self-start gap-1 border-2",
							buttonModes[mode][size],
							{
								"before:hidden": variant !== "gradient",
							},
							{
								[disabledClassName]: disabled || isLoading,
								[buttonFilledColors[color]]: variant === "default" && !(disabled || isLoading),
								[buttonOutlineColors[color]]: variant === "outline" && !(disabled || isLoading),
								[buttonLightColors[color]]: variant === "light" && !(disabled || isLoading),
								[buttonUnstyledColors[color]]: variant === "unstyled" && !(disabled || isLoading),
								[buttonGradientColors[gradient]]: variant === "gradient" && !(disabled || isLoading),
							},
							className,
						)}
						{...props}
					>
						{buttonContent}
					</Comp>
				)}
			</ButtonContext.Provider>
		);
	},
);

const ButtonLabel = ({ children, className }: ButtonLabelProps) => {
	return <span className={clsx("inline-flex text-inherit", className)}>{children}</span>;
};

const ButtonIcon = ({ children, className }: ButtonIconProps) => {
	return <span className={clsx("inline-flex text-inherit", className)}>{children}</span>;
};

const ButtonBadge = ({ children, className }: ButtonBadgeProps) => {
	return <span className={clsx("inline-flex text-inherit", className)}>{children}</span>;
};

export { Button, ButtonLabel, ButtonIcon, ButtonBadge };
