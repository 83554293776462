import { Input } from "@/components/core/forms/input.tsx";
import { Slot } from "@radix-ui/react-slot";
import { IconAlertTriangle, IconAsteriskSimple } from "@tabler/icons-react";
import clsx from "clsx";
import omit from "lodash/omit";
import React from "react";
import {
	Controller,
	type ControllerProps,
	type FieldPath,
	type FieldValues,
	FormProvider,
	useFormContext,
} from "react-hook-form";
import { NumericFormat } from "react-number-format";

const Form = FormProvider;

type FormFieldContextValue<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
	name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

type FormItemContextValue = {
	id: string;
	layout?: "inline" | "default"; // Add the layout property
};

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

const useFormField = () => {
	const fieldContext = React.useContext(FormFieldContext);
	const itemContext = React.useContext(FormItemContext);
	const { getFieldState, formState } = useFormContext();

	const fieldState = getFieldState(fieldContext.name, formState);

	if (!fieldContext) {
		throw new Error("useFormField should be used within <FormField>");
	}

	const { id } = itemContext;

	return {
		id,
		name: fieldContext.name,
		formItemId: `${id}-form-item`,
		formDescriptionId: `${id}-form-item-description`,
		formMessageId: `${id}-form-item-message`,
		...fieldState,
	};
};

/**
 * Form Field
 */
const FormField = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	...props
}: ControllerProps<TFieldValues, TName>) => {
	return (
		<FormFieldContext.Provider value={{ name: props.name }}>
			<Controller {...props} />
		</FormFieldContext.Provider>
	);
};

const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
	({ ...props }, ref) => {
		const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

		return (
			<Slot
				ref={ref}
				id={formItemId}
				aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
				aria-invalid={!!error}
				{...props}
			/>
		);
	},
);
FormControl.displayName = "FormControl";

/**
 * FormItem
 */
const FormItem = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & { layout?: "inline" | "default" } // Add layout prop
>(({ className, layout = "default", ...props }, ref) => {
	const id = React.useId();

	return (
		<FormItemContext.Provider value={{ id, layout }}>
			<div
				ref={ref}
				className={clsx(
					"flex w-full ",
					{
						"flex-row pb-2 gap-2 border-b border-white/30 focus-within:border-mvblue-300": layout === "inline",
						"flex-col gap-1": layout === "default",
					},
					className,
				)}
				{...props}
			/>
		</FormItemContext.Provider>
	);
});
FormItem.displayName = "FormItem";

/**
 * FormMessage
 */
const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, children, ...props }, ref) => {
		const { error, formMessageId } = useFormField();
		const body = error ? String(error?.message) : children;

		if (!body) {
			return null;
		}

		return (
			<div
				ref={ref}
				id={formMessageId}
				className={clsx("flex flex-row items-center w-full gap-1 text-mvred-600", className)}
				{...props}
			>
				{body}
			</div>
		);
	},
);
FormMessage.displayName = "FormMessage";

/**
 * FormDescription
 */
const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, ...props }, ref) => {
		const { formDescriptionId } = useFormField();

		return (
			<p
				ref={ref}
				id={formDescriptionId}
				className={clsx("text-sm text-white/50 leading-tight", className)}
				{...props}
			/>
		);
	},
);
FormDescription.displayName = "FormDescription";

/**
 * FormLabel
 */
const FormLabel = React.forwardRef<
	HTMLLabelElement,
	React.HTMLAttributes<HTMLLabelElement> & {
		required?: boolean;
		hideOptional?: boolean;
	}
>(({ className, ...props }, ref) => {
	const { error, formItemId } = useFormField();
	const { required = false } = props;
	const { layout } = React.useContext(FormItemContext); // Get the layout value
	const labelProps = omit(props, ["required", "hideOptional"]);

	return (
		<div className="flex flex-row items-center gap-1">
			{/* biome-ignore lint/a11y/noLabelWithoutControl: <This is handled on the component using this> */}
			<label
				ref={ref}
				htmlFor={formItemId}
				className={clsx(
					"text-sm text-white/50 font-medium leading-tight",
					{
						"whitespace-nowrap": layout === "inline",
						"whitespace-wrap": layout === "default",
					},
					{
						"text-red-600": error,
					},
					className,
				)}
				{...labelProps}
			/>
			{required && (
				<span className="text-sm text-red-600 leading-tight">
					<IconAsteriskSimple size={8} stroke={3} />
				</span>
			)}
		</div>
	);
});
FormLabel.displayName = "FormLabel";

/**
 * FormGroup
 */
interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
	inputSize?: "sm" | "md" | "lg";
	inputStyle?: "default" | "outline" | "unstyled";
	hasIcon?: boolean;
}

const FormGroup = React.forwardRef<HTMLDivElement, FormGroupProps>(
	({ className, children, inputSize = "lg", inputStyle = "default", ...props }, ref) => {
		const { error } = useFormField();
		const { layout } = React.useContext(FormItemContext); // Get the layout value
		const hasIcon = React.Children.toArray(children).some(
			(child) => React.isValidElement(child) && child.type === FormIcon,
		);

		const input = React.Children.map(children, (child) => {
			if (React.isValidElement(child) && (child.type === Input || child.type === NumericFormat)) {
				return React.cloneElement(child as React.ReactElement<any>, {
					className: clsx(
						"block w-full outline-none focus:ring-0 focus:outline-0",
						{
							"min-h-[28px] h-[28px] leading-[28px] py-0 px-3 text-sm": inputSize === "sm" && inputStyle === "default",
							"min-h-[40px] h-[40px] leading-[40px] py-0 px-5 text-md": inputSize === "md" && inputStyle === "default",
							"min-h-[40px] sm:min-h-[56px] leading-[40px] sm:leading-[56px] py-0 px-5 sm:px-8 text-md":
								inputSize === "lg" && inputStyle === "default",
							"min-h-[18px] h-[18px] leading-[18px] py-0 text-sm": inputSize === "sm" && inputStyle === "outline",
							"min-h-[30px] h-[30px] leading-[30px] py-0 text-md": inputSize === "md" && inputStyle === "outline",
							"min-h-[44px] sm:min-h-[44px] leading-[44px] sm:leading-[44px] py-0 text-md sm:text-lg":
								inputSize === "lg" && inputStyle === "outline",
							"p-0 text-sm": inputSize === "sm" && inputStyle === "unstyled",
							"p-0 text-md": inputSize === "md" && inputStyle === "unstyled",
							"p-0 text-lg": inputSize === "lg" && inputStyle === "unstyled",
						},
						{
							"rounded-full": inputStyle === "default",
							"rounded-none": inputStyle === "outline" || inputStyle === "unstyled",
						},
						{
							"bg-white border-2 border-white focus:border-2 focus:border-mvblue-300 font-normal placeholder-gray-400 text-gray-600":
								inputStyle === "default",
							"bg-transparent border-b-2 border-white/30 focus:border-b-mvblue-300 font-normal placeholder-white/40 text-white":
								inputStyle === "outline",
							"bg-transparent font-normal placeholder-white/40 text-white": inputStyle === "unstyled",
						},
						{
							"border-2 !border-mvred-600 placeholder-mvred-600 !text-mvred-600": inputStyle === "default" && error,
							"border-b-2 !border-mvred-600 placeholder-mvred-600 !text-mvred-600": inputStyle === "outline" && error,
						},
						{
							"pl-10": hasIcon,
						},
						child.props.className,
					),
				});
			}
			return child;
		});

		return (
			<div ref={ref} className={clsx("relative flex flex-row w-full", className)}>
				<div className="flex flex-row items-start grow shrink w-full" {...props}>
					{input}
				</div>
			</div>
		);
	},
);
FormGroup.displayName = "FormGroup";

/**
 * FormIcon
 */
const FormIcon = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		return (
			<div ref={ref} className={clsx("absolute top-1/2 left-3 -translate-y-1/2 text-gray-400", className)} {...props} />
		);
	},
);
FormIcon.displayName = "FormIcon";

export {
	useFormField,
	Form,
	FormControl,
	FormDescription,
	FormItem,
	FormIcon,
	FormMessage,
	FormField,
	FormGroup,
	FormLabel,
};
