import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { questQueryOptions, useQuestClaimMutation } from "@/api/queries/quest-queries.ts";
import { rewardQueryOptions, useRewardClaimMutation } from "@/api/queries/reward-queries.ts";
import { ExpContext } from "@/components/exp-gain/exp-gain-context.tsx";
import { ExpTimer } from "@/components/exp-timer.tsx";
import SingleQuestCard from "@/components/single-quest-card.tsx";
import ExpCardSkeleton from "@/components/skeletons/exp-card-skeleton";
import QuestCardSkeleton from "@/components/skeletons/quest-card-skeleton";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useContext } from "react";
import { Pagination } from "swiper/modules";

export default function QuestBoard() {
	const questQuery = useQuery(
		questQueryOptions({
			include: "media",
		}),
	);
	const rewardQuery = useQuery(rewardQueryOptions());
	const { triggerAnimation } = useContext(ExpContext);
	const rewardMutation = useRewardClaimMutation();
	const quests = questQuery.data;
	const rewardStatus = rewardQuery.data;

	const questClaimMutation = useQuestClaimMutation();
	const navigate = useNavigate({
		from: "/overview",
	});
	const isLoading = questQuery.isLoading;

	return (
		<div className="flex flex-col w-full gap-2">
			<div className="flex flex-row items-center gap-2">
				<span className="text-sm font-semibold text-white">Perks and Rewards</span>
			</div>
			<div className="flex flex-row w-full gap-4 overflow-visible">
				<Swiper
					slidesPerView="auto"
					spaceBetween={10}
					pagination={{
						enabled: false,
						clickable: true,
					}}
					modules={[Pagination]}
					className="overflow-visible w-full quest-board-swiper"
				>
					{rewardQuery.isFetching ? (
						<SwiperSlide className="flex min-w-[260px] w-[260px] max-w-[260px] min-h-[300px]">
							<ExpCardSkeleton />
						</SwiperSlide>
					) : (
						<SwiperSlide className="flex min-w-[260px] w-[260px] max-w-[260px] min-h-[300px]">
							<div className="flex flex-1 flex-col min-w-[260px] w-[260px] max-w-[260px] min-h-[300px] h-full rounded-4xl bg-white bg-opacity-5 text-white/50">
								{rewardStatus && (
									<ExpTimer
										onClaim={async () => {
											triggerAnimation();

											await rewardMutation.mutateAsync();
										}}
										duration={rewardStatus?.next_claim_duration}
									/>
								)}
							</div>
						</SwiperSlide>
					)}

					{quests?.data?.map((quest) =>
						isLoading ? (
							<SwiperSlide key={quest.id} className="flex min-w-[260px] w-[260px] max-w-[260px] min-h-[300px]">
								<QuestCardSkeleton />
							</SwiperSlide>
						) : (
							<SwiperSlide key={quest.id} className=" min-w-[260px] w-[260px] max-w-[260px]">
								<SingleQuestCard
									quest={quest}
									onClaim={async (quest) => {
										await questClaimMutation.mutateAsync({ quest_id: quest.id });
										triggerAnimation();
									}}
									onAction={async (quest) => {
										await navigate({
											to: quest.metadata.actionLink,
										});
									}}
								/>
							</SwiperSlide>
						),
					)}
				</Swiper>
			</div>
		</div>
	);
}
