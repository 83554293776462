import { queryClient } from "@/query-client.ts";
import { routeTree } from "@/route-tree.gen.ts";
import { QueryClientProvider } from "@tanstack/react-query";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/app.css";
import { ExpProvider } from "@/components/exp-gain/exp-gain-context.tsx";
import * as Sentry from "@sentry/react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { UserProvider } from "@/hooks/use-auth.tsx";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const sentryDsn = import.meta.env.VITE_PUBLIC_SENTRY_DSN;

const router = createRouter({
	routeTree,
	context: {
		queryClient,
		auth: undefined!,
	},
	notFoundMode: "root",
	defaultPreload: "intent",
	defaultPreloadStaleTime: 0,
});

if (sentryDsn) {
	Sentry.init({
		dsn: import.meta.env.VITE_PUBLIC_SENTRY_DSN,
		integrations: [
			Sentry.tanstackRouterBrowserTracingIntegration(router),
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

function Router() {
	const auth = useAuth0();

	return <RouterProvider context={{ auth }} router={router} />;
}

function App() {
	const handleRedirectCallback = (appState: {
		returnTo: string;
	}) => {
		window.location.href = appState?.returnTo || "/overview";
	};

	return (
		<Auth0Provider
			domain={import.meta.env.VITE_AUTH0_DOMAIN}
			clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
			cacheLocation="localstorage"
			useRefreshTokens
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: `https://${import.meta.env.VITE_AUTH0_DOMAIN}/api/v2/`,
				scope: "openid profile email offline_access",
			}}
			onRedirectCallback={handleRedirectCallback}
		>
			<QueryClientProvider client={queryClient}>
				<ExpProvider>
					<UserProvider>
						<Router />
					</UserProvider>
				</ExpProvider>
				{import.meta.env.VITE_PUBLIC_ENV === "local" && <ReactQueryDevtools initialIsOpen={false} />}
			</QueryClientProvider>
		</Auth0Provider>
	);
}

const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);
}
