import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import Scroller from "@/components/core/display/scroller.tsx";
import { IconQrcode } from "@/components/mvicons";
import IconCheckCircle from "@/components/mvicons/icon-check-circle.tsx";
import type { Collection } from "@/types/collection.ts";
import { formatNumber } from "@/utils/format-number.ts";
import { macronautCollectionsLink } from "@/utils/macronaut-link.ts";
import { canNativeShare, nativeShare } from "@/utils/social-share.ts";
import { IconX } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Button, ButtonLabel } from "../core/buttons/button.tsx";
import { Avatar, AvatarImage } from "../core/display/avatar.tsx";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../core/navigations/tabs.tsx";
import { IconChevronRight, IconCollectionAdd, IconCollectionSecure, IconExp, IconShare } from "../mvicons";

function CollectionPreview({
	collection,
	isOpen,
	onClose,
}: {
	collection: Collection;
	isOpen: boolean;
	onClose: () => void;
}) {
	const profileQuery = useQuery(profileQueryOptions());
	const profile = profileQuery.data;

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
		return () => {
			document.body.style.overflow = "";
		};
	}, [isOpen]);

	const handleCloseWalkthrough = () => {
		onClose();
		document.body.style.overflow = "";
	};

	const tabTriggerClasses = "py-3 text-white/50 hover:text-white data-[state=active]:text-mvblue-300";

	const isCurrent = false;

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className={clsx(
						"z-50 fixed inset-0 font-aller flex items-center justify-center min-h-[100svh] w-full overflow-hidden",
					)}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* walkthrough gradient border */}
					<div className="z-[1] absolute gradient-border inset-0 blur-[30px] pointer-events-none" />

					{/* walkthrough overlay image */}
					<div
						className="z-[1] fixed inset-0 w-full h-full bg-cover bg-center bg-no-repeat opacity-10  pointer-events-none"
						style={{
							backgroundImage: "url(/scannables/scanner-bg-default.jpg)",
							filter: "grayscale(30%)",
						}}
					/>

					{/* walkthrough ui overlay */}
					<motion.div
						className="absolute inset-0 h-full w-full bg-black pointer-events-none"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* walkthrough topbar */}
					<div className="z-[10] fixed top-0 left-0 right-0 flex flex-row items-center justify-between w-full p-4 gap-4 bg-gradient-to-b from-black/90 to-transparent pointer-events-none">
						<div className="grid grid-cols-3 w-full">
							<div className="col-span-1 flex flex-row justify-start">
								{/* close button */}
								<button
									onClick={handleCloseWalkthrough}
									type="button"
									className="inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none ring-0 border-none bg-white/10 hover:bg-white/20 backdrop-blur text-white pointer-events-auto"
								>
									<IconX size={28} stroke={2} />
								</button>
							</div>
							<div className="col-span-1 flex flex-row items-center justify-center">
								<div className="inline-flex flex-row w-auto gap-2">
									<div className="inline-flex h-[32px]">
										<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
									</div>
									<div className="inline-flex h-[32px]">
										<img src="/svg/authentic-editions.svg" alt="Macroverse" />
									</div>
								</div>
							</div>
							<div className="col-span-1 flex flex-row justify-end">
								<div className="inline-flex flex-row items-center w-auto gap-2">
									<div className="inline-flex flex-row items-center w-auto gap-1 text-white">
										<IconExp size={24} />
										<span className="text-xs">{formatNumber(profile?.total_exp, "0,0")}</span>
									</div>
									<Avatar className="w-[32px] h-[32px] rounded-full border-2 border-white bg-mvclaimcard-gradient-a pointer-events-auto">
										<AvatarImage src={profile?.avatar_url} />
										{!profile?.avatar_url && <AvatarImage src="/macronaut-avatar.png" />}
									</Avatar>
								</div>
							</div>
						</div>
					</div>

					{/* walkthrough ui container */}
					<motion.div
						className="relative flex flex-col lg:justify-center items-center h-full w-full pt-16 pb-8 overflow-y-auto"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* main walkthrough display */}
						<div className="z-[5] relative flex flex-col w-full max-w-[480px] md:max-w-[1024px]">
							<div className="flex flex-col w-full p-4 gap-8">
								<div className="flex flex-row items-center justify-center gap-4">
									<div className="flex w-full h-[1px] border-t border-white/20" />
									<div className="inline-flex shrink-0 h-[50px]">
										<img src="/svg/precode-horror.svg" alt="Macroverse" />
									</div>
									<div className="flex w-full h-[1px] border-t border-white/20" />
								</div>
								<div className="flex flex-col md:flex-row w-full gap-8">
									<div className="flex flex-col self-center shrink-0 w-full md:w-[360px] gap-2">
										<div className="relative flex w-full h-auto border-2 border-white/10">
											<img className="z-[2] block w-full" src={collection.cover_image.original_url} alt="Macroverse" />
											<motion.div
												className="z-[1] absolute inset-0 rounded-full bg-mvmain-gradient blur-2xl"
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												transition={{
													duration: 1,
													delay: 1,
													ease: "easeInOut",
												}}
												style={{
													animation: "spinAndScale 4s infinite linear",
												}}
											/>
										</div>
										{collection.is_owned && (
											<div className="flex flex-row justify-center items-center p-3 gap-2 rounded-lg bg-mvblue-300/20 backdrop-blur">
												<div className="flex justify-center items-center flex-row shrink-0 w-[44px] h-[44px] rounded-sm bg-white">
													<IconQrcode className="text-black" size={36} />
												</div>
												<div className="flex flex-col gap-1">
													<div className="flex flex-row items-center gap-1">
														<IconCheckCircle className="text-mvblue-300" size={16} />
														<span className="text-xs text-mvblue-300">Digital Twin Paired</span>
													</div>
													<div className="flex flex-row items-center gap-1 p-1 px-2 rounded-md bg-white/20">
														<span className="text-xs text-white leading-tight">#000001</span>
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="flex flex-col w-full divide-y divide-white/20">
										<div className="flex flex-row w-full gap-4">
											<div className="relative flex flex-col w-full pb-4">
												<span className="font-bold text-lg text-white">{collection.name}</span>
												<span className="text-sm text-white">
													Season {collection.season} | Issue #{collection.issue_number}
												</span>
												<span className="text-xs text-white/50">1st Printing | January 2025</span>
											</div>
											{/*<div className="inline-flex self-start flex-col justify-center items-center p-2 gap-1 rounded-md bg-white/10 text-white">*/}
											{/*	<IconExp size={24} />*/}
											{/*	<span className="font-semibold text-xs leading-tight">+10</span>*/}
											{/*</div>*/}
										</div>
										<div className="flex flex-col w-full py-4 gap-2">
											<p className="text-sm text-white">
												{collection.excerpt ||
													"The story data for this issue hasn’t fully decoded yet. Our archivists are working to unlock its secrets. Check back soon to uncover what lies within…"}
											</p>
										</div>
										<div className="flex flex-col w-full">
											<Tabs defaultValue="collaborators">
												<TabsList className="!justify-start !py-0 !gap-3">
													<TabsTrigger value="collaborators" className={tabTriggerClasses}>
														<span className="text-sm">Collaborators</span>
													</TabsTrigger>
													<TabsTrigger value="story" className={tabTriggerClasses}>
														<span className="text-sm">Story</span>
													</TabsTrigger>
													<TabsTrigger value="characters" className={tabTriggerClasses}>
														<span className="text-sm">Characters</span>
													</TabsTrigger>
												</TabsList>
												<TabsContent value="collaborators">
													<Scroller className="pr-4 h-auto md:h-[340px]">
														<div className="flex flex-col w-full gap-2">
															<p className="text-white">
																Short Synopsis Text that gives a bit of context or flavor about this issue. It can be as
																long as we need/want it to be or it can also be blank which would have it not show and
																you’d just go straight to the full issue info below.
															</p>
														</div>
													</Scroller>
												</TabsContent>
												<TabsContent value="story">
													<Scroller className="pr-4 h-auto md:h-[340px]">
														<div className="flex flex-col w-full gap-2 text-sm">
															<span className="text-sm text-white">{collection.name}</span>
															{collection.description && (
																<div
																	// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
																	dangerouslySetInnerHTML={{
																		__html:
																			collection.description ||
																			"The story data for this issue hasn’t fully decoded yet. Our archivists are working to unlock its secrets. Check back soon to uncover what lies within…",
																	}}
																	className="flex flex-col w-full gap-2 text-white"
																/>
															)}
														</div>
													</Scroller>
												</TabsContent>
												<TabsContent value="characters">
													<Scroller className="pr-4 h-auto md:h-[340px]">
														<div className="flex flex-col w-full gap-2">
															<p className="text-white">
																Short Synopsis Text that gives a bit of context or flavor about this issue. It can be as
																long as we need/want it to be or it can also be blank which would have it not show and
																you’d just go straight to the full issue info below.
															</p>
														</div>
													</Scroller>
												</TabsContent>
											</Tabs>
										</div>
									</div>
								</div>
								<div className="flex flex-row items-center justify-center gap-4">
									<div className="flex w-full h-[1px] border-t border-white/20" />
								</div>
								<div className="flex flex-col md:flex-row items-center justify-center w-full gap-3">
									{canNativeShare && (
										<Button
											className="font-quagmire !gap-1.5 backdrop-blur uppercase w-full !shrink"
											type="button"
											variant={isCurrent ? "gradient" : "outline"}
											size="md"
											color="blue"
											onClick={() => {
												nativeShare({
													title: "Macroverse",
													text: "Check out this collection from Macroverse!",
													url: macronautCollectionsLink((profile?.username || profile?.macronaut_id) ?? ""),
												});
											}}
										>
											<IconShare className="translate-y-[1px]" size={16} />
											<ButtonLabel>Share</ButtonLabel>
										</Button>
									)}

									<Button
										disabled
										className="font-quagmire !gap-1.5 backdrop-blur uppercase w-full !shrink"
										type="button"
										variant={isCurrent ? "gradient" : "outline"}
										size="md"
										color="blue"
									>
										<IconCollectionAdd className="translate-y-[1px]" size={16} />
										<ButtonLabel>Buy Now</ButtonLabel>
									</Button>
									<Button
										disabled
										className="font-quagmire !gap-1.5 backdrop-blur uppercase w-full !shrink"
										type="button"
										variant={isCurrent ? "gradient" : "outline"}
										size="md"
										color="blue"
									>
										<IconCollectionSecure className="translate-y-[1px]" size={16} />
										<ButtonLabel>Secure</ButtonLabel>
									</Button>
									<Button
										disabled
										className="font-quagmire !gap-1.5 backdrop-blur uppercase w-full !shrink"
										type="button"
										variant={isCurrent ? "gradient" : "outline"}
										size="md"
										color="blue"
									>
										<IconChevronRight className="translate-y-[1px]" size={9} stroke={12} />
										<ButtonLabel>Read</ButtonLabel>
									</Button>
								</div>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
}

export default CollectionPreview;
