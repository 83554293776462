import {
	IconChevronRight,
	IconCollectionPair,
	IconCollectionSecured,
	IconCollections,
	IconPlus,
	IconQrcode,
} from "@/components/mvicons";
import Reader from "@/components/reader/reader.tsx";
import ScanModal from "@/components/scanner/scanner.tsx";
import CollectionsSkeleton from "@/components/skeletons/collections-skeleton";
import { useScreenWidth } from "@/hooks/use-screen-width.ts";
import type { Collection } from "@/types/collection.ts";
import type { User } from "@/types/user.ts";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import type React from "react";
import { Suspense, useState } from "react";
import Masonry from "react-masonry-css";
import CollectionPreview from "./collection-preview.tsx";

function ItemPlaceholder({
	onClick,
}: {
	onClick?: () => void;
}) {
	return (
		<div className="relative flex grow-0 w-auto max-w-[260px] md:max-w-[180px]">
			<button
				onClick={onClick}
				type="button"
				className="z-[2] absolute inset-0 flex flex-1 shrink-0 flex-col justify-center items-center w-full h-full rounded-xl gap-4 p-2 md:p-4 bg-white bg-opacity-5 hover:bg-opacity-10 border-2 border-white/20 border-dashed transition-all"
			>
				<IconQrcode className="text-white" size={32} />
				<span className="max-w-[180px] text-center text-xs sm:text-sm font-semibold text-white leading-tight">
					Scan QR Code and redeem digital twin and rewards!
				</span>
			</button>
			<img className="z-[1] opacity-0 block w-full h-auto" src="/collection-cover-placeholder.jpg" alt="" />
		</div>
	);
}

function GuidelinePlaceholder({ className }: { className?: string }) {
	return (
		<div className={clsx("flex flex-row min-w-[320px] w-full rounded-xl gap-4 p-8 bg-white bg-opacity-5", className)}>
			<div className="inline-flex text-white">
				<IconCollections size={38} />
			</div>
			<div className="flex flex-col w-full gap-3">
				<span className="text-sm font-semibold text-white leading-tight">Your Grails and Prizes</span>
				<span className="text-sm text-white/70 leading-tight line-clamp-4">
					As you collect comics your collection will grow here. Over time you’ll receive additional collectibles, bonus
					content and rewards and any of those that are collectible will also show up here.
				</span>
				<span className="text-sm text-white/70 leading-tight line-clamp-3">
					This is your personal collection. Each item is yours and all kinds of awesome will land here as you explore
					the Macroverse and participate across it.
				</span>
			</div>
		</div>
	);
}

type CollectionButtonProps = {
	children: React.ReactNode;
	className?: string;
	icon: React.ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
	onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
};

function CollectionButton({ children, className, icon, onClick, onMouseEnter, onMouseLeave }: CollectionButtonProps) {
	const [isHovered, setIsHovered] = useState(false);
	const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
		setIsHovered(true);
		if (onMouseEnter) onMouseEnter(e);
	};
	const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
		setIsHovered(false);
		if (onMouseLeave) onMouseLeave(e);
	};

	return (
		<AnimatePresence mode="wait">
			<motion.button
				type="button"
				className={clsx(
					"inline-flex justify-center items-center rounded-md bg-white/20 backdrop-blur text-white transition h-[44px]",
					className,
				)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onClick}
				animate={{ width: isHovered ? "auto" : "44px" }}
				transition={{ duration: 0.3, ease: "easeOut" }}
			>
				<div className="inline-flex flex-row justify-center items-center p-2 gap-1.5 overflow-hidden">
					{icon}
					<AnimatePresence>
						{isHovered && (
							<motion.span
								className="font-quagmire inline-flex font-bold text-md uppercase"
								initial={{ opacity: 0, width: 0 }}
								animate={{ opacity: 1, width: "auto" }}
								exit={{ opacity: 0, width: 0 }}
								transition={{ duration: 0.3, ease: "easeOut" }}
							>
								{children}
							</motion.span>
						)}
					</AnimatePresence>
				</div>
			</motion.button>
		</AnimatePresence>
	);
}

export function Collections({
	user,
	collections,
	showAllItems,
}: {
	user?: User;
	collections: Collection[];
	showAllItems: boolean;
}) {
	// This is just to simulate the counters, make sure your collection has atleast 3 items in the database
	// visit the macroverse-admin to access this module, just replace the second parameter in slice
	const [isWalkthroughOpen, setWalkthroughOpen] = useState(false);
	const [isScanOpen, setScanOpen] = useState(false);
	const [isReaderOpen, setReaderOpen] = useState(false);
	const screenWidth = useScreenWidth();

	const isMobile = screenWidth < 640;
	const isTablet = screenWidth > 640 && screenWidth < 768;

	const getSlotCount = () => {
		if (screenWidth < 640) return 3; // Mobile
		if (screenWidth < 768) return 4; // Tablet
		return 5; // Desktop
	};

	const filteredItems = showAllItems ? collections : collections?.filter((collection) => collection.is_owned);

	const slotCount = getSlotCount();
	const mtCol = filteredItems; // For Masonry
	const tCol = filteredItems.slice(0, slotCount); // For Dummy

	const notAvailableAnimation = {
		initial: { opacity: 0, scale: 0.7 },
		animate: { opacity: 1, scale: 1 },
		exit: { opacity: 0, scale: 0.7 },
		transition: { duration: 0.3 },
	};

	const breakpointColumnsObj = {
		default: 5,
		1024: 5,
		768: 4,
		640: 3,
	};

	const showMasonry =
		(isMobile && filteredItems.length >= 3) ||
		(isTablet && filteredItems.length >= 4) ||
		(!isMobile && !isTablet && filteredItems.length >= 5);

	const [isSecured, setIsSecured] = useState(false);
	const handleSecureCollection = () => {
		setIsSecured((prevState) => !prevState);
		alert("Collection item secured!");
	};

	const handleOpenReader = () => {
		setReaderOpen(true);
	};
	const handleOpenDetails = (issue: Issue) => {
		setWalkthroughOpen(issue);
	};

	return (
		<Suspense fallback={<CollectionsSkeleton />}>
			{isWalkthroughOpen && (
				<CollectionPreview
					collection={isWalkthroughOpen}
					isOpen={isWalkthroughOpen}
					onClose={() => setWalkthroughOpen(false)}
				/>
			)}
			{isReaderOpen && <Reader isOpen={isReaderOpen} onClose={() => setReaderOpen(false)} />}
			{isScanOpen && <ScanModal isAuth={!!user} isOpen={isScanOpen} onClose={() => setScanOpen(false)} />}

			{showMasonry ? (
				<div className="flex flex-col w-full">
					<AnimatePresence>
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
						>
							{mtCol.map((filteredItem) => (
								<motion.button
									key={filteredItem.id}
									onClick={() => {
										handleOpenDetails(filteredItem);
									}}
									className={`relative group inline-flex bg-white bg-opacity-5 rounded-xl ${filteredItem.is_owned ? "grayscale-0" : "grayscale"}`}
									initial={filteredItem.is_owned ? undefined : notAvailableAnimation.initial}
									animate={filteredItem.is_owned ? undefined : notAvailableAnimation.animate}
									exit={filteredItem.is_owned ? undefined : notAvailableAnimation.exit}
									transition={filteredItem.is_owned ? undefined : notAvailableAnimation.transition}
									layout
								>
									<div className="z-[2] absolute inset-0 flex flex-col w-full h-full p-2 gap-2 bg-black/50 transition-all opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
										{filteredItem.is_owned ? (
											<>
												{isSecured ? (
													<>
														<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
															<IconCollectionSecured size={14} />
															<span className="text-xs font-semibold uppercase">Secured</span>
														</div>
														<CollectionButton
															onClick={handleOpenReader}
															className="self-end justify-end mt-auto ml-auto"
															icon={<IconChevronRight size={18} stroke={12} />}
														>
															Read
														</CollectionButton>
													</>
												) : (
													<>
														<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
															<IconCollectionPair size={14} />
															<span className="text-xs font-semibold uppercase">Paired</span>
														</div>
														{/*<CollectionButton
															onClick={handleSecureCollection}
															className="self-end justify-end mt-auto ml-auto"
															icon={<IconCollectionSecure size={32} />}
														>
															Secure
														</CollectionButton>*/}
													</>
												)}
											</>
										) : (
											<>
												<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
													<IconPlus size={isMobile ? 10 : 12} />
													<span className="text-[10px] sm:text-xs font-semibold uppercase">Available Now</span>
												</div>
												<CollectionButton
													onClick={(e) => {
														e.stopPropagation();
														setScanOpen(true);
													}}
													className="self-end justify-end mt-auto ml-auto"
													icon={<IconQrcode size={32} />}
												>
													Pair
												</CollectionButton>
											</>
										)}
									</div>

									{filteredItem?.cover_image?.original_url ? (
										<img
											className="z-[1] opacity-100 block w-full h-auto"
											src={filteredItem?.cover_image?.original_url}
											alt="Cover"
											onError={(e) => {
												(e.target as HTMLImageElement).src = "/collection-cover-placeholder.jpg";
												(e.target as HTMLImageElement).className = "rounded-lg";
											}}
										/>
									) : (
										<img
											className="z-[1] opacity-100 block w-full h-auto"
											src="/collection-cover-placeholder.jpg"
											alt="Cover"
										/>
									)}
								</motion.button>
							))}
						</Masonry>
					</AnimatePresence>
				</div>
			) : (
				<div className="flex flex-col w-full gap-[4px]">
					{/*<button*/}
					{/*	onClick={handleOpenWalkthrough}*/}
					{/*	type="button"*/}
					{/*	className="text-white border border-white/20 mb-4 p-4 rounded-full"*/}
					{/*>*/}
					{/*	Show Issue Details*/}
					{/*</button>*/}
					<div
						className={clsx(
							"grid w-full gap-[4px] auto-cols-fr",
							isMobile && "grid-cols-3",
							isTablet && "grid-cols-4",
							!isMobile && !isTablet && "grid-cols-5",
						)}
					>
						{tCol.map((filteredItem) => (
							<button
								onClick={() => {
									console.log("fire");
									handleOpenDetails(filteredItem);
								}}
								type="button"
								key={filteredItem.id}
								className={clsx("relative group flex shrink grow-0 w-auto bg-white bg-opacity-5 rounded-xl", [
									filteredItem.is_owned ? "grayscale-0" : "grayscale",
								])}
							>
								<div className="z-[2] absolute inset-0 flex flex-col w-full h-full p-2 gap-2 bg-black/50 transition-all opacity-0 group-hover:opacity-100 group-hover:pointer-events-auto">
									{filteredItem.is_owned ? (
										<>
											{isSecured ? (
												<>
													<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
														<IconCollectionSecured size={14} />
														<span className="text-xs font-semibold uppercase">Secured</span>
													</div>
													<CollectionButton
														onClick={handleOpenReader}
														className="self-end justify-end mt-auto ml-auto"
														icon={<IconChevronRight size={16} stroke={8} />}
													>
														Read
													</CollectionButton>
												</>
											) : (
												<>
													<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
														<IconCollectionPair size={14} />
														<span className="text-xs font-semibold uppercase">Paired</span>
													</div>
													{/*<CollectionButton*/}
													{/*	onClick={handleSecureCollection}*/}
													{/*	className="self-end justify-end mt-auto ml-auto"*/}
													{/*	icon={<IconCollectionSecure size={32} />}*/}
													{/*>*/}
													{/*	Secure*/}
													{/*</CollectionButton>*/}
												</>
											)}
										</>
									) : (
										<>
											<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
												<IconPlus size={12} />
												<span className="text-xs font-semibold uppercase">Available Now</span>
											</div>
											<CollectionButton
												onClick={(e) => {
													e.stopPropagation();
													setScanOpen(true);
												}}
												className="self-end justify-end mt-auto ml-auto"
												icon={<IconQrcode size={32} />}
											>
												Pair
											</CollectionButton>
										</>
									)}
								</div>

								{filteredItem?.cover_image?.original_url ? (
									<img
										className="z-[1] opacity-100 block w-full h-auto"
										src={filteredItem?.cover_image?.original_url}
										alt={filteredItem.name}
										onError={(e) => {
											(e.target as HTMLImageElement).src = "/collection-cover-placeholder.jpg";
											(e.target as HTMLImageElement).className = "rounded-lg";
										}}
									/>
								) : (
									<img
										className="z-[1] opacity-100 block w-full h-auto"
										src="/collection-cover-placeholder.jpg"
										alt={"Macroverse"}
									/>
								)}
							</button>
						))}

						{Array.from({ length: slotCount }).map((_, index) => {
							const placeholderCount = slotCount - tCol.length;

							if (index < placeholderCount - (!isMobile && !isTablet && tCol.length <= 1 ? 3 : 0)) {
								return (
									<ItemPlaceholder
										onClick={() => {
											setScanOpen(true);
										}}
										key={`placeholder-${index}`}
									/>
								);
							}

							if (tCol.length <= 1 && index === placeholderCount - 3) {
								return (
									<GuidelinePlaceholder
										key="guideline"
										className={clsx(index === placeholderCount - 3 && "col-span-3")}
									/>
								);
							}

							return null;
						})}
					</div>

					<div className="flex md:hidden w-full">
						<GuidelinePlaceholder key="guideline" />
					</div>
				</div>
			)}

			{/*<div className="flex flex-row items-center justify-center w-full p-4 gap-2 rounded-xl bg-white bg-opacity-5 text-white/70">*/}
			{/*	<IconLoader3 className="animate-spin" size={18} stroke={2} />*/}
			{/*	<span className="">Loading Assets</span>*/}
			{/*</div>*/}
		</Suspense>
	);
}
