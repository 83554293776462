import { IconAchievements } from "@/components/mvicons";
import type { Badge } from "@/types/badge.ts";
import Scroller from "./core/display/scroller";

export default function BadgeModal({ badges }: { badges: Badge[] }) {
	return (
		<div className="flex flex-col justify-center items-center w-full gap-6">
			<div className="flex flex-col w-full max-w-[520px]">
				<div className="flex flex-col justify-center items-center w-full gap-2">
					<div className="inline-flex flex-row items-center gap-4 text-white">
						<IconAchievements size={28} />
						<span className="text-xl text-white">Your Badges</span>
					</div>
					<div className="flex flex-col justify-center items-center w-full">
						<span className="font-quagmire text-center font-bold text-2xl text-white uppercase leading-8">
							Learn Macroverse...
						</span>
						<span className="font-quagmire font-bold text-2xl text-mvred-600 uppercase  leading-8">& earn badges!</span>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-full px-6">
				{badges.length === 0 && (
					<div className="flex flex-row justify-center lg:justify-start w-full rounded-xl gap-6 p-4 bg-white bg-opacity-5">
						<div className="flex flex-row justify-center w-full gap-3 ml-3">
							<div className="inline-flex text-white">
								<IconAchievements size={38} />
							</div>
							<div className="flex flex-col w-full gap-3">
								<span className="text-sm font-semibold text-white leading-tight">Achievements Rewarded!</span>
								<span className="text-sm text-white/70 leading-tight line-clamp-4">
									Unlock and collect special badges to show off your fandom, activity, and impeccable taste!
								</span>
							</div>
						</div>
					</div>
				)}

				<div className="flex flex-col w-full divide-y divide-white/10">
					{badges.map((badge) => (
						<div
							key={badge.id}
							className="flex flex-row items-center w-full gap-4 p-3 hover:bg-white hover:bg-opacity-5"
						>
							<div className="inline-flex shrink-0 self-start h-[70px] w-[70px] text-white">
								<img src={badge.image.original_url} alt={badge.name} className="w-full object-contain rounded-xl" />
							</div>
							<div className="flex flex-col w-full max-w-[560px] gap-1">
								<span className="text-md font-semibold text-white leading-tight">{badge.name}</span>
								<span className="text-sm text-white/70 leading-tight line-clamp-4">{badge.description}</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
