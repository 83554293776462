import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconTap = ({ size = 24, color = "currentColor", title = "mv-icon", className, ...props }: IconProps) => (
	<div
		className={clsx("inline-flex shrink-0 justify-center items-center", className)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				opacity="0.998"
				d="M13.3296 32C12.5339 32 11.7711 31.6837 11.2085 31.1211C10.6459 30.5585 10.3296 29.7956 10.3296 29V3C10.3296 2.20435 10.6459 1.44152 11.2085 0.878906C11.7711 0.316297 12.5339 0 13.3296 0H29.0098C29.8054 0 30.5687 0.316297 31.1313 0.878906C31.694 1.44152 32.0098 2.20435 32.0098 3V29.0098C32.0098 29.8054 31.694 30.5687 31.1313 31.1313C30.5687 31.694 29.8054 32.0098 29.0098 32.0098L13.3296 32ZM17.1299 11.5532L21.562 15.9849L17.1299 20.417L20.1558 23.4429L24.5879 19.0112L27.6138 15.9849L24.5879 12.959L20.1558 8.52686L17.1299 11.5532ZM7.98193 32C7.18628 32 6.42296 31.6837 5.86035 31.1211C5.29774 30.5585 4.98193 29.7956 4.98193 29V3C4.98193 2.20435 5.29774 1.44152 5.86035 0.878906C6.42296 0.316297 7.18628 0 7.98193 0H9.99902C9.20337 0 8.44005 0.316297 7.87744 0.878906C7.31483 1.44152 6.99902 2.20435 6.99902 3V29.0098C6.99902 29.8054 7.31483 30.5687 7.87744 31.1313C8.44005 31.694 9.20337 32.0098 9.99902 32.0098L7.98193 32ZM3 32C2.20435 32 1.44103 31.6837 0.878418 31.1211C0.315809 30.5585 0 29.7956 0 29V3C0 2.20435 0.315809 1.44152 0.878418 0.878906C1.44103 0.316297 2.20435 0 3 0H4.98584C4.19627 0.00921226 3.44227 0.3295 2.88721 0.891113C2.33215 1.45273 2.02094 2.21038 2.021 3V29.0098C2.021 29.8054 2.3368 30.5687 2.89941 31.1313C3.46202 31.694 4.22535 32.0098 5.021 32.0098L3 32Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconTap;
