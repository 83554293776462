import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { IconComic, IconExp, IconGift, IconMacronaut, IconQrcode, IconVrs, IconWallet } from "@/components/mvicons";
import type { Quest } from "@/types/quest.ts";
import clsx from "clsx";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import type React from "react";
import { type ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import useMeasure from "react-use-measure";
import "react-lazy-load-image-component/src/effects/blur.css";

const QuestCardOverlay = ({
	children,
	handleQuestCardPop,
}: {
	children: ReactNode;
	handleQuestCardPop: () => void;
}) => {
	const handleKeyPress = (e) => {
		if (e.key === "Enter" || e.key === " ") {
			handleQuestCardPop();
		}
	};

	return ReactDOM.createPortal(
		<motion.div
			onClick={handleQuestCardPop}
			onKeyDown={handleKeyPress} // Adds keyboard interaction
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{
				delay: 0,
				duration: 0.3,
			}}
			className="z-50 fixed inset-0 flex justify-center items-center h-full w-full bg-mvdark-950/60 backdrop-blur-sm"
		>
			{children}
		</motion.div>,
		document.body, // renders the overlay into the body element
	);
};

/* card exp animation content */
function QuestRewardGain() {
	return (
		<div className="z-[2] quest-reward-gain inline-flex flex-col justify-center items-center text-white pointer-events-none">
			<IconExp className="z-[2]" size={100} />
			<span className="z-[2] text-3xl">+100</span>
			<div className="z-[1] absolute inset-0 w-full h-full rounded-full bg-mvmain-gradient blur-2xl opacity-80" />
		</div>
	);
}

const questsIcons: Record<string, JSX.Element> = {
	macronaut: <IconMacronaut className="text-white" size={28} />,
	wallet: <IconWallet className="text-white" size={28} />,
	comic: <IconComic className="text-white" size={28} />,
	qr: <IconQrcode className="text-white" size={28} />,
};

const QuestCard = ({
	handleRewardClaim,
	handleQuestCardPop,
	isActive,
	quest,
}: {
	quest: Quest;
	isActive: boolean;
	handleRewardClaim: (e: React.MouseEvent<HTMLButtonElement>) => void;
	handleQuestCardPop: () => void;
}) => {
	const [showQuestDescription, setShowQuestDescription] = useState(false);
	const [ref, { height }] = useMeasure();

	useEffect(() => {
		if (isActive) {
			const timeout = setTimeout(() => {
				setShowQuestDescription(true);
			}, 300);

			return () => clearTimeout(timeout);
		}
	}, [isActive]);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter" || event.key === " ") {
			event.preventDefault(); // Prevent scrolling if Space is pressed
			handleQuestCardPop();
		}
	};

	return (
		<div
			onClick={handleQuestCardPop}
			onKeyDown={handleKeyPress}
			className="quest-card-wrapper quest-card-wrapper--active flex flex-col w-full cursor-pointer"
		>
			<QuestRewardGain />
			<div
				className={clsx("quest-card relative", {
					"quest-card--disabled": !quest.claimed_at,
					"quest-card--default": quest.is_claimable && quest.category === "Macroverse",
					"quest-card--accounts": quest.is_claimable && quest.category === "Accounts",
					"quest-card--collect": quest.is_claimable && quest.category === "Collect",
					"quest-card--bonus": quest.is_claimable && quest.category === "Bonus",
				})}
			>
				<div className="flex flex-col w-full rounded-[28px] overflow-hidden">
					<div className="flex shrink-0 flex-col w-full h-auto rounded-t-3xl p-[5px] pb-[4px] bg-mvdark-950/80 overflow-hidden">
						<motion.div
							className="relative flex flex-col items-center justify-center min-w-[250px] w-full h-full rounded-t-3xl bg-purple-600 overflow-hidden"
							initial={{ height: 220 }}
							animate={{ height: isActive ? 340 : 220 }}
							exit={{ height: 220 }}
							transition={{ duration: 0.3 }}
						>
							<div className="z-[2] absolute inset-0 flex flex-col w-full rounded-t-3xl p-3 gap-4 bg-gradient-to-b from-black/80 via-black/30 to-black/90">
								<div className="flex flex-row items-start justify-between w-full gap-4">
									<div className="inline-flex">
										{questsIcons[quest?.metadata ? quest?.metadata?.card.icon : "macronaut"]}
									</div>
								</div>
								<div
									className={clsx("flex flex-col w-full mt-auto mb-1", {
										"gap-2": isActive,
										"gap-0": !isActive,
									})}
								>
									<span className="font-bold text-xl text-white leading-7">{quest.title}</span>
									<AnimatePresence mode="wait">
										<motion.div className="overflow-hidden" animate={{ height }}>
											{isActive && showQuestDescription && (
												<span ref={ref} className="inline-flex text-sm text-white leading-tight">
													{quest.description}
												</span>
											)}
										</motion.div>
									</AnimatePresence>
								</div>
							</div>
							{quest.image ? (
								<motion.img
									src={quest.image.original_url}
									alt="Macroverse"
									className={clsx("w-full h-full object-cover", {
										"grayscale-0": quest.is_claimable,
										"grayscale-50": !quest.is_claimable,
									})}
									onError={(e) => {
										(e.target as HTMLImageElement).src = "/quest-card-image-placeholder.jpg";
									}}
								/>
							) : (
								<motion.img
									src="/quest-card-image-placeholder.jpg"
									alt="Macroverse"
									className={clsx("w-full h-full object-cover", {
										"grayscale-0": quest.is_claimable,
										"grayscale-50": !quest.is_claimable,
									})}
								/>
							)}
						</motion.div>
					</div>
					<div className="flex shrink-0 flex-row justify-center min-h-[55px] w-full py-2 px-3 gap-4 bg-mvdark-950/60">
						{quest?.rewards?.exp && (
							<div className="inline-flex items-center flex-col w-auto gap-1">
								<IconExp className="text-white" size={20} />
								<span className="text-3xs text-white">{quest.rewards.exp}</span>
							</div>
						)}
						{quest?.rewards?.vrs && (
							<div className="inline-flex items-center flex-col w-auto gap-1">
								<IconVrs className="text-white" size={20} />
								<span className="text-3xs text-white">{quest.rewards.vrs}</span>
							</div>
						)}
						{quest?.rewards?.__unknown_gift && (
							<div className="inline-flex items-center flex-col w-auto gap-1">
								<IconGift className="text-white" size={20} />
								<span className="text-3xs text-white">{quest.rewards.__unknown_gift}</span>
							</div>
						)}
					</div>
					<div className="flex shrink-0 flex-row justify-center w-full p-3 bg-mvdark-950/40">
						<Button
							onClick={(e) => {
								e.stopPropagation();
								handleRewardClaim(e);
							}}
							className="font-quagmire w-full max-w-[160px] bg-mvdark-950/60 uppercase"
							type="button"
							size="md"
							variant="gradient"
							gradient={clsx({
								default: quest.category === "Macroverse",
								accounts: quest.category === "Accounts",
								collect: quest.category === "Collect",
								bonus: quest.category === "Bonus",
							})}
						>
							<ButtonLabel>{quest.is_claimable ? "Claim" : "Do It"}</ButtonLabel>
						</Button>
					</div>
				</div>
				{/* card gradient background */}
				<div
					className={clsx("z-[-1] absolute inset-0 rounded-[28px]", {
						"opacity-70": quest.is_claimable,
						"opacity-30": !quest.is_claimable,
						"bg-mv-gradient-default": quest.category === "Macroverse",
						"bg-mv-gradient-accounts": quest.category === "Accounts",
						"bg-mv-gradient-collect": quest.category === "Collect",
						"bg-mv-gradient-bonus": quest.category === "Bonus",
					})}
				/>
				{quest.is_claimable && (
					<div
						className={clsx(
							"z-[-1] absolute inset-0 rounded-full  blur-2xl transition-all duration-4000 animate-spinAndScale",
							{
								"bg-mv-gradient-default": quest.category === "Macroverse",
								"bg-mv-gradient-accounts": quest.category === "Accounts",
								"bg-mv-gradient-collect": quest.category === "Collect",
								"bg-mv-gradient-bonus": quest.category === "Bonus",
							},
						)}
					/>
				)}
			</div>
		</div>
	);
};

export default function SingleQuestCard({
	quest,
	onClaim,
	onAction,
}: {
	quest: Quest;
	onClaim?: (quest: Quest) => void;
	onAction?: (quest: Quest) => void;
}) {
	const [isQuestCardActive, setIsQuestCardActive] = useState(false);

	const handleQuestCardPop = () => {
		setIsQuestCardActive((prev) => !prev);
	};

	const handleClaimables = () => {
		const questCard = document.querySelector(`#quest-card-item-${quest.id} .quest-card`);
		const questRewardGain = document.querySelector(`#quest-card-item-${quest.id} .quest-reward-gain`);

		questCard?.classList.add("quest-card--animate");
		questRewardGain?.classList.add("quest-reward-gain--animate");

		setTimeout(() => {
			questCard?.classList.remove("quest-card--animate");
			questRewardGain?.classList.remove("quest-reward-gain--animate");

			setIsQuestCardActive(false);

			onClaim?.(quest);
		}, 1000);
	};

	const handleRewardClaim = () => {
		if (quest.is_claimable) {
			handleClaimables();
		} else {
			onAction?.(quest);
		}
	};

	return (
		<LayoutGroup>
			{!isQuestCardActive && (
				<motion.div id={`quest-card-item-${quest.id}`} layoutId={quest.id} className="flex">
					<QuestCard
						handleRewardClaim={handleRewardClaim}
						handleQuestCardPop={handleQuestCardPop}
						isActive={isQuestCardActive}
						quest={quest}
					/>
				</motion.div>
			)}

			<AnimatePresence initial={false} mode="wait">
				{isQuestCardActive && (
					<QuestCardOverlay handleQuestCardPop={handleQuestCardPop}>
						<motion.div layoutId={quest.id} animate={{ width: 340 }} className="flex">
							<QuestCard
								handleRewardClaim={handleRewardClaim}
								handleQuestCardPop={handleQuestCardPop}
								isActive={isQuestCardActive}
								quest={quest}
							/>
						</motion.div>
					</QuestCardOverlay>
				)}
			</AnimatePresence>
		</LayoutGroup>
	);
}
