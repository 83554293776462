export default function RedirectModal() {
	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col justify-center items-center w-full max-w-[380px] gap-6">
				<div className="">
					<img src="/svg/macroverse-logo-colored-layered.svg" alt="Macroverse" />
				</div>
				<div className="flex flex-col justify-center items-center w-full gap-3">
					<span className="font-quagmire text-center uppercase font-bold text-2xl text-white leading-8">
						Transmission Failed!
					</span>
				</div>
				<div className="flex flex-col justify-center items-center w-full gap-4">
					<p className="text-center text-sm text-white leading-6">
						We're so excited to welcome you aboard — but it looks like our ship is currently at full capacity. <br />
						<br />🚀 Don't worry though, a fresh batch of seats is launching soon! You’ll be redirected to the main page
						in a moment. Hang tight — we’ll be ready for you soon.
					</p>
				</div>
			</div>
		</div>
	);
}
