import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { IconInstagram, IconKickstarter, IconXtwitter } from "@/components/mvicons";
import SupportModal from "@/components/support-modal.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";

export const Route = createFileRoute("/terms-of-use")({
	component: Page,
});

function Page() {
	const modal = useModal();
	const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";
	const { loginWithRedirect } = useAuth0();

	const handleLogin = async () => {
		await loginWithRedirect();
	};

	return (
		<div
			className={clsx(
				"font-aller relative flex flex-col w-full bg-mvdark-950",
				isProduction ? "min-h-[100svh]" : "min-h-[calc(100svh-2rem)]",
			)}
		>
			<div
				className={clsx(
					"z-[3] fixed left-0 right-0 flex flex-row items-center justify-between w-full gap-4 p-4 md:p-6 bg-gradient-to-b from-mvdark-950/80 to-transparent",
					isProduction ? "top-0" : "top-8",
				)}
			>
				<div className="inline-flex h-[44px]">
					<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
				</div>
				<div className="inline-flex flex-row w-auto gap-2">
					<Button
						className="font-quagmire backdrop-blur uppercase"
						onClick={handleLogin}
						type="button"
						variant="gradient"
					>
						<ButtonLabel>Login</ButtonLabel>
					</Button>
				</div>
			</div>

			<div className="z-[2] flex flex-col w-full max-w-[960px] gap-8 sm:gap-12 pt-24 pb-12 sm:py-24 px-12 mx-auto">
				<div className="flex flex-col w-auto gap-12">
					<div className="flex flex-col w-full gap-8">
						<div className="flex flex-col sm:flex-row justify-between items-start sm:items-end w-full sm:gap-2">
							<span className="text-lg font-semibold text-white">Macroverse Terms of Service</span>
							<span className="text-xs text-white/70">Last Updated: 12/20/24</span>
						</div>
						<p className="text-md text-white/70">
							Welcome to Macroverse. By using this platform, you agree to these Terms of Service (“Terms”). Please read
							them carefully.{" "}
							<span className="font-bold text-white">If you do not accept these Terms, you may not use Macroverse</span>
							.
						</p>
					</div>

					<hr className="border-b border-white/20" />

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">1. Introduction</span>
						<p className="text-white/70">
							Macroverse is a platform designed for digital comics, collectibles, and interactive experiences. These
							Terms outline your rights and responsibilities when using the platform and its features.
						</p>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">Beta Disclaimer</span>: Macroverse is continually evolving, and
								some features may still be in development. By using the platform, you acknowledge this and accept that
								features may change, and interruptions or bugs may occur.
							</li>
							<li>
								<span className="font-bold text-white">Updates to Terms</span>: Macroverse may update these Terms over
								time. Continued use of the platform after updates indicates your acceptance of the revised Terms.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">2. Platform Access</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">Account Creation</span>: You can create an account using a valid
								email and password or through social login options such as Google, Apple, or X. All accounts are
								publicly visible during the initial launch phase.
							</li>
							<li>
								<span className="font-bold text-white">Public Profiles</span>: Your profile, including your username and
								redeemed content, will be visible to all users.
							</li>
							<li>
								<span className="font-bold text-white">Voluntary Engagement</span>: Activities like QR code redemption
								and earning XP are optional and entirely voluntary.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">3. Platform Features</span>
						<ul className="list-disc pl-8 space-y-6 text-white/70">
							<li className="flex flex-col w-full gap-4">
								<span className="font-bold text-white">Digital Twins:</span>
								<ul className="list-disc pl-8 space-y-4 text-white/70">
									<li>
										Digital Twins are paired with physical collectibles. Ownership of a physical collectible grants
										rights to the corresponding Digital Twin. The verification process for ownership may include
										resubmission of the QR code, proof of purchase, or other documentation. In case of disputes,
										Macroverse will review evidence from all parties involved and make a final determination on the
										rightful owner of the Digital Twin.
									</li>
									<li>
										If you transfer ownership of a physical item, the new owner can claim the Digital Twin by
										re-scanning the QR code or using platform features.
									</li>
									<li>
										Macroverse may intervene in disputes to verify and transfer Digital Twins to rightful owners if
										necessary.
									</li>
								</ul>
							</li>
							<li className="flex flex-col w-full gap-4">
								<span className="font-bold text-white">XP Rewards:</span>
								<ul className="list-disc pl-8 space-y-4 text-white/70">
									<li>
										XP can be earned through activities like QR scans, participating in platform events, completing
										collection tasks, or engaging with the Macroverse ecosystem.
									</li>
									<li>
										XP may be used as an in-app or on-platform "currency" to access platform-specific rewards and
										features, though it holds no monetary value or guaranteed benefit.
									</li>
									<li>
										Macroverse reserves the right to adjust XP balances for platform stability, ensuring fairness and
										avoiding punitive actions.
									</li>
								</ul>
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">4. Rules of Conduct</span>
						<p className="text-white/70">To foster a safe and respectful community, users must:</p>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>Treat others with respect and act responsibly.</li>
							<li>Avoid engaging in fraudulent, disruptive, or illegal activities.</li>
							<li>Comply with all applicable laws and these Terms.</li>
						</ul>
						<p className="text-white/70">
							<span className="font-bold text-white">Content Moderation</span>: Macroverse may monitor and remove
							content, comments, or actions at its discretion, including but not limited to offensive language,
							fraudulent activity, harassment, or other behavior that violates community standards or applicable laws.
							Users are responsible for their actions, and Macroverse is not liable for their consequences.
						</p>
						<p className="text-white/70">
							<span className="font-bold text-white">Enforcement</span>: Violations may result in warnings, suspensions,
							or permanent account bans.
						</p>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">5. Payments and Transactions</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">Refunds</span>: Refund requests for physical goods will be
								reviewed on a case-by-case basis. Digital content and XP transactions are non-refundable.
							</li>
							<li>
								<span className="font-bold text-white">Taxes and Fees</span>: Users are responsible for any taxes or
								fees related to their transactions.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">6. Intellectual Property</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">Macroverse Content</span>: All platform assets, branding, and
								features are owned by Macroverse. Third-party or partner content is clearly identified, and ownership
								remains with the respective partners. Macroverse does not claim rights to third-party content.
							</li>
							<li>
								<span className="font-bold text-white">User Feedback</span>: By providing feedback, you grant Macroverse
								the right to use it for platform improvements without compensation.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">7. Content Ratings</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								Content on the platform includes visible ratings. By accessing content, you acknowledge and accept its
								rating.
							</li>
							<li>Macroverse is not liable for outcomes resulting from users’ interactions with content.</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">8. Disclaimers and Limitations</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">Platform as Is</span>: Macroverse is provided “as is,” without
								guarantees of uninterrupted access or functionality.
							</li>
							<li>
								<span className="font-bold text-white">XP Utility</span>: XP is for on-platform use only, and its value
								or application may change over time.
							</li>
							<li>
								<span className="font-bold text-white">Feature Changes</span>: Platform features may be updated,
								modified, or removed without prior notice.
							</li>
							<li>
								<span className="font-bold text-white">Limitation of Liability</span>: Macroverse is not responsible for
								any damages or losses arising from platform use.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">9. Termination</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">User-Initiated</span>: You can deactivate your account by
								contacting support.
							</li>
							<li>
								<span className="font-bold text-white">Platform-Initiated</span>: Macroverse may suspend or terminate
								accounts for violations of these Terms.
							</li>
							<li>
								<span className="font-bold text-white">Effect of Termination</span>: Terminated accounts forfeit all XP,
								content, and access to the platform.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">10. Dispute Resolution</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">Informal Resolution</span>: Users are encouraged to resolve
								disputes by contacting Macroverse support.
							</li>
							<li>
								<span className="font-bold text-white">Binding Arbitration</span>: If disputes cannot be resolved
								informally, they will be subject to binding arbitration under California or Delaware jurisdiction, as
								determined by Macroverse. Arbitration will be conducted by a neutral arbitrator in accordance with
								applicable arbitration rules. Each party will be responsible for their own legal fees, but
								administrative costs will be shared equally unless otherwise required by law.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">11. Miscellaneous</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">Entire Agreement</span>: These Terms constitute the complete
								agreement between Macroverse and its users.
							</li>
							<li>
								<span className="font-bold text-white">Severability</span>: If any provision is deemed invalid, the
								remaining Terms will remain enforceable.
							</li>
							<li>
								<span className="font-bold text-white">Force Majeure</span>: Macroverse is not responsible for
								disruptions caused by events beyond its control, such as natural disasters or technical issues.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">12. Contact Information</span>
						<p className="text-white/70">
							If you have questions, need assistance, or want to report a violation, please contact us:
							<br />
							<span className="font-bold text-white">Email</span>:{" "}
							<a href="mailto:support@macroverse.com" className="underline text-mvblue-300">
								support@macroverse.com
							</a>
						</p>
					</div>
				</div>

				<div className="flex flex-col w-full py-4 px-4 md:px-6 gap-6 mt-auto">
					<div className="flex flex-col md:flex-row justify-center items-center w-full gap-6 md:gap-12">
						<div className="inline-flex flex-row items-center w-auto gap-6 md:gap-12">
							<div className="inline-flex w-[75px] mr-2">
								<img className="block h-auto w-full" src="/svg/macroverse-logo-colored-layered.svg" alt="Macroverse" />
							</div>
							<a
								href="https://macroverse.onelink.me/VuAp/mvweb"
								className="group inline-flex p-[2px] rounded-xl bg-mvlogo-gradient shadow-lg"
							>
								<div className="relative inline-flex flex-row items-center w-auto p-2 pr-4 rounded-xl gap-2 bg-mvdark-600 overflow-hidden">
									<div className="z-[2] inline-flex w-[40px] h-[40px] rounded-lg shadow-lg bg-mvdark-900 overflow-hidden">
										<img src="/macroverse-app-icon.png" alt="" />
									</div>
									<div className="z-[2] flex flex-col w-auto">
										<span className="font-semibold text-sm text-white leading-4">Get the</span>
										<span className="font-quagmire font-bold text-md text-white uppercase leading-4">
											Macroverse App
										</span>
									</div>
									<div className="z-[1] absolute inset-0 w-full h-full bg-mvlogo-gradient opacity-0 transition-all group-hover:opacity-20 pointer-events-none" />
								</div>
							</a>
						</div>
						<div className="flex flex-row items-center w-auto gap-8">
							<div className="inline-flex flex-row items-center gap-4">
								<a href="https://www.instagram.com/macroversehq" className="font-semibold text-white">
									<IconInstagram size={24} />
								</a>
								<a href="https://x.com/macroverse" className="font-semibold text-white">
									<IconXtwitter size={24} />
								</a>
								<a href="https://www.kickstarter.com/profile/macroverse/created" className="font-semibold text-white">
									<IconKickstarter size={24} />
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-col w-full gap-4 mb-2">
						<div className="flex flex-row flex-wrap justify-center w-full gap-x-4">
							<a href="/terms-of-use" className="text-sm text-white/70 hover:text-white">
								Terms of Use
							</a>
							<a href="/privacy-policy" className="text-sm text-white/70 hover:text-white">
								Privacy Policy
							</a>
							<button
								onClick={() => {
									modal.open({
										title: "Support Modal",
										component: <SupportModal />,
									});
								}}
								type="button"
								className="text-sm text-white/70 hover:text-white"
							>
								Support
							</button>
						</div>
						<div className="flex flex-row justify-center w-full">
							<span className="text-xs text-white/50">© 2024 Macroverse Media Inc. All Rights Reserve</span>
						</div>
					</div>
				</div>
			</div>

			<div className="z-[1] fixed error-page-gradient inset-0 blur-[25px] overflow-hidden" />
		</div>
	);
}
