import { DialogProvider } from "@/components/core/modals/modal.tsx";
import { NotFound } from "@/components/errors/not-found.tsx";
import type { Auth0ContextInterface } from "@auth0/auth0-react";
import type { QueryClient } from "@tanstack/query-core";
import { ReactQueryDevtools as TanStackReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import clsx from "clsx";
import { Toaster } from "sonner";

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
	auth: Auth0ContextInterface;
}>()({
	component: RootComponent,
	notFoundComponent: () => <NotFound />,
	errorComponent: () => <NotFound />,
});

const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

function RootComponent() {
	return (
		<div
			className={clsx(
				"font-aller root-container relative flex min-h-full flex-col overflow-x-hidden",
				isProduction ? "mt-0" : "mt-8",
			)}
		>
			{!isProduction && (
				<div className="z-50 fixed top-0 right-0 left-0 flex items-center justify-center w-full h-8 px-6 bg-mvred-600">
					<span className="font-aller text-white ">You are in Staging Environment</span>
				</div>
			)}
			<DialogProvider>
				<div className="flex-1">
					<Outlet />
					<Toaster />

					{import.meta.env.VITE_DEVTOOLS_ENABLED === "true" && <TanStackRouterDevtools position="bottom-right" />}
					{import.meta.env.VITE_DEVTOOLS_ENABLED === "true" && (
						<TanStackReactQueryDevtools buttonPosition="top-right" />
					)}
				</div>
			</DialogProvider>
		</div>
	);
}
