import type React from "react";
import { type ReactNode, createContext, useState } from "react";

interface ExpContextType {
	triggerExpAnim: boolean;
	triggerAnimation: () => void;
}

const defaultExpContext: ExpContextType = {
	triggerExpAnim: false,
	triggerAnimation: () => {},
};

export const ExpContext = createContext<ExpContextType>(defaultExpContext);
interface ExpProviderProps {
	children: ReactNode;
}

export const ExpProvider: React.FC<ExpProviderProps> = ({ children }) => {
	const [triggerExpAnim, setTriggerExpAnim] = useState(false);

	const triggerAnimation = () => {
		setTimeout(() => setTriggerExpAnim(true), 200);
		setTimeout(() => setTriggerExpAnim(false), 2000); // Adjust delay as needed
	};

	return <ExpContext.Provider value={{ triggerExpAnim, triggerAnimation }}>{children}</ExpContext.Provider>;
};
