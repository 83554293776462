import { z } from "zod";

export const metadataSchema = z.object({
	website: z.union([
		z.string().refine((value) => !value || /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*\/?$/.test(value), {
			message: "Please provide a valid URL",
		}),
		z.literal(""),
	]),
	custom_link: z
		.union([
			z.string().refine((value) => !value || /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*\/?$/.test(value), {
				message: "Please provide a valid URL",
			}),
			// .url({ message: "Invalid custom link URL" }),
			z.literal(""),
		])
		.optional(),
	show_welcome: z.boolean().optional(),
	bg_color: z.string().optional(),
	twitter_handler: z
		.union([
			z.string().regex(/^[a-zA-Z0-9_](?!.*[-_.]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9_]$/, {
				message: "Invalid twitter handle",
			}),
			z.literal(""),
		])
		.optional(),
	instagram_handler: z
		.union([
			z.string().regex(/^[a-zA-Z0-9](?!.*[-_.]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9]$/, {
				message: "Invalid instagram handle",
			}),
			z.literal(""),
		])
		.optional(),
});

export const userSchema = z.object({
	id: z.string().optional().nullable(),
	email: z.string().optional().nullable(),
	provider_type: z.string().optional().nullable(),
	macronaut_id: z.string().optional().nullable(),
	username: z
		.string()
		.min(3, {
			message: "Username must be at least 3 characters long",
		})
		.max(30, {
			message: "Username must be at most 30 characters long",
		})
		.regex(/^[a-zA-Z0-9](?!.*[-_.]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9]$/, {
			message:
				"Username can contain letters, numbers, underscores (_), periods (.), and hyphens (-), but cannot start or end with a special character or have consecutive special characters.",
		}),
	email_verified_at: z.string().optional().nullable(),
	first_name: z.string().optional(),
	last_name: z.string().optional(),
	aptos_address: z.string().optional(),
	metadata: metadataSchema,
	bio: z
		.string()
		.max(1000, {
			message: "Bio must be at least 1000 characters long",
		})
		.optional(),
	headline: z
		.string()
		.max(160, {
			message: "Headline must be at least 160 characters long",
		})
		.optional(),
	total_exp: z.number().optional(),
	avatar_thumbnail: z.string().optional(),
	avatar_url: z.string().optional(),
	banner_thumbnail: z.string().optional(),
	banner_url: z.string().optional(),
	disabled_at: z.string().optional().nullable(),
	collection_count: z.number().optional(),
	followers_count: z.number().optional(),
	following_count: z.number().optional(),
	quest_count: z.number().optional(),
	last_login_at: z.string().optional().nullable(),
	created_at: z.string().optional(),
	updated_at: z.string().optional(),
});

export type User = z.infer<typeof userSchema>;
