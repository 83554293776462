import { Avatar, AvatarImage } from "@/components/core/display/avatar.tsx";
import { IconCheckCircle, IconExp, IconUser } from "@/components/mvicons";
import type { Activity } from "@/types/activity.ts";
import type { User } from "@/types/user.ts";
import { humanReadableDateTime } from "@/utils/human-readable-datetime.ts";

const iconMap: Record<string, React.ReactNode> = {
	xp: <IconExp className="translate-y-[2px] mr-1" size={16} />,
	quest: <IconUser className="translate-y-[2px] mr-1" size={16} />,
	user: <IconCheckCircle className="translate-y-[2px] mr-1" size={16} />,
};

export function parseTemplate(template: string, variables: Record<string, any>) {
	return template.split(/({\w+})/).map((part, index) => {
		if (part.startsWith("{") && part.endsWith("}")) {
			const key = part.slice(1, -1);
			if (key === "subject") {
				return (
					<span key={index} className="inline mx-2 text-white">
						{iconMap[variables.icon]} {variables.text}
					</span>
				);
			}
			return <span key={index}>{variables[key]}</span>;
		}

		return part.trim() ? <span key={index}>{part}</span> : null;
	});
}

export function ActivityItem({ activity, profile }: { activity: Activity; profile: User }) {
	const { template, variables } = activity.metadata;

	return (
		<div className="flex flex-row items-center w-full py-3 gap-3">
			<Avatar className="self-start shrink-0 w-[40px] h-[40px] rounded-full border-2 border-white bg-mvlogo-gradient">
				<AvatarImage src={profile.avatar_url} />
				{!profile.avatar_url && <AvatarImage src="/macronaut-avatar.png" />}
			</Avatar>
			<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full shrink grow gap-1 lg:gap-4">
				<p className="text-mvblue-300 leading-tight">{parseTemplate(template, variables)}</p>
				<span className="text-xs text-white/30  leading-tight">{humanReadableDateTime(activity.created_at)}</span>
			</div>
		</div>
	);
}
