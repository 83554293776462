import socialService from "@/api/services/social-service.ts";
import { queryClient } from "@/query-client.ts";
import { queryOptions, useMutation } from "@tanstack/react-query";

export const useFollowMutation = () => {
	return useMutation({
		mutationKey: ["follow"],
		mutationFn: socialService.follow,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["profile"] });
			queryClient.invalidateQueries({ queryKey: ["following"] });
			queryClient.invalidateQueries({ queryKey: ["user"] });
		},
	});
};

export const useUnfollowMutation = () => {
	return useMutation({
		mutationKey: ["follow"],
		mutationFn: socialService.unfollow,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["profile"] });
			queryClient.invalidateQueries({ queryKey: ["following"] });
			queryClient.invalidateQueries({ queryKey: ["user"] });
		},
	});
};

export const useFollowingQueryOptions = (userIds: string[]) => {
	return queryOptions({
		queryKey: ["following"],
		queryFn: () =>
			socialService.following({
				user_ids: userIds,
			}),
	});
};
